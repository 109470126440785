import {
    clearFilters,
  } from "../../utils/Utilities.js";

export default {
  state: {
    filter_store: {
        keyword: [],
        category_name: [],
        google_monthly_search_volume: [],
        fkms: [],
        sessions: [],
        sessions_90d: [],
        transactions: [],
        revenue: [],
        keywordRank: [],
        seo_rank_change_monthly: [],
        potential_traffic: [],
        seo_rank: [],
        status: [],
        updated_at: [],
        comment: [],
        url: [],
      },
      clearFilters: true,
  },
  mutations: {
    clear_filters_commit(state) {
        state.filter_store = clearFilters();
      },
  },
  actions: {
    clear_filters({ commit }) {
        commit("clear_filters_commit");
      },
  },
};
