<template>
  <div class="bg-w cards" style="padding-bottom: 1px; ">
    <!-- download keyword button for keyword approval page  -->
    <!-- organic/paid keyword download button should be moved here also  -->
    <a
      v-if="kwAwaitingApprovalPage"
      href="#"
      class="btn btn-secondary download"
      :title="$t('tab_container.keywords.download_keywords')"
      :alt="$t('tab_container.keywords.download_keywords')"
      @click="downloadKWs()"
      style="border-radius: 0.25rem;float: right; margin: 15px 15px 15px 0;"
    >
      <font-awesome-icon icon="download" />
    </a>
    <!-- keyword table  -->
    <b-table
      id="keyword_table"
      sticky-header="700px"
      head-variant="light"
      responsive
      no-sort-reset
      hover
      :items="tableData"
      :fields="fields()"
      foot-clone
      :busy="table_busy"
      @sort-changed="keyword_sort_change"
      no-local-sorting
      :per-page="perPage"
      :current-page="-1"
      selectable
      select-mode="single"
      bordered
      outlined
      no-footer-sorting
      style="border-bottom: 1px solid #dee2e6!important;"
    >
      <div slot="table-busy" class="text-center text-danger" style="margin: 150px 0;">
        <b-spinner class="align-left"></b-spinner>
        <strong class="mx-2">Loading...</strong>
      </div>

      <!-- non numeric filters  -->
      <template slot="head()" slot-scope="data">
        <span>{{ data.label }}</span>
        <Filters :data="data" :number_check="false" @filter_fetch="filterFetch" />
      </template>

      <!-- numeric filters -->
      <template v-for="(item, index) in numericValueInput" :slot="item" slot-scope="data">
        <span :key="index">{{ transactions_leads_display(data) }}</span>
        <Filters :key="index + 'input'" :data="data" :number_check="true" @filter_fetch="filterFetch" />
      </template>

      <!-- columns without filters -->
      <template slot="head(index)" slot-scope="data">
        <span>{{ data.label }}</span>
        <div style="height: 31px;"></div>
      </template>
      <template slot="head(url)" slot-scope="data">
        <span>{{ data.label }}</span>
        <div style="height: 31px;"></div>
      </template>

      <template slot="cell(index)" slot-scope="data">{{ data.index + 1 + (currentPage - 1) * perPage }}.</template>
      <template slot="cell(keyword)" slot-scope="data">
        <span>
          <div>{{ data.item.keyword }}</div>
        </span>
      </template>
      <template slot="cell(status)" slot-scope="data">
        <div>{{ data.item.status }}</div>
      </template>
      <template v-if="kwAwaitingApprovalPage" slot="comment" slot-scope="data">
        <div>
          <div>
            <span>{{ data.item.comment }}</span>
          </div>
        </div>
      </template>
      <template slot="foot(keyword)">
        <strong>{{ $t("tab_container.keywords.total_keywords") }}: {{ totalPages }}</strong>
      </template>
      <template slot="foot(sessions)">
        <strong>{{ tableData && transactionTotal(tableData, "sessions") }}</strong>
      </template>
      <template slot="foot(transactions)">
        <strong>{{ tableData && transactionTotal(tableData, "transactions") }}</strong>
      </template>
      <template slot="foot()">
        <span></span>
      </template>
    </b-table>
    <!-- table pagination  -->
    <div class="m-3 row" style="justify-content: space-between">
      <div class="col-12 col-md-8">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalPages"
          :per-page="perPage"
          aria-controls="keyword_table"
        ></b-pagination>
      </div>
      <!-- per page select  -->
      <div class="col-12 col-md-4">
        <b-form-group
          :label="$t('tab_container.keywords.per_page')"
          label-cols-sm="3"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0 justify-content-start justify-content-md-end"
          style="flex-grow: 0!important"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            style="width: 80px;"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { clientDataAPICall } from "../../utils/api";
import { mapState, mapGetters } from "vuex";
const FileDownload = require("js-file-download");
import axios from "axios";
import { getDashboardURL, initialSortState, axiosHeaders } from "../../utils/Utilities";
import { table_fields } from "../../utils/tableFields";
import Filters from "../ui/Filters";
import filterMix from "../../mixins/filterMix.js";

export default {
  name: "TopPerformingKeywordTable",
  mixins: [filterMix],
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      pageOptions: [20, 50, 100],
      sortBy: "sessions",
      numericValueInput: [
        "head(fkms)",
        "head(google_monthly_search_volume)",
        "head(sessions)",
        "head(sessions_90d)",
        "head(transactions)",
        "head(revenue)",
        "head(keywordRank)",
        "head(seo_rank_change_monthly)",
        "head(potential_traffic)",
        "head(seo_rank)",
        "head(activated_days)",
        "head(bounce_rate30d)",
      ],
      // show spinner when table is busy loading data 
      table_busy: false,
      sort: null,
      // initial sort direction, numeric value sort highest to lwoest first while string sort low to high
      sorting_order: initialSortState(),
      CommentData: [],
      checked: [],
      APIClient: false,
      load: 1,
    };
  },
  mounted() {
    // clear all filters when table is mounted
    this.$store.dispatch("clear_filters");
  },
  created() {
    if (this.tableData.length > 0) {
      if (!this.tableData[0].url != "undefined" && this.tableData[0].url) {
        this.APIClient = false;
      } else {
        this.APIClient = true;
      }
    }
  },
  computed: {
    ...mapState({
      TPKdata: (state) => state.clientData.TPKData,
      SEMTPKdata: (state) => state.clientData.SEMTPKData,
      kwAwaitingApproval: (state) => state.keywordApproval.kw_awaiting_approval,
      kwAwaitingApprovalPage: (state) => state.keywordApproval.kw_awaiting_approval_page,
      selectedClientName: (state) => state.clientList.selectedClient.name,
      selectedClient_rev_lead: (state) => state.clientList.selectedClient.rev_lead,
      filter_store: (state) => state.filters.filter_store,
    }),
    ...mapGetters([
      "seo_sem_string_getter",
      "seo_sem_boolean_getter",
      "revenue_leads_string_getter",
      "revenue_leads_boolean_getter",
    ]),
    csvMonth() {
      let dates = new Date();
      return dates
        .toLocaleDateString("en-us", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace("_", "");
    },
    totalPages() {
      if (this.kwAwaitingApprovalPage) {
        return this.kwAwaitingApproval.data.total_keywords;
      } else {
        if (this.seo_sem_boolean_getter) {
          if (this.TPKdata && this.TPKdata.data && this.TPKdata.data.total_keywords)
            return this.TPKdata.data.total_keywords;
        } else if (!this.seo_sem_boolean_getter) {
          if (this.SEMTPKdata && this.SEMTPKdata.data && this.SEMTPKdata.data.total_keywords)
            return this.SEMTPKdata.data.total_keywords;
        }
        return 0;
      }
    },
    tableData() {
      data = [];
      // keyword awaiting approval data
      if (this.kwAwaitingApprovalPage) {
        var data = this.kwAwaitingApproval.data.keywords;
        if (data && data.length > 0) {
          let i = 0;
          data.map((x) => {
            x.keyword;
            x.edit = false;
            x.id = i;
            x.checked = false;
            i++;
          });
        }
      }
      // non keyword apprival data
      else {
        if (this.seo_sem_boolean_getter) {
          if (this.TPKdata && this.TPKdata.data && this.TPKdata.data.keywords) data = this.TPKdata.data.keywords;
        } else if (!this.seo_sem_boolean_getter) {
          if (this.SEMTPKdata && this.SEMTPKdata.data && this.SEMTPKdata.data.keywords)
            data = this.SEMTPKdata.data.keywords;
        }
        if (data && data.length > 1) {
          // format data properly before we pass to table
          data.map((x) => {
            (x.transactions = this.numberWithCommas(Math.round(x.transactions))),
              (x.sessions = this.numberWithCommas(x.sessions)),
              (x.revenue = this.numberWithCommas(Math.round(x.revenue))),
              (x.bounce_rate30d = Math.round(x.bounce_rate30d * 100 * 100) / 100),
              (x.activated_days = x.activated_days >= 90 ? ">90" : x.activated_days);
            // any google search volume data under 10 should return '<10' string
            x.google_monthly_search_volume = !x.google_monthly_search_volume
              ? "<10"
              : x.google_monthly_search_volume < 10
              ? "<10"
              : x.google_monthly_search_volume;
          });
        }
      }
      return data;
    },
  },
  methods: {
    transactions_leads_display(data) {
      if (data.field.key === "transactions") {
        if (!this.revenue_leads_boolean_getter) {
          return "Leads";
        }
        return data.field.label;
      }
      return data.field.label;
    },
    // call the filter function and pass in the data from the table
    filterFetch(data) {
      this.fetchData(false, data, true);
    },
    downloadKWs() {
      axios
        .get(
          `${getDashboardURL()}/api/download-keywords/${this.selectedClientName}/${
            this.seo_sem_string_getter
          }/awaiting-approval`,
          axiosHeaders()
        )
        .then((response) => {
          FileDownload(
            response.data,
            `${this.$route.params.id.charAt(0).toUpperCase() + this.$route.params.id.slice(1)}${
              this.seo_sem_string_getter
            }KeywordReport-${this.csvMonth}.csv`, "text/csv;charset=utf-8", "\uFEFF"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // reset the sorting order
    initialState() {
      this.sorting_order = initialSortState();
    },
    // @vuese
    // Used to fetch new keyword data with the sorting and filters
    // @arg sort_call to tell if we need to account for sorting
    fetchData(sort_call, filter_data, cancel) {
      this.table_busy = true;
      var newstate = !this.sorting_order[this.sort];
      // if sorting reset all sort values and then only update the chosen sort value
      if(sort_call){
        this.initialState();
        this.sorting_order[this.sort] = newstate
      }
      const dispatch_data = {
        cancel: cancel,
        client: this.$route.params.id,
        type: this.seo_sem_string_getter,
        page: this.currentPage,
        sort: this.sort,
        per_page: this.perPage,
        order: this.sorting_order[this.sort] ? "ASC" : "DESC",
        filter_data,
        url: this.kwAwaitingApprovalPage
          ? `api/get-keywords/${this.selectedClientName}/${this.seo_sem_string_getter}/awaiting-approval`
          : "api/get-keywords",
        commitName: this.seo_sem_boolean_getter ? "updateTPKData" : "SEMupdateTPKData",
        fetchName: this.seo_sem_boolean_getter ? "TPK_data_fetched" : "SEM_TPK_data_fetched",
        name: this.seo_sem_boolean_getter ? "TPKData" : "SEMTPKData",
      };
      if (this.kwAwaitingApprovalPage) {
        this.$store.dispatch("keyword_approval_API_call", dispatch_data).then(() => {
          this.table_busy = false;
        });
      }
      if (!this.kwAwaitingApprovalPage) {
        clientDataAPICall(dispatch_data).then(() => {
          this.table_busy = false;
        });
      }
    },
    // function that runs when table sort is clicked
    keyword_sort_change(x) {
      this.currentPage = 1;
      this.sort = x.sortBy;
      this.fetchData(true, this.convert_nonEmpty_filter_value_toString);
    },
    // calculate total number of transactions 
    transactionTotal(arr, variable) {
      if (arr && !this.kwAwaitingApprovalPage) {
        var sum = 0;
        arr.forEach((element) => {
          const val = typeof element[variable] == "string" ? +element[variable].replace(/,/g, "") : element[variable];
          sum += val;
        });
        return this.numberWithCommas(Math.round(sum));
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // return the correct table fields based on organic/paid / approval status 
    fields() {
      if (this.kwAwaitingApprovalPage) {
        return this.APIClient == false ? table_fields().keyword_approval_nonAPI : table_fields().keyword_approval_API;
      } else {
        return this.seo_sem_boolean_getter
          ? this.revenue_leads_boolean_getter
            ? table_fields().seo_rev
            : table_fields().seo_leads
          : table_fields().sem;
      }
    },
  },
  watch: {
    // fetch newq table data whenever user updates pagination 
    currentPage: function(value) {
      this.CommentData = [];
      this.currentPage = value;
      this.fetchData(false, this.convert_nonEmpty_filter_value_toString);
    },
    perPage: function(value) {
      this.perPage = value;
      this.fetchData(false, this.convert_nonEmpty_filter_value_toString);
    },
    TPKData: function(value) {
      this.ApiClientCheck;
      if (value > 0) {
        if (value[0].url != "undefined" && value[0].url) {
          this.APIClient = false;
        } else {
          this.APIClient = true;
        }
      }
    },
  },
  components: {
    Filters,
  },
};
</script>

<style>
.b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -2px;
}
.b-table {
  margin: 0;
}
#keyword_table{
  font-size: 14px;
}
th {
  font-weight: bold;
}
table.b-table > thead > tr > th {
  border-bottom: 2px solid #dee2e6;
}
table.b-table > tfoot > tr > th {
  border-top: 2px solid #dee2e6;
}
td a {
  color: #2f2f2f;
}
.header-name {
  min-height: 50px;
  background-color: blue;
}
table.b-table[aria-busy="true"] {
  height: 700px;
}
.b-table-sticky-header > .table.b-table > tfoot > tr > th {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 2;
  border: none;
}
div[role="group"] > div.col {
  flex-grow: 0;
}
th > input {
  padding: 0 7px;
  display: block;
}
th > span {
  display: block;
  margin: 5px;
}
.spinner-container {
  padding: 150px;
  text-align: center;
}
.table td {
  padding: 8px;
}
.keyword-row {
  min-width: 250px;
}
.gmsv-row {
  min-width: 150px;
}
</style>
