<template>
  <!-- edit data modal  -->
  <b-modal :ref="type + '_modal'" size="lg">
    <template slot="modal-header">
      <h3>{{type === 'edit' ? 'Edit' : 'Create new task'}}</h3>
    </template>
    <b-form @submit.prevent="submit_edit_task">
      <div class="row">
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_type">{{ $t("task_list.type_of_task") }}</label>
          <b-input type="text" name="edit_type" v-model="task_list_data.task_type"></b-input>
        </div>
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_priority">{{ $t("task_list.priority") }}</label>
          <b-form-select v-model="task_list_data.priority" :options="['High', 'Med', 'Low']"></b-form-select>
        </div>
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_task">{{ $t("task_list.task") }}</label>
          <b-input type="text" name="edit_task" v-model="task_list_data.task"></b-input>
        </div>
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_requested_by">{{ $t("task_list.requested_by") }}</label>
          <b-input type="text" name="edit_requested_by" v-model="task_list_data.request_by"></b-input>
        </div>
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_reference">{{ $t("task_list.reference_if_any") }}</label>
          <b-input type="text" name="edit_reference" v-model="task_list_data.reference"></b-input>
        </div>
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_status">{{ $t("task_list.status") }}</label>
          <b-form-select v-model="task_list_data.status" :options="['Complete', 'Open', 'In Progress']"></b-form-select>
        </div>
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_assigned_to">{{ $t("task_list.assigned_to") }}</label>
          <b-input type="text" name="edit_assigned_to" v-model="task_list_data.assigned_to"></b-input>
        </div>
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_eta_for_resolution">{{ $t("task_list.eta_for_resolution") }}</label>
          <b-form-datepicker
            id="datepicker1"
            reset-button
            v-model="task_list_data.eta_for_resolution"
            class="mb-2"
          ></b-form-datepicker>
        </div>
        <div class="col-6 col-lg-4 modal-input">
          <label for="edit_date_of_request">{{ $t("task_list.date_of_request") }}</label>
          <b-form-datepicker
            id="datepicker2"
            reset-button
            v-model="task_list_data.date_of_request"
            class="mb-2"
          ></b-form-datepicker>
        </div>
        <div class="col-12">
          <label for="edit_comments">{{ $t("task_list.comments") }}</label>
          <b-form-textarea
            type="text"
            name="edit_comments"
            maxlength="255"
            v-model="task_list_data.comments"
          ></b-form-textarea>
        </div>
      </div>
    </b-form>
    <template v-slot:modal-footer="{ cancel }">
      <b-button size="sm" variant="outline-secondary" @click="submit_task()">Submit</b-button>
      <b-button size="sm" variant="outline-secondary" @click="cancel()">Cancel</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["task_data", "type"],
  data() {
    return {
      task_list_data: this.task_data,
    };
  },
  methods: {
    submit_task() {
      this.$emit("submit", this.task_list_data);
    },
    show() {
      this.type === "edit" ? this.$refs["edit_modal"].show() : this.$refs["new_modal"].show();
    },
    hide() {
      this.type === "edit" ? this.$refs["edit_modal"].hide() : this.$refs["new_modal"].hide();
    },
  },
};
</script>

<style></style>
