<template>
  <div id="insightMonth" class="bg-w m-0 position-relative">
    <div class>
      <div class="tab-head p-4">
        <span class="med-txt grey">KEY INSIGHTS & ACTIONS</span>
      </div>
    </div>
    <div class="row brd-top p-0 p-lg-4 mt-0 position-relative mr-0">
      <MonthlyItem :index="0" :value="insightData[0]" v-if="insightData.length > 0"></MonthlyItem>
    </div>
    <b-collapse id="collapse-3" v-model="visible"  v-if="insightData.length > 0">
      <span v-for="(value, index) in insightData" :key="index">
        <div class="row brd-top p-4 mt-0 position-relative" v-if="index > 0">
          <MonthlyItem :index="index" :value="value"></MonthlyItem>
        </div>
      </span>
    </b-collapse>
    <div class="row text-center" v-if="insightData.length > 1">
      <b-button
        @click="showAll"
        class="m-1 hide-show-btn"
        :class="visible ? null : 'collapsed'"
        :aria-expanded="visible ? 'true' : 'false'"
        aria-controls="collapse-3"
        >{{ button.text }}</b-button
      >
    </div>
    <b-alert :show="dismissCountDown" fade variant="warning" @dismiss-count-down="countDownChanged">
      Sending to database
    </b-alert>
    <div class="date-editor px-0 px-lg-4" v-if="auth == 1">
      <div v-if="checkedCalendar">
        <VueCtkDateTimePicker v-model="datePicker"></VueCtkDateTimePicker>
      </div>

      <div class="col-md-12 col-12 date-dropdown">
        <span style="position:absolute;left:240px;top:5px;"
          ><input type="checkbox" v-model="checkedCalendar" value="calendar" /> Calendar</span
        >
        <div style="position:absolute;left:320px;top:-9px;">
          <b-dropdown variant="outline-secondary" id="dropdown-form" text="Selected Images" ref="dropdown" class="m-2">
            <b-dropdown-form class="images_dropdown">
              <b-form-checkbox class="mb-3" v-model="images.session_revenue">Session / Revenue</b-form-checkbox>
              <b-form-checkbox class="mb-3" v-model="images.ranking_summary">Ranking Summary</b-form-checkbox>
              <b-form-checkbox class="mb-3" v-model="images.keyword_index"
                >Active Keywords / Indexed Pages</b-form-checkbox
              >
            </b-dropdown-form>
          </b-dropdown>
        </div>
        <b-dropdown variant="outline-secondary" id="ddown2" :text="shownMonth()" class="mx-1" no-flip>
          <b-dropdown-item
            :key="index"
            v-for="(month, index) in availableMonths"
            ref="trigInput"
            @click="changeMonths(month, index)"
          >
            <div>{{ month }}</div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div>
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" @blur="saveState()"></ckeditor>
        <br />
        <b-button @click="sendForm(), showAlert()" :class="{ disable: disableButton }">Save</b-button>
      </div>
    </div>
    <div class="subscription px-0 px-lg-4  py-4">
      {{ subscriptionDate }}
    </div>
  </div>
</template>
<script>
import MonthlyItem from "./Monthly-insights-item.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { calculateSubscription, apiError, getDashboardURL, axiosHeaders } from "../../../utils/Utilities";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import * as moment from "moment";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "Monthly",
  components: {
    MonthlyItem,
    VueCtkDateTimePicker,
  },
  data() {
    return {
      insightItems: [],
      datePicker: "",
      checkedCalendar: false,
      visible: false,
      images: {
        session_revenue: true,
        ranking_summary: true,
        keyword_index: true,
      },
      button: {
        text: "SHOW ALL",
      },
      isActive: false,
      date: new Date(),
      selectedMonth: new Date().getMonth() + 1,
      postDate: "",
      activeMonthEdit: 0,
      editorData: "",
      editor: ClassicEditor,
      editor_data: {
        insights: {
          description: {
            text: "",
          },
          session_revenue: {
            chart: "",
            title: "SESSIONS / REVENUE",
          },
          ranking_summary: {
            chart: "",
            title: "RANKING SUMMARY",
          },
          keyword_index: {
            chart: "",
            title: "ACTIVE KEYWORDS / INDEXED PAGES",
          },
          meeting_info: {
            date: "",
          },
        },
        insight_date: "",
        first_name: this.$store.state.auth.first_name,
        last_name: this.$store.state.auth.last_name,
        email: this.$store.state.auth.email,
      },

      show_date: "",
      editorConfig: {
        toolbar: {
          items: ["heading", "bold", "italic", "link", "bulletedList"],
          groups: ["styles"],
        },
      },
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      disableButton: false,
      language: "",
      subscriptionDate: "",
    };
  },
  watch: {
    disableButton: function() {
      const local = this;
      setTimeout(function() {
        local.disableButton = false;
        var element = document.getElementById("insightMonth");
        element.scrollIntoView({ behavior: "smooth" });
      }, 3000);
    },
  },
  methods: {
    apiError(response) {
      return apiError(response);
    },
    checkDate() {
      if (
        !this.editor_data.insights.meeting_info ||
        this.editor_data.insights.meeting_info.length == 0 ||
        this.editor_data.insights.meeting_info.date == "" ||
        !this.editor_data.insights.meeting_info.date == "undefined"
      ) {
        this.editor_data.insights.meeting_info = {
          date: "",
        };
      }
      if (
        !this.editor_data.insights.session_revenue ||
        this.editor_data.insights.session_revenue.chart == "" ||
        this.editor_data.insights.session_revenue.length == 0
      ) {
        this.images.session_revenue = false;
        this.editor_data.insights.session_revenue = {
          chart: "",
          title: "SESSIONS / REVENUE",
        };
      }
      if (
        !this.editor_data.insights.ranking_summary ||
        this.editor_data.insights.ranking_summary.chart == "" ||
        this.editor_data.insights.ranking_summary.length == 0
      ) {
        this.images.ranking_summary = false;
        this.editor_data.insights.ranking_summary = {
          chart: "",
          title: "RANKING SUMMARY",
        };
      }
      if (
        !this.editor_data.insights.keyword_index ||
        this.editor_data.insights.keyword_index.chart == "" ||
        this.editor_data.insights.keyword_index.length == 0
      ) {
        this.images.keyword_index = false;
        this.editor_data.insights.keyword_index = {
          chart: "",
          title: "ACTIVE KEYWORDS / INDEXED PAGES",
        };
      }
    },
    emailData() {
      Swal.fire({
        title: "Do you want to send this report?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ffc001",
        cancelButtonColor: "#a5a5a5",
        confirmButtonText: "Yes, send it!",
      }).then((result) => {
        if (result.value) {
          var sendEmail = true;
          this.sendForm(sendEmail);
          Swal.fire({
            title: "Sent!",
            text: "Your file has been sent.",
            type: "success",
            confirmButtonColor: "#ffc001",
          });
        }
      });
    },
    calculateSubscription(x) {
      return (this.subscriptionDate = calculateSubscription(x));
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
      this.disableButton = true;
    },
    updateDateDrop() {
      this.show_date = this.availableMonths[1];
      this.editor_data.insight_date = moment(this.availableMonths[0]).format("YYYY-MM-DD");
    },
    saveState() {
      if (!localStorage.getItem("insight_draft")) {
        let a = [
          {
            client_id: this.selectedClientName,
            insights: this.editorData,
          },
        ];
        localStorage.setItem("insight_draft", JSON.stringify(a));
      } else {
        let data = {
          client_id: this.selectedClientName,
          insights: this.editorData,
        };
        let a = [];
        a = JSON.parse(localStorage.getItem("insight_draft"));
        let local = this;
        a = a.filter(function(item) {
          return item.client_id != local.$store.state.clientList.selectedClient.name;
        });
        a.push(data);
        localStorage.setItem("insight_draft", JSON.stringify(a));
      }
    },
    showAll() {
      this.visible = !this.visible;
      if (this.button.text == "SHOW ALL") {
        this.button.text = "SHOW LESS";
      } else {
        this.button.text = "SHOW ALL";
      }
    },
    sendForm() {
      this.editor_data.insights.meeting_info.date = this.datePicker;
      if (this.editor_data.insight_date == "") {
        this.editor_data.insight_date = moment(this.availableMonths[0]).format("YYYY-MM-DD");
      } else {
        this.updateDateDrop();
      }
      for (const property in this.images) {
        var selection = document.body.querySelector("." + property + ".chart-wrapper canvas") !== null;
        if (this.images[property] == true && selection) {
          this.editor_data.insights[property].chart = document.body
            .querySelector("." + property + ".chart-wrapper canvas")
            .toDataURL("image/jpg");
        } else {
          delete this.editor_data.insights[property];
        }
      }

      if (!this.checkedCalendar) {
        delete this.editor_data.insights.meeting_info;
      } else {
        if (this.editor_data.insights.meeting_info) {
          this.editor_data.insights.meeting_info.date = moment(this.datePicker, "YYYY-MM-DD h:mm A").format(
            "YYYY-MM-DD HH:mm"
          );
        }
      }
      this.editor_data.insights.description.text = this.editorData;
      let editor_data = this.editor_data;
      this.show_date = "";
      this.shownMonth();
      let self = this;
      var params = {
        new_item: editor_data,
      };
      let client = this.selectedClientName;
      axios
        .post(`${getDashboardURL()}/api/monthly-insights/` + client + ``, params.new_item, axiosHeaders())
        .then(function() {
          self.$store.dispatch("fetchMonthlytInsight");
          setTimeout(function() {
            self.$store.dispatch("fetchMonthlytInsight").then(() => {});
            self.show_date = self.availableMonths[0];
            self.editor_data.insight_date = moment(self.availableMonths[0]).format("YYYY-MM-DD");
          }, 3000);
          self.checkDate();
        })
        .catch(function(res) {
          self.apiError(res);
        });
    },
    populateEditor() {
      if (localStorage.getItem("insight_draft")) {
        let noitems = true;
        let x = localStorage.getItem("insight_draft");
        x = JSON.parse(x);
        Object.entries(x).forEach(([key]) => {
          if (x[key].client_id == this.selectedClientName) {
            this.editorData = x[key].insights;
            noitems = false;
          }
        });
        if (noitems) {
          this.editorData = "";
        }
      } else {
        this.editorData = "";
      }
    },
    changeMonths(month, index) {
      this.activeMonthEdit = index;
      this.show_date = month;
      this.editor_data.insight_date = moment(month).format("YYYY-MM-DD");
    },
    shownMonth() {
      if (this.show_date == "") {
        return "Choose Date";
      } else {
        return this.show_date;
      }
    },
  },
  computed: {
    ...mapGetters(["availableMonths"]),
    ...mapState({
      clientList: state => state.clientList.clientList,
      selectedClientName: state => state.clientList.selectedClient.name,
      locale: "locale",
      stateInsights: state => state.monthlyInsights.editor_data,
      auth: state => state.auth.edit
    }),
    activeMonth() {
      return this.activeMonthEdit;
    },
    insightData() {
      const self = this;
      if (this.stateInsights.new_item.length > 0) {
        Object.keys(self.stateInsights.new_item).forEach((key) => {
          var months = [
            "JANUARY",
            "FEBRUARY",
            "MARCH",
            "APRIL",
            "MAY",
            "JUNE",
            "JULY",
            "AUGUST",
            "SEPTEMBER",
            "OCTOBER",
            "NOVEMBER",
            "DECEMBER",
          ];
          let current_datetime = new Date(self.stateInsights.new_item[key].insight_date);
          let formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getFullYear();
          self.stateInsights.new_item[key].insight_new_date = formatted_date;
        });
      }
      return self.stateInsights.new_item;
    },
  },
  created() {
    let x = this.clientList[this.selectedClientName].annual_subscription_end_date;
    this.calculateSubscription(x);
    this.$watch("$i18n.locale", (newLocale) => {
      this.language = newLocale;
      let x = this.clientList[this.selectedClientName].annual_subscription_end_date;
      this.calculateSubscription(x);
    });

    this.$store.watch(
      (state) => state.monthlyInsights.editor_data.new_item,
      () => {
        this.populateEditor();
      },
      (state) => state.clientList.selectedClient.name,
      () => {
        this.shownMonth();
      }
    );
    let self = this;
    this.$store.watch(
      (state) => state.clientList.selectedClient.name,
      () => {
        let x = self.clientList[self.selectedClientName].annual_subscription_end_date;
        this.calculateSubscription(x);
      }
    );
    this.populateEditor();
  },
};
</script>
<style lang="scss">
button#dropdown-form__BV_toggle_ {
  border: none !important;
  border-left: 1px solid #dee2e6 !important;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0;
}

.images_dropdown .b-dropdown-form {
  padding: 0.25rem 0.5rem;
}
.send-button {
  margin-left: 10px;
}
.ck-toolbar {
  zoom: 0.8;
}
.btn {
  font-size: 14px;
}
.disable {
  pointer-events: none;
  background: #6c757d4a;
}
@media print {
  .btn.btn-secondary.download {
    display: none;
  }
  body,
  div {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  .tab-content {
    .date-editor {
      display: none;
    }
    #ddown2__BV_toggle_ {
      display: none;
    }
    .button-container.container {
      display: none;
    }
    .hide-show-btn {
      display: none;
    }
  }
  .active.row {
    page-break-before: always;
  }

  .seo-summary {
    .rev_container {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .inside-border[data-v-4ddd79d8]:after {
    content: "";
    background: #e9ecef !important;
    position: absolute;
    bottom: 25%;
    left: 0;
    height: 50%;
    width: 2px !important;
    color: #e9ecef;
  }

  .col-12.col-xl-3.p-0.pl-xl-1 {
    .mt-1.col-12.col-md-6.col-lg-3.col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  .col-3.col-xl-2.align-items-center.col-item {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 16.66667% !important;
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }
  .col-3.col-xl-2.align-items-center.col-item:first-child {
    border-left-width: 0px !important;
  }

  .img-header-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;

    border-right: 2px solid #646464;
    -o-border-image: linear-gradient(
      to bottom,
      rgba(240, 240, 240, 0) 25%,
      #f0f0f0 25%,
      #f0f0f0 75%,
      rgba(240, 240, 240, 0) 75%
    );
    border-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(25%, rgba(240, 240, 240, 0)),
      color-stop(25%, #f0f0f0),
      color-stop(75%, #f0f0f0),
      color-stop(75%, rgba(240, 240, 240, 0))
    );
    border-image: linear-gradient(
      to bottom,
      rgba(240, 240, 240, 0) 25%,
      #f0f0f0 25%,
      #f0f0f0 75%,
      rgba(240, 240, 240, 0) 75%
    );
    border-image-slice: 1;
  }

  // #chartWrapper {
  //   width: 1000px !important;
  // }
  // .chart-wrapper {
  //   width: 1000px !important;
  // }
  #bar-chart {
    width: 1000px !important;
  }
}

.anime {
  width: 100%;
}
// #hideShow {
//   height: auto;
//   transition: max-height 0.15s ease-out;
// }
.date-editor .dropdown-toggle {
  border: none !important;
  border-left: 1px solid #dee2e6 !important;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0;
}
.list-create {
  display: none;
}
.list-create:nth-child(1) {
  display: block;
}
</style>
<style lang="scss" scoped>
.hide-show-btn {
  background: #a5a5a5;
  margin-left: auto !important;
  margin-right: auto !important;
  border: 3px solid #a5a5a5 !important;
  transition: all 0.5s;
  font-weight: bold;
  &:focus,
  &:hover {
    background: #fff !important;
    color: #a5a5a5;
    box-shadow: none !important;
  }
}
// .hideShowItems {
//   display: block;
// }
// .list-create{
//   display:none;
// }
.dropdown-toggle {
  border: none !important;
}
.date-editor {
  position: relative;
  .date-dropdown {
    position: relative;
    z-index: 2;
    #ddown2 {
      position: absolute;
      right: -3px;
      top: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.brd-top {
  margin-left: 0;
}
.col-me-1 {
  -ms-flex: 0 0 70px;
  -webkit-box-flex: 0;
  flex: 0 0 70px;
  max-width: 70px;
}
.col-11 {
  -ms-flex: 0 0 calc(91.666667% - 70px);
  -webkit-box-flex: 0;
  flex: 0 0 calc(91.666667% - 70px);
  max-width: calc(91.666667% - 70px);
}

.profile-pic {
  height: 44px;
  width: 44px;
  border-radius: 10px;
}

@media (max-width: 991.98px) {
  #ddown2 {
    position: absolute;
    right: -3px;
    top: -38px;
    margin-left: 0;
    margin-right: 0;
  }

  .col-11 {
    -ms-flex: 0 0 calc(100% - 70px);
    -webkit-box-flex: 0;
    flex: 0 0 calc(100% - 70px);
    max-width: calc(100% - 70px);
  }
}
</style>
