import Vue from "vue";
import Router from "vue-router";
import store from "../store/store.js";
import mainPage from "./views/mainPage.vue";
import Login from "./views/Login.vue";
import clientList from "./views/clientList.vue";
import ApprovalView from "./views/ApprovalView.vue";
import Unauthorised from "./views/Unauthorised.vue";
import PasswordResetForm from "./views/PassReset.vue";
import newPassword from "./views/newPassword.vue";
import Maintenance from "./views/maintenance";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/maintenance",
      component: Maintenance,
      name: "maintenance",
    },
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: `/client/:id/keyword-approval`,
      name: "keyword_approval",
      component: ApprovalView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/password/reset",
      name: "password_reset_form",
      component: PasswordResetForm,
    },
    {
      path: "/password/reset/:id",
      name: "new_password",
      component: newPassword,
    },
    {
      path: "/unauthorised",
      name: "unauthorised",
      component: Unauthorised,
    },
    {
      path: "/client/:id",
      name: "Client",
      component: mainPage,
      meta: {
        requiresAuth: true,
        windowRedirectAfter: true,
      },
    },
    {
      path: "/clientList",
      name: "clientlist",
      component: clientList,
      meta: {
        requiresAuth: true,
        conditionalRoute: true,
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to Maintenance page 
  if (process.env.VUE_APP_maintenance == 1) {
    if (to.path.toLowerCase() !== "/maintenance") {
      next("/maintenance");
    }
    next();
    return;
  } else {
    // if login page and already logged in redirect to client list page 
    if (store.getters.isLoggedIn && to.name == "login") {
      next("/clientList");
      return;
    } 
    // if page requires auth 
    else if (to.matched.some((record) => record.meta.requiresAuth)) {
      // if logged in go to page 
      if (store.getters.isLoggedIn) {
        next();
        return;
      }
      // this seems redundant?
      if (process.env.VUE_APP_maintenance) {
        if (to.path !== "/login") {
          next("/login");
        } else {
          next();
        }
      }
      // if not logged in go to unauthorised page 
      if (!store.getters.isLoggedIn) {
        next("/unauthorised");
        return;
      }
    } 
    // if no auth required
    else {
      next();
    }
  }
});

export default router;
