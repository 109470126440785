import Vue from "vue";
import Vuex from "vuex";
import { getDashboardURL } from "../utils/Utilities.js";
import createPersistedState from "vuex-persistedstate";
import moment from "moment";
import {
  get_keyword_report_api,
  get_keywords_sem_api,
  SEO_API_calls,
  keyword_approval_API_call,
  get_uploadedFiles_API_call,
} from "../utils/api.js";
import auth from "./modules/auth.js";
import clientList from "./modules/clientList.js";
import clientData from "./modules/clientData.js";
import keywordApproval from "./modules/keywordApproval.js";
import keywordUpload from "./modules/keywordUpload.js";
import monthlyInsights from "./modules/monthlyInisghts.js";
import filters from "./modules/filters.js";
import axios from "axios";
import jwt_decode from 'jwt-decode';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    clientList,
    clientData,
    keywordApproval,
    keywordUpload,
    monthlyInsights,
    filters,
  },
  state: {
    seosem: true,
    err: "",
    activeMonth: moment(new Date())
      .subtract(1, "months")
      .format("MMM YYYY"),
    update_date: {
      new_item_date: {},
    },
    expire_token:0,
    selectedDate: "",
  },
  mutations: {
    auth_refresh(state, data) {
      // expire_token time (minus 300 {5 mins})
      state.expire_token = data.exp - 300
    },
    changeSEO_SEM_State(state) {
      state.seosem = !state.seosem;
    },
    SEO_default(state) {
      state.seosem = true;
    },
    SEM_default(state) {
      state.seosem = false;
    },
    updateErr(state, err) {
      state.err = err;
    },
    updateMonth(state, month) {
      state.activeMonth = month;
    },
  },
  actions: {
    // get data for new client
    getNewClient({ commit }, client) {
      commit("updateCurrentClientData", client);
      // get client data that doesnt rely on seo/sem
      get_keyword_report_api(client);

      if (this.state.clientList.selectedClient.types.includes("seo")) {
        // get seo data
        SEO_API_calls(client);
          get_uploadedFiles_API_call(this.state.clientList.selectedClient.name, "seo");
        if (this.state.seosem) {
          keyword_approval_API_call(client, "seo");
        }
      }
      if (this.state.clientList.selectedClient.types.includes("sem")) {
        if (!this.state.seosem) {
          keyword_approval_API_call(client, "sem");
        }
        get_keywords_sem_api(client);
          get_uploadedFiles_API_call(this.state.clientList.selectedClient.name, "sem");
      }
    },
    updateError({ commit }, err) {
      commit("updateErr", err);
    },
    // the refresh action will create a post request, response will have a token
    refresh({ commit }) {
      let config = {
        method: "post",
        url: getDashboardURL() + "/api/auth/refresh",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      axios(config)
        .then(function(response) {
          // decode the token to get the expirey time-stamp
          const jwt_data = jwt_decode(response.data.access_token)
          const tokenId = response.data.access_token;
          if (response.data.user_info.group == "LUX") {
            localStorage.setItem("token", tokenId);
            // commit the expirey time-stamp for mutation auth_refresh
            commit("auth_refresh", jwt_data);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  getters: {
    authStatus: (state) => state.auth.status,
    seo_sem_string_getter: (state) => (state.seosem ? "seo" : "sem"),
    seo_sem_boolean_getter: (state) => state.seosem
  },

  plugins: [
    createPersistedState({
      paths: [
        "seosem",
        "auth.edit",
        "auth.first_name",
        "auth.last_name",
        "auth.lux_status",
        "auth.email",
        "clientList.selectedClientName",
        "clientList.selectedClient",
        "clientList.clientList",
        "expire_token_time",
      ],
    }),
  ],
});
