<template>
  <div class="row cards bg-w" id="logo_row">
    <div class="col-12 col-xl-3 img-header-col my-2 sm-pb-0">
      <a :href="clientURL" target="_blank">
        <img :src="clientLogo" style="max-height: 100px; max-width:270px;" />
      </a>
    </div>
    <div class="col-12 col-xl-9 my-0 sm-pb-0 row mx-0" v-if="logoData && logoData.data">
      <!-- active keywords  -->
      <div
        class="col-md h-md-100 col-item m-0 px-1 right-side-border"
        :class="{ 'col-4 ': seosem, 'h-50': seosem, col: !seosem }"
      >
        <div class="py-2 ">
          <div
            class="toprow-sm grey h-50 vertical-align-text"
            v-html="seosem ? $t('logo_row.active_lux_organic_smart_pages') : $t('logo_row.active_lux_paid_smart_pages')"
          ></div>
          <div class="toprow-lg blk h-50 vertical-align-text">
            {{
              seosem
                ? logoData.data.seo_current_active_keywords_count.toLocaleString()
                : logoData.data.sem_current_active_keywords_count.toLocaleString()
            }}
          </div>
        </div>
      </div>
      <!-- number of products  -->
      <div
        class="col-md  h-md-100 col-item m-0 mid-box right-side-border px-1"
        :class="{ 'col-4': seosem, 'h-50': seosem, col: !seosem }"
      >
        <div class="py-2">
          <div class="toprow-sm grey h-50 vertical-align-text">{{ $t("logo_row.num_products") }}</div>
          <div class="toprow-lg blk h-50 vertical-align-text">
            {{ logoData.data.current_product_count.toLocaleString() }}
          </div>
        </div>
      </div>
      <!-- traffic on forecast  -->
      <div
        class="col-md h-md-100 col-item m-0 right-side-border sm-no-border px-1"
        :class="{ 'col-4': seosem, 'h-50': seosem, col: !seosem }"
        v-if="seosem"
      >
        <div class="py-2">
          <div class="toprow-sm grey h-50 vertical-align-text">{{ $t("logo_row.forecast_achieved") }}</div>
          <div class="toprow-lg blk h-50 vertical-align-text">
            {{ Math.round(parseFloat(logoData.data.seo_pc_forecast_met)) }}%
          </div>
        </div>
      </div>
      <!-- uplift  -->
      <div
        class="col-md h-md-100  col-item m-0 mid-box right-side-border px-1"
        :class="{ 'col-6': seosem, 'h-50': seosem, col: !seosem }"
        v-if="uplift != null && seosem"
      >
        <div class="py-2">
          <div
            class="toprow-sm grey h-50 vertical-align-text"
            v-html="$t('logo_row.LUX_Uplift_on_non_brand_traffic')"
          ></div>
          <div class="toprow-lg blk h-50 vertical-align-text">{{ Math.round(uplift * 100).toLocaleString() }}%</div>
        </div>
      </div>
      <!-- launch date  -->
      <div class="col-md h-md-100 col-item m-0 px-1 pt-0" :class="{ 'col-6': seosem, 'h-50': seosem, col: !seosem }">
        <div class="py-2">
          <div class="toprow-sm grey h-50 vertical-align-text">{{ $t("logo_row.launch_date") }}</div>
          <div
            class="toprow-lg blk h-50 vertical-align-text"
            v-if="clientList[selected_client_name] != null && launch_date"
          >
            <span>{{ launch_date }}</span>
          </div>
          <div class="toprow-lg blk h-50 vertical-align-text" v-else>
            <span>N/A</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { launchDateFormat } from "../../utils/Utilities.js";

// @vuese
// @group Rows
// Container for the top row used for both organic and paid views, shows the logo and other generic data
export default {
  name: "LogoRow",
  computed: {
    ...mapState({
      logoData: (state) => state.clientData.staticData,
      seosem: "seosem",
      clientLogo: (state) => state.clientList.selectedClient.logo,
      clientURL: (state) => state.clientList.selectedClient.url,
      selected_client_name: (state) => state.clientList.selectedClient.name,
      clientList: (state) => state.clientList.clientList,
      uplift: (state) => state.clientData.firstUpliftValue,
    }),
    // return a correctly formatted launch date
    launch_date() {
      var launch = this.seosem
        ? launchDateFormat(this.clientList[this.selected_client_name].seo_launch_date)
        : launchDateFormat(this.clientList[this.selected_client_name].sem_launch_date);
      if (!launch || launch == "Invalid date") {
        launch = false;
      }
      return launch;
    },
  },
};
</script>

<style lang="scss" scoped>
#logo_row {
  text-align: center;
}
.vertical-align-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-marg {
  padding-right: 9px;
}
.toprow .col-12.my-2.sm-pb-0.row.mx-0.col-xl-9 {
  align-items: center;
  justify-content: center;
}

.right-side-border,
.right-side-border2 {
  padding-top: 0 !important;
}
#logo_row {
  padding: 0;
  .col-item {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }
  .right-side-border {
    position: relative;
  }
  .right-side-border:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: calc(50% - 30px);
    height: 40px;
    width: 50%; /* or 100px */
    border-right: 2px solid #e9ecef;
  }
}
.toprow-sm {
  padding: 5px 0;
}
@media (max-width: 1199.98px) {
  .left-nobord {
    border-left: none !important;
  }
  .col-item {
    padding-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .img-header-col {
    text-align: center;
  }
  .toprow-sm {
    min-height: 30px !important;
    padding-bottom: 5px;
    font-size: 14px;
  }
}
@media (min-width: 640px) {
  .h-md-100 {
    height: 100% !important;
  }
}
@media (max-width: 767px) {
  .toprow-sm {
    line-height: 1;
  }
  .mid-box {
    padding-left: 0;
    padding-right: 0;
  }
  .right-side-border,
  .right-side-border2 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .sm-pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 600px) {
  .sm-no-border:before {
    border-right: none !important;
  }
}
</style>
