<template>
      <div>
    <div class="container login">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12  login2 cards">
           <div class="locale-changer" style='position: absolute; right: 10px; top: 10px;'>
            <languageSwitch />
          </div>
            <div class='float-left back-arrow' @click='goBack()'>
                <font-awesome-icon icon="arrow-left" size="lg"/>
            </div>
          <img src="../../assets/images/Longtail-logo2016.jpg" class="mb-4" style='width: 100%;'>
          <form class @submit.prevent="sendEmail">
            <div class="form-group text-left">
              <span class="login-header text-left">{{$t('login.password_reset')}}</span>
              <p>{{$t('login.enter_email')}}</p>
              <h5>{{$t('login.email')}}</h5>
              <input required v-model="email" class="form-control email" type="email">
              <b-alert variant="success"
             dismissible
             :show="success"
             >Email Sent</b-alert>
              <b-alert variant="danger"
             dismissible
             :show="failure"
             >{{error}}</b-alert>
              <button class="login100-form-btn btn btn-secondary" type="submit">{{$t('login.send_email')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDashboardURL } from '../../utils/Utilities'
import axios from 'axios'
import languageSwitch from '../../components/ui/languageSwitch'

export default {
    data () {
        return {
            email: '',
            success: false,
            failure: false,
            error: ''
        }
    },
    components: {
      languageSwitch
    },
    methods: {
        goBack: function(){
            this.$router.push("/login")
        },
        sendEmail: function() {
            axios.get(getDashboardURL() + '/api/request_token').then((nonce_response)=>{
                if('success' === nonce_response.data.status) {
                    axios.post(getDashboardURL() + '/api/password/email', {
                      email: this.email,
                      _nonce: nonce_response.data.data._nonce
                    }).then((response) => {
                        let status = response.data.status
                        if (status == 'success'){
                          this.success = true
                          this.failure = false
                        } else {
                          this.failure = true
                          this.error = response.data.message
                        }
                      }).catch(() => {
                        this.error = 'The given data was invalid.'
                        this.failure = true
                        this.success = false
                    })
                } 
            }).catch(() => {
              this.error = 'Sorry, we are experiencing technical issues. Please try again later'
              this.failure = true
              this.success = false
            })
        }
    }
}
</script>

<style scoped>

.back-arrow{
    position: relative;
    bottom: 20px;
    cursor: pointer;
}
.email{
  margin-bottom: 1rem;
}
</style>

