export default {
    computed : {
        nonEmpty_filter_values() {
            const empty = [];
            Object.keys(this.filter_store).forEach(key => {
                if (this.filter_store[key].length > 0) {
                    empty.push([key, this.filter_store[key]]);
                }
            });
            return empty;
        },
        convert_nonEmpty_filter_value_toString() {
            var x = {};
            this.nonEmpty_filter_values.forEach(value => {
                var numVal = value[1][1]
                if (value[0] == 'bounce_rate30d') {
                    numVal = numVal / 100
                }
                var string1 = `filter[${value[0]}][operator]`;
                var string2 = `filter[${value[0]}][value]`;
                (x[string1] = value[1][0]), (x[string2] = numVal);
                return;
            });
            return x;
        }
    }
}