import { Bar } from "vue-chartjs";
import { yAxisOptions } from "../../../utils/chartOptions.js";
import { prevMonths } from "../../../utils/Utilities.js";

export default {
  props: [
    "chartData",
    "labels",
    "left_axis",
    "dataLabel",
    "locale",
    "revenue",
    "forecastData",
    "forecastLabels",
    "rendered",
  ],
  extends: Bar,
  name: "BarChart",
  computed: {
    // maxValues() {
    //   console.log(this.chartData.revenue)
    //   return Math.max(...this.forecastData.forecast_high);
    // },
    barChart() {
      return {
        labels: this.forecastData
          ? this.forecastLabels
          : prevMonths().reverse(),
        datasets:  !this.forecastData ? [
              {
                // sessions
                label: this.labels[1],
                fill: "none",
                borderColor: "#2f2f2f",
                backgroundColor: this.getGradient("#4c4c4c", "#2f2f2f"),
                hoverBackgroundColor: "#6c757d",
                lineTension: 0,
                yAxisID: "A",
                data: this.chartData.sessions,
                type: "bar",
              },
              {
                // revenue / leads
                label: this.labels[0],
                fill: "none",
                backgroundColor: this.getGradient("#ffefbf", "#ffc001"),
                hoverBackgroundColor: "#ffefbf",
                lineTension: 0,
                borderColor: "#ffd75f",
                yAxisID: "B",
                data: this.revenue
                  ? this.chartData.revenue
                  : this.chartData.conversions,
                type: "bar",
              },
        ] : [
          {
            // sessions
            label: this.labels[1],
            fill: "none",
            borderColor: "#2f2f2f",
            backgroundColor: this.getGradient("#4c4c4c", "#2f2f2f"),
            hoverBackgroundColor: "#6c757d",
            lineTension: 0,
            yAxisID: "A",
            data: this.chartData.sessions,
            type: "bar",
          },
          {
            // revenue / leads
            label: this.labels[0],
            fill: "none",
            backgroundColor: this.getGradient("#ffefbf", "#ffc001"),
            hoverBackgroundColor: "#ffefbf",
            lineTension: 0,
            borderColor: "#ffd75f",
            yAxisID: "B",
            data: this.revenue
              ? this.chartData.revenue
              : this.chartData.conversions,
            type: "bar",
          },
            // forecast data 
            
              {
                // forecast high
                label: this.$t("chart_data.forecast_high"),
                data: this.forecastData.forecast_high,
                type: "line",
                fill: false,
                lineTension: 0,
                backgroundColor: "#fff",
                borderColor: "#ffc001",
                pointRadius: 0,
                yAxisID: "B",
              },
              {
                // forecast low
                label: this.$t("chart_data.forecast_low"),
                data: this.forecastData.forecast_low,
                type: "line",
                fill: "-1",
                lineTension: 0,
                backgroundColor: "#ffc00114",
                borderColor: "#ffc001",
                pointRadius: 0,
                yAxisID: "B",
              },
            ],
      };
    },
    chartOptions() {
      // var _this = this;
      return {
        spanGaps: false,
        plugins: {
          filler: {
            propagate: false,
          },
          datalabels: {
            display: this.dataLabel,
            color: "black",
            textAlign: "center",
            font: {
              weight: "bold",
              size: 12,
            },
            anchor: "end",
            align: "top",
          },
        },
        cornerRadius: 20,
        responsive: true,
        legend: {
          display: true,
          position: "bottom",
          labels: {
            // filter: function(item) {
            //   // if forecast data
            //   // if (_this.forecastData) {
            //   //   // dont return forecast low
            //   //   return !item.text.includes(_this.$t("chart_data.forecast_low"));
            //   // }
            //   // if no forecast data
            //   // dont return forecast low or high
            //   // if (
            //   //   item.text.includes(_this.$t("chart_data.forecast_low")) ||
            //   //   item.text.includes(
            //   //     _this.$t("chart_data.forecasted_ramp_up", {
            //   //       rev_lead: _this.revenue
            //   //         ? _this.$t("chart_data.revenue")
            //   //         : _this.$t("chart_data.forecast_leads"),
            //   //     })
            //   //   )
            //   // ) {
            //   //   return false;
            //   // }
            //   return true;
            // },
          },
        },
        maintainAspectRatio: false,
        aspectRatio: 5,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                fontColor: "#c4c6c5",
                fontFamily: "dosis",
                fontSize: 14,
                min: 0,
              },
            },
          ],
          yAxes: [
            yAxisOptions(
              "A",
              "left",
              this.labels[1],
              this.getWindowWidth(),
              true,
              true
            ),
            yAxisOptions(
              "B",
              "right",
              this.labels[0],
              this.getWindowWidth(),
              false,
              true
            ),
          ],
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: true,
          // filter: function(tooltipItem, data) {
          //   console.log(data.datasets[tooltipItem.datasetIndex])

          //   const type = data.datasets[tooltipItem.datasetIndex].type;
          //   if (type === "bar") {
          //     return true;
          //   } else {
          //     return false;
          //   }
          // },
          callbacks: {
            label: (tooltipItem, data) => {
              return [
                tooltipItem.yLabel.toLocaleString() +
                  " " +
                  data.datasets[tooltipItem.datasetIndex].label,
              ];
            },
          },
        },
      };
    },
  },
  methods: {
    getGradient(color1, color2) {
      var grad = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      grad.addColorStop(0, color1);
      grad.addColorStop(1, color2);
      return grad;
    },
    getWindowWidth() {
      var windowWidth = document.documentElement.clientWidth;
      return windowWidth > 900 ? true : false;
    },
  },
  mounted() {
    this.renderChart(this.barChart, this.chartOptions);
  },
  watch: {
    chartData: function() {
      this.renderChart(this.barChart, this.chartOptions);
    },
    rendered: function() {
      this.renderChart(this.barChart, this.chartOptions);
    },
    // revenue: function() {
    //   this.renderChart(this.barChart, this.chartOptions);
    // },
    locale: function() {
      this.renderChart(this.barChart, this.chartOptions);
    },
  },
};
