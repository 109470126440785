export default {
    // The install method will be called with the Vue constructor as
    // the first argument, along with possible options
    install(Vue) {
        Vue.prototype.$watchAll = function (props, callback) {
            props.forEach(prop => {
                // Pass the prop as the first argument to our callback
                this.$watch(prop, callback.bind(null, prop));
            });
        };
    }
}
