
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDownload, faUser, faCalendarAlt, faCalendar, faLongArrowAltDown, faLongArrowAltUp, faArrowLeft, faEdit, faTrash,  faPlusSquare, faInfoCircle, faChartLine, faWindowClose} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CKEditor from '@ckeditor/ckeditor5-vue';
import multiWatch from './plugins.js'
import vSelect from 'vue-select'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import 'vue-select/dist/vue-select.css';
import i18n from './i18n'
import { AlertPlugin } from 'bootstrap-vue'

library.add(faDownload, faUser, faCalendarAlt, faCalendar, faLongArrowAltDown, faLongArrowAltUp, faArrowLeft, faEdit, faTrash, faPlusSquare, faInfoCircle, faChartLine, faWindowClose)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use( ChartJsPluginDataLabels )
Vue.use( CKEditor );
Vue.use( multiWatch );
Vue.use(AlertPlugin)
Vue.component('v-select', vSelect)

// import Axios from 'axios'

// Vue.prototype.$http = Axios;

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = "Bearer " + token;
}

Vue.config.productionTip = false;


new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
