<template>
  <div id="app">
    <link
      rel="stylesheet"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <router-view v-if="!getMaintenanceStatus_value"></router-view>
    <Maintenance v-if="getMaintenanceStatus_value" />
  </div>
</template>

<script>
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import VueCharts from "vue-chartjs";
import VueAxios from "vue-axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { getMaintenanceStatus } from "./utils/Utilities.js";
import Maintenance from "./router/views/maintenance";
import moment from "moment";
import { mapState } from "vuex";
Vue.use(VueAxios, axios);
Vue.use(VueCharts);
Vue.use(BootstrapVue);

export default {
  data() {
    return {
      alreadyRan: true,
      refresh_token_interval: undefined,
    };
  },
  components: {
    Maintenance,
  },
  beforeCreate() {
    let lang = window.localStorage.getItem("language") || window.navigator.language.split("-")[0];
    this.$i18n.locale = lang;
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    getMaintenanceStatus_value() {
      return getMaintenanceStatus();
    },
    ...mapState({
      expireTokenNotBefore: (state) => state.expire_token,
      authToken: (state) => state.auth.token,
      luxStatus: (state) => state.auth.lux_status,
    }),
  },
  methods: {
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    refresh_token: function() {
      // method/function to create an interval for 15 seconds
      let local_this = this;
      this.refresh_token_interval = setInterval(function() {
        //if current time has passed expiry token time then dispatch to store for refresh
        if (Number(moment(new Date()).format("X")) > Number(local_this.expireTokenNotBefore)) {
          local_this.$store.dispatch("refresh");
        }
        //interval is currently set to: 60000 {1min}
      }, 60000);
      //if user is not LUX then clear interval else re-run interval
      if (!this.authToken) {
        clearInterval(this.refresh_token_interval);
        this.refresh_token_interval = undefined;
      }
    },
  },
  watch: {
    // watch for route change and perform function logic to determine if the user is still LUX/logged in
    $route(to) {
      // if route page NOT login and if user is LUX
      if (to.name != "login" && this.luxStatus) {
        this.refresh_token();
      }
    },
  },
  mounted() {
    let hubspotScript = document.createElement("script");
    hubspotScript.setAttribute("src", "//js.hs-scripts.com/2615651.js");
    hubspotScript.setAttribute("id", "hs-script-loader");
    hubspotScript.setAttribute("defer", "");
    document.head.appendChild(hubspotScript);
  },
};
</script>

<style lang="scss">
@import "../src/assets/css/master.scss";
@import url("https://fonts.googleapis.com/css?family=Dosis:400,500,700");
</style>
