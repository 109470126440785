<template>
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <h1>{{errors && errors.data.status}}{{errors && errors.status}}</h1>
      </div>
    </div>
    <div class="row text-center">
        <div class="col">{{errors && errors.data.message}}</div>
    </div>
    <div class="row ">
        <div class="col text-center">
             <b-button class='m-1' @click="back()">BACK</b-button>
      <b-button class='m-1' @click="logout()">LOGOUT</b-button>
        </div>
     
    </div>
  </div>
</template>


<script>
import router from "../router";

export default {
  computed: {
    errors() {
      return this.$store.state.err;
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    back: function() {
      router.push("/");
    }
  }
};
</script>
