<script>
import Header from './Header'

// @vuese 
// @group Layouts 
// Main layout file which loads the header and has a slot for the main content
export default {
  name: "MainContent",
  components: { Header },
}
</script>

<template>
  <div>
    <Header />
    <!-- main content for organic/paid keyword approval pages -->
    <slot />
  </div>
</template>
