<template>
  <div>
    <div v-if="!show_SEM_placeholder_check">
      <LogoRow />
      <TabContainer />
    </div>
    <div class="placeholder" v-if="show_SEM_placeholder_check">
      <Placeholder />
    </div>
  </div>
</template>

<script>
import Placeholder from "../ui/Placeholder";
import TabContainer from "../Rows/TabContainer";
import LogoRow from "../Rows/LogoRow.vue";
import { mapState } from "vuex";
import { get_keywords_sem_api, get_uploadedFiles_API_call } from "@/utils/api.js";

// @vuese
// @group Views
// displays all paid data, shows the logo row, task list keywords and keyword upload functionality
export default {
  name: "PaidView",
  components: {
    TabContainer,
    LogoRow,
    Placeholder,
  },
  computed: {
    ...mapState({
      selectedClientTypes: (state) => state.clientList.selectedClient.types,
      clientList: (state) => state.clientList.clientList,
      selectedClientName: (state) => state.clientList.selectedClient.name,
    }),
    // if client doesnt have a launch date or is not a sem client show a placeholder
    show_SEM_placeholder_check() {
      if (!this.selectedClientTypes.includes("sem") || !this.launch_date) {
        return true;
      }
      return false;
    },
    launch_date() {
      return !!this.clientList[this.selectedClientName].sem_launch_date;
    },
  },
  created() {
    const client = this.$route.params.id;
    if (this.selectedClientTypes.includes("sem")) {
      // fetch client paid data
      get_keywords_sem_api(client);
      get_uploadedFiles_API_call(client, "sem");
    }
  },
};
</script>
