<template>
  <div class="relative">
    <button
      href="#"
      class="flex items-center btn btn-outline-secondary"
      @click="toggleVisibility"
    >
      <!-- <img :src="currentFlag" alt="flag" class="w-8 h-8" /> -->
      <span class="ml-2 grey">{{ $i18n.locale.toUpperCase() == 'JA' ? '日本語' : 'English' }}</span>
      <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          class="heroicon-ui"
          d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
        />
      </svg>
    </button>
    <transition name="dropdown-fade">
      <ul
        v-on-clickaway="hideDropdown"
        v-if="isVisible"
        ref="dropdown"
        class="absolute normal-case z-30 font-normal xs:left-0 lg:right-0 bg-white shadow overflow-hidden rounded w-48 border mt-2 p-1 lg:z-20"
        style='position: absolute;'
      >
        <li>
          <a
            href="#"
            @click.prevent="setLocale('en')"
            ref="account"
            class="flex items-center px-2 py-2 hover:bg-gray-200 blk"
            @keydown.up.exact.prevent
            @keydown.esc.exact="hideDropdown"
          >
            <!-- <img src="../../assets/images/flags/en.png" alt="english flag" class="h-8 w-8" /> -->
            <span class="ml-2 grey">English</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            @click.prevent="setLocale('ja')"
            class="flex items-center px-2 py-2 hover:bg-gray-200 blk"
            @keydown.down.exact.prevent
            @keydown.tab.exact="hideDropdown"
            @keydown.esc.exact="hideDropdown"
          >
            <!-- <img src="../../assets/images/flags/ja.png" alt="english flag" class="h-8 w-8" /> -->
            <span class="ml-2 grey">日本語</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
export default {
    mixins: [ clickaway ],
  data() {
    return {
      isVisible: false,
      focusedIndex: 0
    };
  },
  computed: {
    currentFlag() {
      return require(`../../assets/images/flags/${this.$i18n.locale.toLowerCase().split('-')[0]}.png`)
    }
  },
  methods: {
    changeLang(locale) {
      localStorage.setItem("language", locale);
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    setLocale(locale) {
      this.changeLang(locale);
      this.$i18n.locale = locale;
      this.hideDropdown();
    }
  }
};
</script>

<style scoped>
.dropdown-fade-enter-active,
.dropdown-fade-leave-active {
  transition: all 0.1s ease-in-out;
}
.dropdown-fade-enter,
.dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}
ul {
  list-style: none;
}
li{
  margin: 5px 0;
  width: 86px;
}
.btn{
  padding: .20rem 0.4rem;
}
.btn span.ml-2 {
  font-weight:500;
  font-size:16px;
}
</style>