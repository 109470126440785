<template>
  <div>
    <div class="container login">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 login2 cards" style="position: relative">
          <div class="locale-changer" style="position: absolute; right: 10px; top: 10px;">
            <languageSwitch />
          </div>
          <div class="userpic">
            <font-awesome-icon icon="user" />
          </div>
          <div class="expiry" v-if="sessionExpiry">Session Expired</div>
          <img src="../../assets/images/Longtail-logo2016.jpg" class="mb-4 logo" />
          <form class @submit.prevent="login">
            <div class="form-group text-left">
              <span class="login-header">{{ $t("login.member_login") }}</span>
              <h5>{{ $t("login.email") }}</h5>
              <input required v-model="email" class="form-control" type="email" />
              <h5>{{ $t("login.password") }}</h5>
              <span @click="forgot_password()" class="f_password">{{ $t("login.forgot_password") }}</span>
              <input required v-model="password" type="password" class="form-control" />
              <b-alert variant="danger" dismissible :show="errors">Incorrect Login or Password</b-alert>
              <b-alert variant="success" dismissible :show="notification && !errors">{{ notificationMessage }}</b-alert>
              <button class="login100-form-btn btn btn-secondary" type="submit">{{ $t("login.login") }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import languageSwitch from "../../components/ui/languageSwitch";
import { mapState } from "vuex";

// @vuese
// @group Views
// Login page, other pages will redirect here if not authorized
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapState({
      sessionExpiry: "expiryError",
      notification: (state) => state.auth.notification,
      notificationMessage: (state) => state.auth.notificationMessage,
    }),
    errors() {
      return this.$store.state.auth.status === "error";
    },
  },
  methods: {
    // @vuese
    // pass the email and password to the login function in the store. If succesful response push to clientList page
    login() {
      let email = this.email;
      let password = this.password;
      this.$store
        .dispatch("login", { email, password })
        .then(() => this.$router.push(`/clientList`))
        .catch((err) => console.log(err));
    },
    forgot_password() {
      this.$router.push(`/password/reset`);
    },
  },
  components: {
    languageSwitch,
  },
};
</script>

<style scoped>
.login2 {
  max-width: 400px;
}
.f_password {
  float: right;
  color: #07c;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}
h5 {
  font-weight: 700;
  display: inline-block;
  margin: 0;
}
.login {
  margin-top: 40px;
}
.unAuthorized {
  background-color: #a22;
  color: white;
}
.userpic {
  text-align: center;
  width: 80px;
  height: 80px;
  background-color: #454945;
  margin: 0 auto 20px auto;
  border-radius: 50%;
  color: white;
  font-size: 50px;
  margin-top: -90px;
}
.login2 {
  width: 380px;
  margin: 4em auto;
  padding: 3em 2em 2em 2em;
  background: #ffffff;
  border: 1px solid #ebebeb;
}
input {
  margin: 10px 0;
  padding: 15px;
}
button {
  margin-top: 20px;
  width: 10%;
  cursor: pointer;
}
p {
  margin-top: 40px;
  font-size: 13px;
}
p a {
  text-decoration: underline;
  cursor: pointer;
}
.expiry {
  color: red;
}
</style>

<style lang="scss">
@import "@/assets/css/login.scss";
@import url("https://fonts.googleapis.com/css?family=Dosis:400,500,700");
@media (max-width: 1344px) {
}

@media (max-width: 1199.98px) {
}

@media (min-width: 767px) and (max-width: 1045px) {
}

@media (max-width: 767.98px) {
  .login {
    padding: 0;
  }
}

@media (max-width: 575.98px) {
}
</style>
