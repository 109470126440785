<template>
  <nav
    class="navbar navbar-dark bd-navbar sticky-top navbar-light navbar-expand-md bg-w"
    style="border-bottom: 1px solid #e8e8e8;"
  >
    <!-- start mobile  -->
    <b-dropdown id="hidden-dropdown" text class="m-md-2" no-caret variant="outline-light">
      <template slot="button-content">
        <img src="../../assets/images/hamburger-icon.svg" />
      </template>
      <!-- OrganicPaidSlider  -->
      <li id="mobile-button">
        <OrganicPaidToggle  :organicPaid="seo_sem_boolean_getter"/>
      </li>
      <b-dropdown-divider></b-dropdown-divider>
      <!-- keyword approval and homepage links  -->
       <NavLinks :clientName="selectedClientName" :currentPage="currentPage" :organicPaid="seo_sem_string_getter"/>
      <b-dropdown-divider></b-dropdown-divider>
      <!-- language switch  -->
      <li>
        <LanguageSwitch />
      </li>
      <b-dropdown-divider></b-dropdown-divider>
      <!-- PDF viewer  -->
      <li>
        <span @click="PDFViewer()" class="mobile-PDFbutton">PDF view</span>
      </li>
      <b-dropdown-divider></b-dropdown-divider>
      <!-- logout  -->
      <li>
        <div class="m-auto p-0">
          <a @click="logout" class="h-100 logout-anchor">
            <div class="pr-2 logout-img"></div>
            <div class="mobile-logout">
              {{ $t("nav.logout") }}
            </div>
          </a>
        </div>
      </li>
    </b-dropdown>
    <!-- lux logo  -->
    <a href="/" target="_self" class="navbar-brand p-0">
      <img src="../../assets/images/LongtailUXsm2.png" class="LUXlogo" />
    </a>
    <!-- client selection dropdown  -->
    <span class="nav-item clientList1">
      <ClientSelectDropdown />
    </span>
    <!-- end mobile  -->

    <div class="justify-content-between navbar-collapse collapse" :class="{ show: !nav_hidden_toggle }">
      <!-- keyword approval and homepage links  -->
      <NavLinks :clientName="selectedClientName" :currentPage="currentPage" :organicPaid="seo_sem_string_getter" />
      <ul class="navbar-nav">
        <!-- client selection dropdown  -->
        <li class="left-li nav-item dropdown clientList2 grey">
          <ClientSelectDropdown />
        </li>
        <!-- PDF viewer  -->
        <li class="nav-item">
          <img @click="PDFViewer()" src="../../assets/images/PDF.png" alt class="PDFbutton" />
        </li>
        <!-- OrganicPaidSlider -->
        <li class="mid-li nav-item">
          <OrganicPaidToggle :organicPaid="seo_sem_boolean_getter"/>
        </li>
        <!-- language switch  -->
        <li class="nav-item">
          <div class="locale-changer">
            <LanguageSwitch />
          </div>
        </li>
        <!-- logout button  -->
        <li class="nav-item">
          <div class="seo-sem logout m-auto text-right p-0">
            <a @click="logout" class="h-100 logout-anchor">
              <div class="pr-2 logout-img"></div>
              <span style="margin: auto;">{{ $t("nav.logout") }}</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import LanguageSwitch from "../../components/ui/languageSwitch";
import OrganicPaidToggle from "../../components/ui/OrganicPaidToggle";
import ClientSelectDropdown from "../../components/ui/ClientSelectDropdown";
import NavLinks from "../../components/ui/NavLinks";

// @vuese
// @group Layouts
export default {
  name: "Header",
  components: {
    LanguageSwitch,
    OrganicPaidToggle,
    ClientSelectDropdown,
    NavLinks,
  },
  data() {
    return {
      nav_hidden_toggle: true,
    };
  },
  computed: {
    currentPage() {
      return this.$route.name;
    },
    ...mapState({
      clientList: (state) => state.clientList.clientList,
      selectedClientName: (state) => state.clientList.selectedClient.name,
      selectedClient: (state) => state.clientList.selectedClient.client,
      kw_awaiting_approval_page: (state) => state.keywordApproval.kw_awaiting_approval_page,
    }),
    ...mapGetters(['seo_sem_string_getter', 'seo_sem_boolean_getter']),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    PDFViewer() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
.logout-anchor {
  display: flex;
  cursor: pointer;
}
.logout-img {
  background-image: url("~@/assets/images/2-exit.png");
  background-repeat: no-repeat;
  width: 27px;
  height: 32px;
  display: inline-block;
}
.logout-anchor:hover .logout-img {
  background-image: url("~@/assets/images/2-exithover.png");
}

li.nav-item.kw-approval {
  margin-right: 5px;
  &:after {
    content: " ";
    border-bottom: 2px solid #ffc001;
    border-bottom-width: 2px;
    display: table-cell;
    width: 0px;
    position: absolute;
    bottom: -28px;
    transition: all 0.5s;
  }
  position: relative;
  &:hover:after {
    content: " ";
    border-bottom: 2px solid #ffc001;
    border-bottom-width: 2px;
    display: table-cell;
    width: 100%;
    max-width: 134px;
    transition: all 0.5s;
    position: absolute;
    bottom: -28px;
  }
  .keyword-approval-tab {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a5a5a5;
    &:hover {
      text-decoration: none;
    }
  }
}
.navbar-brand {
  margin-right: 0;
}
.LUXlogo {
  max-width: 200px;
  display: block;
}
.PDFbutton {
  cursor: pointer;
}

.left-li {
  max-width: none !important;
}
.mobile-PDFbutton,
.mobile-logout {
  font-size: 16px;
  color: #2f2f2f !important;
  margin: auto 0;
}
.header-row {
  min-height: 60px;
}
.left-li {
  max-width: 28%;
}
.logout-col {
  padding: 0 7px;
}
.logout {
  font-size: 16px;
}
.dropdown-item {
  text-transform: capitalize;
}
.navbar-toggler {
  border: none !important;
}
.clientList1 {
  display: none;
}
.clientList2 {
  display: inline-block;
}
ul {
  display: flex;
  list-style: none;
  flex-direction: row;
  float: right;
  padding-left: 0;
  margin: 5px;
  li {
    margin: auto 5px;
  }
  .mid-li {
    min-width: 90px;
  }
}

.header ul {
  flex-direction: column;
}

.row {
  margin: 0;
}

.navbar-nav {
  justify-content: space-between;
}
.header {
  .lux-logo {
    margin: auto;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .notifications,
  .logout {
    margin: auto !important;
    text-align: left;
  }
}
.col-sm-6 {
  max-width: 50% !important;
}
.logout-anchor {
  span {
    color: rgb(165, 165, 165);
    vertical-align: middle;
  }
  img {
    -moz-transition: padding-left 0.3s ease-in;
    -o-transition: padding-left 0.3s ease-in;
    -webkit-transition: padding-left 0.3s ease-in;
    transition: padding-left 0.3s ease-in;
    background-position: left center;
    background-repeat: no-repeat;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    span {
      color: #454945;
    }
  }
}

@media (max-width: 1199.98px) {
}
@media (min-width: 1199.98px) {
  .logout-col {
    ul {
      margin-right: 15%;
    }
  }
  .navbar {
    padding: 0.5rem 5rem;
  }
}
@media (min-width: 1025px) {
  #hidden-dropdown {
    display: none;
  }
}
@media (max-width: 1024.98px) {
  .collapse:not(.show) {
    display: none !important;
  }
  .navbar {
    justify-content: space-between !important;
  }
  li {
    padding: auto 20px;
  }
  .kwApprovals {
    display: none;
  }
  .keyword-approval-tab {
    color: #2f2f2f !important;
    &:hover {
      text-decoration: none;
    }
  }
  .seo-sem {
    font-size: 14px;
    line-height: 25px;
  }
  .clientList1 {
    display: inline-block;
  }
  .clientList2 {
    display: none;
  }

  .LUXlogo {
    max-width: 120px;
  }
  .hide-mobile {
    display: none;
  }
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 900;
    background-color: #fff;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  ul {
    float: none;
    margin-bottom: 0;
  }
  .logout,
  .lux-logo {
    margin: 0 !important;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
    padding-right: 0;
  }
  .logout-wrapper {
    height: 50px !important;
  }
  .switch-wrapper {
    background: #faf9f5;
    padding-top: 15px;
  }
  .switch {
    span:hover {
      cursor: pointer;
    }
  }
  .slider {
    &:before {
      height: 84%;
    }
  }
}
</style>
