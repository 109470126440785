<template>
  <div>
    <div class="container login">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 login2 cards">
          <img src="../../assets/images/Longtail-logo2016.jpg" class="mb-4" />

          <form class @submit.prevent="resetPass">
            <div class="form-group text-left">
              <h5>Email</h5>
              <input required v-model="email" class="form-control" type="email" />
              <h5>Password</h5>
              <input required v-model="password" class="form-control" type="password" />
              <h5>Confirm Password</h5>
              <input required v-model="password2" class="form-control email" type="password" />

              <b-alert variant="danger" :show="failure">{{ error }}</b-alert>

              <button class="login100-form-btn btn btn-secondary" type="submit">Reset password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDashboardURL } from "@/utils/Utilities.js";
import axios from "axios";

export default {
  data() {
    return {
      token: this.$route.params.id,
      password: "",
      password2: "",
      email: "",
      failure: false,
      error: "",
    };
  },
  methods: {
    resetPass: function() {
      let email = this.email;
      let password = this.password;
      if (this.password.length > 5) {
        if (this.password == this.password2) {
          axios
            .get(getDashboardURL() + "/api/request_token")
            .then((nonce_response) => {
              if ("success" === nonce_response.data.status) {
                axios
                  .post(getDashboardURL() + "/api/password/reset", {
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password2,
                    _nonce: nonce_response.data.data._nonce,
                  })
                  .then((response) => {
                    let status = response.data.status;

                    if (status == "success") {
                      this.$store.state.auth.notification = true;
                      this.$store.state.auth.notificationMessage = "Password updated";
                      // this.$router.push("/login");
                      this.$store
                        .dispatch("login", { email, password })
                        .then(() => this.$router.push("/clientList"))
                        .catch((err) => console.log(err));
                    } else {
                      this.failure = true;
                      this.error = response.data.message;
                    }
                  })
                  .catch(() => {
                    this.error = "Sorry, we are experiencing technical issues. Please try again later";
                    this.failure = true;
                  });
              }
            })
            .catch(() => {
              this.error = "Sorry, we are experiencing technical issues. Please try again later";
              this.failure = true;
            });
        } else {
          this.failure = true;
          this.error = "Password confirmation does not match Password";
        }
      } else {
        this.failure = true;
        this.error = "Password must be atleast 6 characters";
      }
    },
  },
};
</script>

<style>
h5 {
  margin-top: 8px;
}
.email {
  margin-bottom: 1rem;
}
</style>
