import { getDashboardURL, axiosHeaders } from "../../utils/Utilities";
import axios from "axios";

export default {
  state: {
    seo_csvList: [],
    sem_csvList: [],
    sem_csvList_Ready: false,
    seo_csvList_Ready: false,
  },
  getters: {},
  mutations: {
    updateFiles(state, data) {
      data.seoState === "seo" ? (state.seo_csvList = data.response.data) : (state.sem_csvList = data.response.data);
      state[`${data.seoState}_csvList_Ready`] = true;
    },
  },
  actions: {
    uploadedFiles_API_Call({ commit }, data) {
      axios
        .get(
          getDashboardURL() + `/api/uploaded-keywords/${data.client}/${data.seoState}/list?per_page=10&page=1`,
          axiosHeaders()
        )
        .then((response) => {
          commit("updateFiles", { response: response, seoState: data.seoState });
        });
    },
  },
};
