
<template>
  <div class='cards bg-w'>
      <div class="wrapper ">
        <div class="flex">
          <div class="left">
            <h2 v-if='!sem_seo_boolean' v-html="$t('sem_placeholder.title')"></h2>
            <h2 v-if='sem_seo_boolean'>Unique control & measurable ROI for SEO</h2>
            <div class='patent grey' v-if='!sem_seo_boolean'>
                <span>{{$t('sem_placeholder.sem_placeholder1')}}</span>
            </div>
            <div class="list-wrapper grey">
              <div class='flex-center'>
                <div>
                <span>Longtail UX:</span>
              <ul v-if='!sem_seo_boolean'>
                <li>{{$t('sem_placeholder.sem_placeholder2')}}</li>
                <li>{{$t('sem_placeholder.sem_placeholder3')}}</li>
                <li>{{$t('sem_placeholder.sem_placeholder4')}}</li>
              </ul>
              
              <ul v-if='sem_seo_boolean'>
                <li>Identifies Long tail content opportunities</li>
                <li>Creates highly relevant landing pages with the best matching products and</li>
                <li>Automatically integrates new pages into the existing website</li>
              </ul>
              </div>
              </div>
              <div class="right2">
            <img
              class="avia_image"
              src="https://longtailux.com/app/uploads/2019/05/SEO@2x@2x-6.png"
              alt
              title="SEO@2x@2x"
              height="500"
              width="830"
              itemprop="thumbnailUrl"
            >
          </div>
              
            </div>
          </div>
          <!-- <div class="right">
            <img
              class="avia_image"
              src="https://longtailux.com/app/uploads/2019/05/SEO@2x@2x-6.png"
              alt
              title="SEO@2x@2x"
              height="500"
              width="830"
              itemprop="thumbnailUrl"
            >
          </div> -->
          <div class="bottom">
            <h5>{{$t('sem_placeholder.sem_placeholder5')}}</h5>
          </div>
        </div>
      </div>
    <div class="demo">
      <div class="demo-wrapper">
        <div>
          <h2 v-html="$t('sem_placeholder.sem_placeholder6')"></h2>
        </div>
        <div>
            <a href="https://longtailux.com/book-a-demo/ " target='_blank'>
                <div >
                  <button class="demo-btn btn">{{$t('sem_placeholder.sem_placeholder7')}}</button>
                </div>
            </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sortBy: "sessions"
    };
  },
  computed: {
    sem_seo_boolean() {
      return this.$store.state.seosem
    }
  },
  methods: {}
};
</script>

<style scoped>
*{
  font-family: calibri;
}
.btn{
  background-color: #ffce31;
  border-color: #ffce31;
  font-size: 21px;
  padding: 15px 30px 13px;
  border-radius: 3px;
}
h2{
    font-size: 40px;
    color: rgb(102, 102, 102);
    font-weight: 400;
}
h3{
    font-size: 36px;
}
h5{
  font-weight: 400;
}
.patent{
    font-size: 22px;
    font-weight: 300;
}
.wrapper {
  padding: 80px;
}
.left {
  width: calc(100% - 550px);
  flex-grow: 1;
  margin-right: 50px;
}
.left ul {
  list-style: none;
}
.left ul li {
  position: relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  /* use padding-bottom instead of margin-bottom.*/
  margin-bottom: 0; /* This overrides previously specified margin-bottom */
}

.left ul li:after {
  /* bullets */
    content: "\25EF";
    position: absolute;
    left: -24px;
    top: 7px;
    font-size: 8px;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.right img, .right2 img {
  width: 100%;
  height: auto;
}
.right {
  max-width: 500px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  /* margin-bottom: 30px; */
}
.demo {
  text-align: center;
  background-color: #f5f4ec;
}
.demo-wrapper {
  padding: 20px;
}
.list-wrapper {
  margin: 20px 0;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  justify-content: space-evenly;
}
.right2{
    display: block;
    width: 35%
  }
.demo-btn {
  margin: 20px;
}
@media (max-width: 1200px){
  .right{
    display: none;
  }
  h2{
    font-size: 28px;
  }
  
    .list-wrapper{
    justify-content: space-between;
  }
  .wrapper {
    padding: 40px;
  }
  .bottom{
    margin-top: 20px;
  }
}
@media(max-width: 930px){
  .right{
    width: 100%;
  }
  .left{
    width: 100%;
  }

}

@media (max-width: 767.98px) {
  ul{
    padding-inline-start: 30px!important;
  }
  .right2{
    display: none;
  }
  .left{
    margin-right: 0;
  }
  .flex{
    justify-content: center;
  }
    .right, .left{
      display: block;
        width: 100%;
    }
    .wrapper {
      padding: 20px;
    }
    h2{
        font-size: 26px;
    }
    h3{
        font-size: 30px;
    }
    .patent{
        font-size: 20px;
        font-weight: 300;
    }
    .list-wrapper {
      font-size: 16px;
    }
}
</style>