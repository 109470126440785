import store from "../store/store";
import { prevMonth, firstMonth, getDashboardURL, errorHandling, axiosHeaders } from "./Utilities";
import { compact } from "lodash";
import axios from "axios";


const CancelToken = axios.CancelToken;
let cancel;

export function clientDataAPICall( data ) {
  var cancel_request = store.state.clientData.cancel_request
  if (data.cancel) {
    if (cancel_request) {
      cancel();
    }
    cancel_request = true;
  }
  return new Promise((resolve, reject) => {
    const {
      url,
      client,
      type,
      sort,
      page,
      per_page,
      order,
      filter_data,
      month,
      fetchName,
      name,
      updateClient,
    } = data;
    // makes array with all false values removed
    const urlSplit = compact([getDashboardURL(), url, client, type, month]);
    axios
      .request({
        method: "GET",
        url: urlSplit.join("/"),
        headers: axiosHeaders().headers,
        params: {
          sort,
          page,
          per_page,
          order,
          ...filter_data,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
      .then((response) => {
        store.state.clientData.cancel_request = false;
        store.commit("updateData", {
          fetchName: fetchName,
          name: name,
          response: response,
        });
        type === "sem" ? store.commit("SEM_Success_Commit") : false;
        updateClient ? store.commit("clientList/updateCurrentClientData", client, { root: true }) : false;
        resolve();
      })
      .catch((err) => {
        type === "seo" ? errorHandling(err) : store.commit("SEM_Fail_Commit");
        reject();
      });
  });
}

export function get_keyword_report_api(client) {
  clientDataAPICall({
    client: client,
    type: "seo",
    url: "api/get-keyword-report",
    commitName: "updateData",
    fetchName: "static_data_fetched",
    name: "staticData",
  });
}

export function get_keyword_performance_summary_api(client) {
  clientDataAPICall({
    client: client,
    type: "seo",
    url: "api/get-keyword-performance-summary",
    commitName: "updateData",
    month: prevMonth(),
    fetchName: "month_data_fetched",
    name: "monthData",
  });
}

// used to get the conversion rate % for the previous year so we can get a YOY value
// hopefully can be removed once conversion rate YOY is added to the API 
export function get_keyword_performance_summary_first_api(client) {
  clientDataAPICall({
    client: client,
    type: "seo",
    url: "api/get-keyword-performance-summary",
    commitName: "updateData",
    month: firstMonth(),
    fetchName: "first_month_data_fetched",
    name: "firstMonthData",
  });
}

export function keyword_performance_chart_api(client) {
  clientDataAPICall({
    client: client,
    type: "seo",
    url: "api/keyword-performance-chart",
    commitName: "updateData",
    fetchName: "chart_data_fetched",
    name: "chartData",
  });
}

export function ranking_summary_api(client) {
  clientDataAPICall({
    client: client,
    url: "api/ranking-summary",
    commitName: "updateRankingChartData",
    fetchName: "ranking_chart_data_fetched",
    name: "rankingChartData",
  });
}

export function get_keywords_seo_api(client) {
  clientDataAPICall({
    client: client,
    type: "seo",
    per_page: 100,
    url: "api/get-keywords",
    commitName: "updateData",
    fetchName: "TPK_data_fetched",
    name: "TPKData",
  });
}

export function keyword_approval_API_call(client, seo_val) {
  store.dispatch("keyword_approval_API_call", {
    client: client,
    per_page: 100,
    url: `api/get-keywords/${client}/${seo_val}/awaiting-approval`,
    commitName: "updateData2",
    fetchName: "TPK_data_fetched",
    name: "TPKData",
  });
}

export function get_keywords_sem_api(client) {
  clientDataAPICall({
    client: client,
    type: "sem",
    per_page: 100,
    url: "api/get-keywords",
    commitName: "SEMupdateData",
    fetchName: "SEM_TPK_data_fetched",
    name: "SEMTPKData",
  });
}

export function get_uploadedFiles_API_call(client, seoState) {
  store.dispatch("uploadedFiles_API_Call", {
    client: client,
    seoState: seoState,
  });
}

export function SEO_API_calls(client) {
  get_keyword_performance_summary_api(client);
  get_keyword_performance_summary_first_api(client);
  keyword_performance_chart_api(client);
  ranking_summary_api(client);
  get_keywords_seo_api(client);
}
