<template>
  <div>
    <div class="container login">
      <div class="row">
        <div class="col-md-6 col-sm-12 login2 cards">
          <div>
            <img src="../../assets/images/Longtail-logo2016.jpg" class="mb-4 logo" />
          </div>
          <!-- show a spinner widget while waiting for API response  -->
          <div class="d-flex justify-content-center mb-3" v-if="client_list_load">
            <bounce-loader :color="'#f8c348'"></bounce-loader>
          </div>
          <b-list-group v-if="Ready">
            <!-- loop through each client and output a router link for each  -->
            <router-link
              v-for="(client, key, index) in clientList"
              :key="index"
              :to="{ name: 'Client', params: { id: key } }"
              class="list-group-item list-group-item-action"
              >{{ client.name }}</router-link
            >
          </b-list-group>
          <b-button class="logout" @click="logout()">{{ $t("nav.logout") }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BounceLoader from "vue-spinner/src/BounceLoader.vue";
import { mapState } from "vuex";

// @vuese
// @group Views
// List of clients available to the user. Shown after logging in successfully
export default {
  created() {
    // fetch client list data on page load
    this.$store.dispatch("getClientList");
  },
  computed: mapState({
    clientList: (state) => state.clientList.clientList,
    Ready: (state) => state.clientList.client_list_ready,
    client_list_load: (state) => state.clientList.client_list_load,
  }),
  methods: {
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  components: {
    BounceLoader,
  },
};
</script>

<style lang="scss">
.list-group-item {
  text-transform: capitalize;
}
.login2 {
  min-width: 400px;
  max-width: 400px !important;
  margin: 4em auto;
  padding: 3em 2em 2em 2em;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px;
}
.list-group {
  max-height: 36vh;
  overflow-y: scroll;
}
.list-group::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.list-group::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.list-group::-webkit-scrollbar-thumb {
  background-color: #6c757d;
}

.logout {
  width: 100%;
  margin-top: 1rem;
}

@media (max-width: 769px) {
  .login2 {
    min-width: 90%;
  }
}
</style>
