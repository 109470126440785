import i18n from "@/i18n";
import store from "../store/store";

export function table_fields() {
    return {
      seo_rev: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "keyword",
          label: i18n.t("tab_container.keywords.keyword"),
          sortable: true,
          sortDirection: "desc",
          stickyColumn: true,
          variant: "light",
          class: "keyword-row",
        },
        {
          key: "category_name",
          label: i18n.t("tab_container.keywords.category"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "google_monthly_search_volume",
          label: i18n.t("tab_container.keywords.gmsv"),
          sortable: true,
          sortDirection: "desc",
          class: "gmsv-row",
        },
        {
          key: "fkms",
          label: i18n.t("tab_container.keywords.fkms"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "sessions",
          label: i18n.t("tab_container.keywords.sessions"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "sessions_90d",
          label: i18n.t("tab_container.keywords.sessions_90d"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "bounce_rate30d",
          label: i18n.t("tab_container.keywords.bounce_rate"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "transactions",
          label: i18n.t("tab_container.keywords.transactions"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "revenue",
          label: `(${store.state.clientList.selectedClient.currency}) ${i18n.t("tab_container.keywords.revenue")}`,
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "keywordRank",
          label: i18n.t("tab_container.keywords.keyword_rank"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "seo_rank_change_monthly",
          label: i18n.t("tab_container.keywords.rank_change"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "activated_days",
          label: i18n.t("tab_container.keywords.days_live"),
          sortable: true,
          sortDirection: "desc",
        },
      ],
      seo_leads: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "keyword",
          label: i18n.t("tab_container.keywords.keyword"),
          sortable: true,
          sortDirection: "desc",
          stickyColumn: true,
          class: "keyword-row",
          variant: "light",
        },
        {
          key: "category_name",
          label: i18n.t("tab_container.keywords.category"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "google_monthly_search_volume",
          label: i18n.t("tab_container.keywords.gmsv"),
          sortable: true,
          sortDirection: "desc",
          class: "gmsv-row",
        },
        {
          key: "fkms",
          label: i18n.t("tab_container.keywords.fkms"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "sessions",
          label: i18n.t("tab_container.keywords.sessions"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "sessions_90d",
          label: i18n.t("tab_container.keywords.sessions_90d"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "bounce_rate30d",
          label: i18n.t("tab_container.keywords.bounce_rate"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "transactions",
          label: i18n.t("tab_container.keywords.transactions"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "keywordRank",
          label: i18n.t("tab_container.keywords.keyword_rank"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "seo_rank_change_monthly",
          label: i18n.t("tab_container.keywords.rank_change"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "activated_days",
          label: i18n.t("tab_container.keywords.days_live"),
          sortable: true,
          sortDirection: "desc",
        },
      ],
      sem: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "keyword",
          label: i18n.t("tab_container.keywords.keyword"),
          sortable: true,
          sortDirection: "desc",
          stickyColumn: true,
          class: "keyword-row",
          variant: "light",
        },
        {
          key: "category_name",
          label: i18n.t("tab_container.keywords.category"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "fkms",
          label: i18n.t("tab_container.keywords.fkms"),
          sortable: true,
          sortDirection: "desc",
        },
      ],
      keyword_approval_nonAPI: [
        //   { key: "checkbox", label: "checkbox" },
        {
          key: "index",
          label: "#",
        },
        {
          key: "keyword",
          label: i18n.t("tab_container.keywords.keyword"),
          sortable: true,
          sortDirection: "desc",
          stickyColumn: true,
          class: "keyword-row",
          variant: "light",
        },
        {
          key: "url",
          label: i18n.t("tab_container.keywords.keyword_landing_page"),
        },
        {
          key: "category_name",
          label: i18n.t("tab_container.keywords.category"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "google_monthly_search_volume",
          label: i18n.t("tab_container.keywords.gmsv"),
          sortable: true,
          sortDirection: "desc",
          class: "gmsv-row",
        },
        {
          key: "fkms",
          label: i18n.t("tab_container.keywords.fkms"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "keywordRank",
          label: i18n.t("tab_container.keywords.keyword_rank"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "status",
          label: i18n.t("tab_container.keywords.status"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "updated_at",
          label: i18n.t("tab_container.keywords.last_updated_at"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "comment",
          label: i18n.t("tab_container.keywords.comment"),
          sortable: true,
          sortDirection: "desc",
        },
      ],
      keyword_approval_API: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "keyword",
          label: i18n.t("tab_container.keywords.keyword"),
          sortable: true,
          sortDirection: "desc",
          stickyColumn: true,
          class: "keyword-row",
          variant: "light",
        },
        {
          key: "category_name",
          label: i18n.t("tab_container.keywords.category"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "potential_traffic",
          label: "Potential SV",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "fkms",
          label: i18n.t("tab_container.keywords.fkms"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "seo_rank",
          label: "Rank",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "status",
          label: i18n.t("tab_container.keywords.status"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "updated_at",
          label: i18n.t("tab_container.keywords.last_updated_at"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "comment",
          label: i18n.t("tab_container.keywords.comment"),
          sortable: true,
          sortDirection: "desc",
        },
      ],
    };
  }