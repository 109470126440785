import { getDashboardURL } from "../../utils/Utilities";
import { isEmpty } from "lodash";
import axios from "axios";

export default {
  state: {
    status: "",
    expiryError: false,
    token: localStorage.getItem("token") || "",
    edit: 0,
    first_name: "",
    last_name: "",
    notification: false,
    notificationMessage: "",
    email: "",
    lux_status: false,
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
  },
  mutations: {
    auth_request(state, data) {
      state.status = "loading";
      state.email = data.email;
      state.password = data.password;
    },
    auth_success(state, res_data) {
      const { data } = res_data;
      state.expiryError = false;
      state.status = "success";
      state.token = data.access_token;
      if (typeof data.scope !== "undefined" && !isEmpty(data.scope)) {
        state.edit = data.scope.insights.edit;
      }
      state.first_name = data.user_info.first_name;
      state.last_name = data.user_info.last_name;
      if (data.user_info.group === "LUX") state.lux_status = true;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout_commit(state) {
      state.status = "";
      state.token = "";
    },
    updateExpiryError(state) {
      state.expiryError = true;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request", user);
        axios({
          url: getDashboardURL() + "/api/auth/login",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.access_token;
            localStorage.setItem("token", token);
            localStorage.setItem("status", "loggedIn");
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", resp);
            resolve(resp);
          })
          .catch((err) => {
            console.log("commiting auth error");
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout_commit");
        localStorage.removeItem("token");
        localStorage.removeItem("status");
        localStorage.removeItem("vuex");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    expiryError({ commit }) {
      commit("updateExpiryError");
    },
  },
};
