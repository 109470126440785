<template>
  <div class="bg-w cards">
    <div class="m-2" v-if="can_user_edit_page">
      <b-button @click="new_task_modal()">
        <font-awesome-icon icon="plus-square" class="add-task" title="Create new task" />
        <span>Create New Task</span>
      </b-button>
      <b-button @click="open_edit_modal()" class="float-right" title="Edit">
        <font-awesome-icon icon="edit" class="sm-icon" />
      </b-button>
      <b-button @click="delete_task()" class="float-right" title="Delete">
        <font-awesome-icon icon="trash" class="sm-icon" />
      </b-button>
    </div>

    <div class="table-container" v-if="task_data.length">
      <!-- table  -->
      <b-table
        ref="table"
        id="task-table"
        sticky-header="700px"
        hover
        responsive
        :items="final_data"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        selectable
        select-mode="single"
        bordered
        outlined
        small
        @row-selected="onRowSelected"
        no-footer-sorting
        :filter="filter"
        :filter-function="filter_function"
        :filter-included-fields="['status']"
        style="border-bottom: 1px solid #dee2e6!important;"
      >
        <template slot="head(status_shown)" slot-scope="data">
          <span class="header-min-width">{{ data.label }}</span>
          <b-form-select
            style="height: 26px; padding: 0 1.25rem 0 .5rem;"
            v-model="filter"
            :options="[
              { value: 'Complete', text: $t('task_list.complete') },
              { value: 'Open', text: $t('task_list.open') },
              {
                value: 'In Progress',
                text: $t('task_list.in_progress'),
              },
              { value: 'Incomplete', text: $t('task_list.filter.incomplete') },
            ]"
          >
            <template v-slot:first>
              <option value>--{{ $t("task_list.filter.all") }}--</option>
            </template>
          </b-form-select>
        </template>
        <template slot="head()" slot-scope="data">
          <span class="header-min-width">{{ data.label }}</span>
          <div class="spacer"></div>
        </template>
        <template slot="head(comments)" slot-scope="data">
          <span style="min-width: 400px">{{ data.label }}</span>
          <div class="spacer"></div>
        </template>
        <template slot="head(task)" slot-scope="data">
          <span style="min-width: 150px">{{ data.label }}</span>
          <div class="spacer"></div>
        </template>
        <template class="header-min-width" v-slot:cell(index)="data">{{ data.index + 1 }}.</template>
      </b-table>
      <!-- end table  -->

      <div class="m-3 row" style="justify-content: space-between">
        <!-- pagination  -->
        <div class="col-12 col-md-8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
        <div class="col-12 col-md-4">
          <b-form-group
            :label="$t('tab_container.keywords.per_page')"
            label-cols-sm="3"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0 justify-content-start justify-content-md-end"
            style="flex-grow: 0!important"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              style="width: 80px;"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>

    <div class="loading-container" v-if="!task_data.length && !api_data_call_finished">
      <b-spinner class="align-left"></b-spinner>
      <p>loading</p>
    </div>
    <!-- edit task modal  -->
    <TaskListModal @submit="submit_task" ref="edit_modal" :task_data="edit_task" :type="'edit'" />
    <!-- new task modal  -->
    <TaskListModal @submit="submit_task" ref="new_modal" :task_data="new_task" :type="'new'" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { getDashboardURL, axiosHeaders } from "../../../utils/Utilities";
import moment from "moment";
import Swal from "sweetalert2";
import i18n from "@/i18n";
import TaskListModal from "./taskListModal";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 20, 50, 100],
      sort: null,
      totalPages: 1,
      task_data: [],
      edit_task: {
        id: "",
        task_type: "",
        priority: "",
        task: "",
        reference: "",
        date_of_request: "",
        status: "",
        assigned_to: "",
        eta_for_resolution: "",
        comments: "",
        request_by: "",
        domain_type: "",
      },
      new_task: {
        task_type: "",
        priority: "",
        task: "",
        reference: "",
        date_of_request: moment().format("YYYY-MM-DD"),
        status: "",
        assigned_to: "",
        eta_for_resolution: "",
        comments: "",
        request_by: "",
        domain_type: "",
      },
      selectedRow: null,
      api_data_call_finished: false,
      filter: null,
    };
  },
  components: {
    TaskListModal,
  },
  created() {
    // fetch task data on component load 
    this.get_task_data_apiCall();
    this.new_task.domain_type = this.seosem;
  },
  computed: {
    ...mapState({
      SEMSEO: "seosem",
      selectedClientName: (state) => state.clientList.selectedClient.name,
      selectedClient_rev_leads: (state) => state.clientList.selectedClient.rev_lead,
      can_user_edit_page: (state) => state.auth.edit,
    }),
    fields() {
      return [
        { key: "index", label: "#" },
        {
          key: "task_type",
          sortable: true,
          label: i18n.t("task_list.type_of_task"),
        },
        {
          key: "request_by",
          sortable: true,
          label: i18n.t("task_list.requested_by"),
        },
        {
          key: "date_of_request",
          label: i18n.t("task_list.date_of_request"),
          sortable: true,
        },
        {
          key: "priority_shown",
          sortable: true,
          label: i18n.t("task_list.priority"),
        },
        { key: "task", sortable: true, label: i18n.t("task_list.task") },
        { key: "reference", label: i18n.t("task_list.reference_if_any") },
        {
          key: "status_shown",
          sortable: true,
          label: i18n.t("task_list.status"),
        },
        {
          key: "assigned_to",
          sortable: true,
          label: i18n.t("task_list.assigned_to"),
        },
        {
          key: "eta_for_resolution_shown",
          label: i18n.t("task_list.eta_for_resolution"),
        },
        {
          key: "updated_at",
          sortable: true,
          label: i18n.t("task_list.updated_at"),
        },
        { key: "comments", label: i18n.t("task_list.comments") },
      ];
    },
    final_data() {
      const data = this.task_data;
      // add color to priority and status columns and format dates
      data.map((x) => {
        if (x.priority == "High") {
          x.priority_shown = i18n.t("task_list.high");
          x._cellVariants = { priority_shown: "danger" };
        }
        if (x.priority == "Med") {
          x.priority_shown = i18n.t("task_list.medium");
          x._cellVariants = { priority_shown: "warning" };
        }
        if (x.priority == "Low") {
          x.priority_shown = i18n.t("task_list.low");
          x._cellVariants = { priority_shown: "info" };
        }
        if (x.status == "Open") {
          x.status_shown = i18n.t("task_list.open");
          x._cellVariants = { ...x._cellVariants, status_shown: "warning" };
        }
        if (x.status == "Complete") {
          x.status_shown = i18n.t("task_list.complete");
          x._cellVariants = { ...x._cellVariants, status_shown: "success" };
        }
        if (x.status == "In Progress") {
          x.status_shown = i18n.t("task_list.in_progress");
          x._cellVariants = { ...x._cellVariants, status_shown: "info" };
        }
        x.date_of_request = moment(x.date_of_request).format("MMM DD YYYY");
        x.updated_at = moment(x.updated_at).format("MMM DD YYYY");
        // if no eta for resolution date selected show 'TBC' 
        x.eta_for_resolution_shown = x.eta_for_resolution
          ? moment(x.eta_for_resolution).format("MMM DD YYYY")
          : i18n.t("task_list.tbc");
      });
      return data;
    },
    seosem() {
      var state = this.$store.state.seosem ? "seo" : "sem";
      return state;
    },
    Revenue_Leads() {
      return this.selectedClient_rev_leads == null ? "Revenue" : this.selectedClient_rev_leads;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    selectedClientName() {
      this.get_task_data_apiCall();
    },
    locale() {
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }
    },
  },
  methods: {
    filter_function(init, filter_val) {
      // if selecting 'incomplete' show both 'open' and 'in progress' tasks 
      if (filter_val == "Incomplete") {
        return init.status == "Open" || init.status == "In Progress" ? true : false;
      }
      return init.status == filter_val ? true : false;
    },
    // update selected task data when clicking on task 
    onRowSelected(item) {
      if (item.length) {
        const {
          status,
          priority,
          task,
          task_type,
          request_by,
          reference,
          assigned_to,
          eta_for_resolution,
          comments,
          date_of_request,
          id,
        } = item[0];
        this.selectedRow = item[0];
        this.edit_task.status = status;
        this.edit_task.task = task;
        this.edit_task.priority = priority;
        this.edit_task.task_type = task_type;
        this.edit_task.request_by = request_by;
        this.edit_task.reference = reference;
        this.edit_task.date_of_request = moment(date_of_request).format("YYYY-MM-DD");
        this.edit_task.assigned_to = assigned_to;
        this.edit_task.comments = comments;
        // if the date is 'tbc' convert to empty string 
        if (eta_for_resolution == i18n.t("task_list.tbc")) {
          this.edit_task.eta_for_resolution = "";
        } else {
          this.edit_task.eta_for_resolution = moment(eta_for_resolution).format("YYYY-MM-DD");
        }
        this.edit_task.id = id;
        this.edit_task.domain_type = this.seosem;
      }
      if (!item.length) {
        this.selectedRow = null;
      }
    },
    open_edit_modal() {
      this.$refs["edit_modal"].show();
    },
    new_task_modal() {
      this.$refs["new_modal"].show();
    },
    // called by the task list modal 
    submit_task(data) {
      axios
        .post(`${getDashboardURL()}/api/task-list/${this.selectedClientName}`, data, axiosHeaders())
        .then(() => {
          // fetch new data 
          this.get_task_data_apiCall();
          // hide modal 
          this.$refs["new_modal"].hide();
          this.$refs["edit_modal"].hide();
        })
        .catch((err) => {
          Swal.fire({
            type: "error",
            title: "Failed to submit task",
            text: err.message,
          });
        });
    },
    get_task_data_apiCall() {
      this.api_data_call_finished = false;
      axios
        .get(`${getDashboardURL()}/api/get-task-list/${this.selectedClientName}/${this.seosem}`, axiosHeaders())
        .then((res) => {
          this.api_data_call_finished = true;
          const data = res.data.data;
          this.task_data = [...data];
          this.totalPages = this.task_data.length;
        });
    },
    delete_task() {
      if (this.selectedRow) {
        const index = { id: this.selectedRow.id };
        Swal.fire({
          title: `Are you sure you want to delete task "${this.selectedRow.task}"?`,
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ffc001",
          cancelButtonColor: "#a5a5a5",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            axios
              .post(`${getDashboardURL()}/api/task-list/${this.selectedClientName}/delete`, index, axiosHeaders())
              .then(() => {
                this.get_task_data_apiCall();
              })
              .catch((err) => {
                Swal.fire({
                  type: "error",
                  title: "Failed to delete task",
                  text: err.message,
                });
              });
          }
        });
      }
      if (!this.selectedRow) {
        Swal.fire({
          type: "error",
          title: "No row selected to delete",
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-input {
  margin: 10px 0;
}
.header-min-width {
  min-width: 100px;
}
.b-table-sticky-header {
  min-height: 300px;
}
.add-task {
  font-size: 18px;
  margin-right: 10px;
  color: white;
}
.sm-icon {
  color: white;
  font-size: 18px;
}
.spacer {
  height: 27px;
}
svg {
  color: #6c757d;
}
.loading-container {
  text-align: center;
  padding: 20px;
}
.action-button {
  padding: 2px 6px;
}
.action-button:first-child {
  margin-right: 4px;
}
.float-right {
  float: right;
  margin-left: 5px;
}
</style>
