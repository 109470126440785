<template>
  <div id="chartWrapper" class="text-center" style="width:100%;">
    <transition name="fade">
      <div>
        <div class="row bg-w cards chart-row">
          <!-- revenue/leads vs sessions chart container  -->
          <div :class="['chart-container', 'chart-sm', 'fullChart']" id="sessRev">
            <div class="px-4 py-3 forecast-toggle-container">
              <div>
                <h3>{{ $t("chart_data.sessions") }} / {{ revenue_leads_getter }}</h3>
                <h6>{{ $t("chart_data.yearly_performance_chart") }}</h6>
              </div>
              <!-- main chart toggle to show forecast if available  -->
              <div>
                <div @click="toggle_forecast" v-if="forecast_available">
                  <font-awesome-icon
                    class="chart-icon"
                    v-if="!forecast_showing"
                    icon="chart-line"
                    title="Show Forecast"
                  />
                  <font-awesome-icon
                    class="chart-icon"
                    v-if="forecast_showing"
                    icon="window-close"
                    title="Close Forecast"
                  />
                </div>
              </div>
            </div>
            <div
              class="chart-wrapper session_revenue"
              style="position: relative; width: 100%;"
              v-if="revenue_leads_data"
            >
            <!-- main revenue/leads vs sessions chart  -->
              <barChart
                :forecastData="forecast_data"
                :chart-data="revenue_leads_data"
                :ranking_chart="false"
                :labels="[revenue_leads_getter, $t('chart_data.sessions')]"
                :dataLabel="false"
                :locale="locale"
                :revenue="revenue_leads_boolean_getter"
                :forecastLabels="forecast_months"
                :rendered="forecast_showing"
              />
            </div>
          </div>
          <!-- ranking summary chart container  -->
          <div class="chart-container chart-sm" id="rank" v-if="ranking_summary_chart_data_has_value">
            <div class="px-4 py-3">
              <h3>{{ $t("chart_data.ranking_summary") }}</h3>
              <h6>{{ $t("chart_data.yearly_performance_chart") }}</h6>
            </div>
            <div
              class="chart-wrapper ranking_summary"
              style="position: relative; width: 100%;"
              v-if="ranking_chart_data_fetched"
            >
              <!-- ranking summary line chart  -->
              <lineChart
                :chart-data="ranking_summary_chart_data"
                :labels="[$t('chart_data.ranked1'), $t('chart_data.ranked10')]"
                :left_axis="$t('chart_data.rank')"
                :dataLabel="true"
                :locale="locale"
                :revenue="revenue_leads_boolean_getter"
                :mixed="false"
              />
            </div>
          </div>
          <!-- active keyword vs indexed pages chart container  -->
          <div class="chart-container chart-sm " id="activeIndex" v-if="monthly_aggregates_has_value">
            <div class="px-4 py-3">
              <h3>{{ $t("chart_data.active_keywords_title") }}</h3>
              <h6>{{ $t("chart_data.yearly_performance_chart") }}</h6>
            </div>
            <div
              class="chart-wrapper  keyword_index"
              style="position: relative; width: 100%;"
              v-if="ranking_chart_data_fetched"
            >
              <!-- active keywords vs indexed pages chart  -->
              <lineChart
                :chart-data="monthly_aggregates"
                :labels="[$t('chart_data.active_keywords'), $t('chart_data.total_indexed')]"
                :left_axis="'Keywords'"
                :dataLabel="true"
                :locale="locale"
                :revenue="revenue_leads_boolean_getter"
                :mixed="true"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <p style="display:none;position:relative;text-align:right;top:-20px;right:15px;margin:0;padding:0;">
      {{ Last_Updated_Data }}
    </p>
  </div>
</template>

<script>
import barChart from "../ui/charts/bar-chart";
import lineChart from "../ui/charts/line-chart";
import * as moment from "moment";
import { mapState } from "vuex";
import { mapGetters } from "vuex";

// @vuese 
// @group Rows
// Container for all the charts
export default {
  name: "ChartRow",
  data() {
    return {
      forecast_showing: false,
    };
  },
  components: {
    lineChart,
    barChart,
  },
  computed: {
    ...mapState({
      chart_data: (state) => state.clientData.chartData,
      ranking_summary_data: (state) => state.clientData.rankingChartData,
      ranking_chart_data_fetched: (state) => state.clientData.ranking_chart_data_fetched,
    }),
    ...mapGetters(["revenue_leads_getter", "revenue_leads_boolean_getter", "launchDate"]),
    // if user toggles forecast data return forecast data else normal chart data
    revenue_leads_data() {
      if (this.forecast_showing) {
        return this.forecast_revenue_leads_data;
      }
      return this.chart_data.data;
    }, 
    // if forecast format the chart data so that the launch date becomes the start of the chart
    // i.e [0,0,0,0,4,5,6,7,8] => [4,5,6,7,8,0,0,0,0] 
    forecast_revenue_leads_data() {
      var newData = { ...this.chart_data.data };
      if (this.monthsSincelaunch && this.monthsSincelaunch <= 12) {
        newData.sessions = this.sliceFill(newData.sessions);
        newData.revenue = this.sliceFill(newData.revenue);
        newData.conversions = this.sliceFill(newData.conversions);
      }
      // if launch date is in same month we can just reverse the array 
      if (this.monthsSincelaunch === 0) {
        newData.sessions.reverse();
        newData.revenue.reverse();
        newData.conversions.reverse();
      }
      return newData;
    },
    // check if forecast data is available and also a launch date.
    // We need both for a forecast
    forecast_available() {
      if (this.chart_data.forecast && Array.isArray(this.chart_data.forecast) && this.launchDate) {
        return true;
      }
      return false;
    },
    // data for the forecast line 
    forecast_data() {
      if (this.forecast_showing) {
        if (this.forecast_available) {
          var forecastData = { ...this.chart_data.forecast };
          var forecast_high = Object.keys(forecastData).map(function(key) {
            return forecastData[key]["forecast_high"];
          });
          var forecast_low = Object.keys(forecastData).map(function(key) {
            return forecastData[key]["forecast_low"];
          });
          if (forecast_high.length < 13) forecast_high.unshift(0);
          if (forecast_low.length < 13) forecast_low.unshift(0);
          return { forecast_high: forecast_high, forecast_low: forecast_low };
        }
      }
      return false;
    },
    // calculate months to show for forecast 
    forecast_months() {
      var months = [];
      var oneYearTime = moment(this.launchDate)
        .add("Y", 1)
        .startOf("month");
      var startDateNormalized = moment(this.launchDate)
        .clone()
        .startOf("month");
      while (startDateNormalized.isSameOrBefore(oneYearTime)) {
        months.push(startDateNormalized.format("MMM YYYY"));
        startDateNormalized.add("M", 1);
      }
      return months;
    },
    // date for ranking summary chart
    ranking_summary_chart_data() {
      return [this.ranking_summary_data.ranking_position_1, this.ranking_summary_data.ranking_position_1_to_10];
    },
    // data for active keywords / indexed pages chart  
    monthly_aggregates() {
      const data = this.ranking_summary_data.monthly_aggregates;
      if (data) {
        const active_keywords = data.map((x) => x.active_keywords);
        const total_indexed = data.map((x) => x.total_indexed);
        return [active_keywords, total_indexed];
      }
      return false;
    },
    // make sure that data exists and isnt all zeros 
    monthly_aggregates_has_value() {
      if (this.monthly_aggregates.length) {
        return this.check_array_not_empty(this.monthly_aggregates);
      }
      return false;
    },
    // make sure that data exists and isnt all zeros 
    ranking_summary_chart_data_has_value() {
      if (this.ranking_summary_chart_data.length) {
        return this.check_array_not_empty(this.ranking_summary_chart_data);
      }
      return false;
    },
    // show hidden div with chart update date 
    Last_Updated_Data() {
      if (this.chart_data.data.last_updated) {
        return "Last Updated: " + this.chart_data.data.last_updated;
      }
      return "";
    },
    locale() {
      return this.$i18n.locale;
    },
    // calculate how many months have passed since launch date 
    // used to determine when forecast chart data should start 
    monthsSincelaunch() {
      if (this.launchDate) {
        const launch = moment(this.launchDate).startOf("month");
        const currentDate = moment().startOf("month");
        return Math.round(currentDate.diff(launch, "months", true));
      }
      return false;
    },
  },
  methods: {
    // will take the chart data and map it so the launch date month is at the start of the array 
    sliceFill(arr) {
      var x = arr.slice(13 - this.monthsSincelaunch);
      x.push(...Array(13 - this.monthsSincelaunch).fill(0));
      return x;
    },
    check_array_not_empty(arr) {
      return arr.some((x) => {
        if (x != undefined) {
          return x.some((y) => y >= 1);
        }
      });
    },
    toggle_forecast() {
      this.forecast_showing = !this.forecast_showing;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-icon {
  font-size: 2em;
  cursor: pointer;
  color: #5f5f5f;
}
.chart-icon:hover {
  color: #212529;
}
.hide {
  display: none;
  height: 50vh;
}
.forecast-toggle-container {
  display: flex;
  justify-content: space-between;
}
h3 {
  margin-bottom: 0;
  text-transform: uppercase;
}
h6 {
  line-height: 1;
  color: #c4c6c5;
  text-align: left;
}
.toggle-chart {
  display: none;
}
.chart-container {
  text-align: left;
  max-width: 100%;
}

.chart-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.chart-row .chart-container {
  flex: 1 0 50%;
  width: 50%;
}
.chart-row .chart-container.fullChart {
  flex: 0 1 100%;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .chart-row .chart-container {
    flex: 1 0 100%;
    width: 100%;
  }

  .toggle-chart {
    display: block;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .chart-container h3 {
    font-size: 1.25rem;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}
</style>
