import { getDashboardURL, errorHandlingSmall, axiosHeaders } from "../../utils/Utilities";
import { compact } from "lodash";
import axios from "axios";

export default {
  state: {
    kw_awaiting_approval: [],
    kw_awaiting_approval_fetched: false,
    kw_awaiting_approval_page: false,
  },
  getters: {},
  mutations: {
    keyword_awaiting_approval_update_data(state, data) {
      state.kw_awaiting_approval = data.response.data;
      state.kw_awaiting_approval_fetched = true;
    },
    approval_page(state, data) {
      state.kw_awaiting_approval_page = data;
    },
    keywordApprovalPageCommit(state, data) {
      state.kw_awaiting_approval_page = data === true ? true : false
    },
  },
  actions: {
    keyword_approval_API_call({ commit }, data) {
      return new Promise((resolve, reject) => {
        const { url, sort, page, per_page, order, filter_data } = data;
        const urlSplit = compact([getDashboardURL(), url]);
        axios
          .request({
            method: "GET",
            url: urlSplit.join("/"),
            headers: axiosHeaders().headers,
            params: {
              sort,
              page,
              per_page,
              order,
              ...filter_data,
            },
          })
          .then((response) => {
            commit("keyword_awaiting_approval_update_data", {
              response: response,
            });
            resolve();
          })
          .catch((err) => {
            errorHandlingSmall(err);
            // if call fails return empty object 
            commit("keyword_awaiting_approval_update_data", {
              response: {},
            });
            reject();
          });
      });
    },
  },
};
