import router from "../router/router";
import store from "../store/store";
import * as moment from "moment";
import * as moment_timezone from "moment-timezone";
import i18n from "@/i18n";
import Swal from "sweetalert2";
import currency from "./currency.json";
export function currencyConversion(currencyVal) {
  return currency.hasOwnProperty(currencyVal) ? currency[currencyVal].symbol : "$";
}
export function launchDateFormat(x) {
  return moment(x).format("MMM D, YYYY");
}
export function insight_date_formatter(x) {
  let date = moment_timezone(x);
  return date.tz("Australia/Sydney").format("MMM D, YYYY HH:mm z");
}

export function calculateSubscription(x) {
  let lang = localStorage.getItem("language");
  let d = moment(x).format("YYYY-MM-D");
  let year = moment(x).format("YYYY");
  let month = moment(x).format("M");
  let day = moment(x).format("D");
  let subscriptionEndDate = d;
  let ninetyFromNow = moment()
    .add(90, "days")
    .format("YYYY-MM-D");
  if (subscriptionEndDate < ninetyFromNow) {
    if (lang == "ja") {
      return `年間サブスクリプションの更新日は${year}年${month}月${day}日です。`;
    } else {
      return `Your annual subscription is due for renewal on ${d}`;
    }
  }
}
export function yAxisOptions(id, pos, label, windowFunc, gridLineLabel, tick_display, maxvalue, graph) {
  return {
    id: id,
    type: "linear",
    position: pos,
    gridLines: {
      display: gridLineLabel,
    },
    scaleLabel: {
      display: windowFunc,
      labelString: label,
      fontColor: "#c4c6c5",
      fontFamily: "dosis",
      fontSize: 14,
    },
    ticks: {
      display: tick_display,
      fontColor: "#c4c6c5",
      fontFamily: "dosis",
      beginAtZero: true,
      min: 0,
      fontSize: 14,
      suggestedMax: graph ? maxvalue * 1.2 : false,
      callback: function (value) {
        return graph
          ? value > 999
            ? (value / 1000).toFixed(1) + "k"
            : value
          : value > 999
            ? (value / 1000).toFixed(0) + "k"
            : value;
      },
    },
  };
}

export function apiError(response) {
  Swal.fire({
    title: "API Error",
    text: response + " (Please inform tech)",
    type: "warning",
    showCancelButton: false,
    confirmButtonColor: "#ffc001",
  })
}

// export function  postMonthlyInsight(data) {
//   var params = {
//     new_item: data,
//   };
//   var sendEmail = data.sendEmail;
//   let client = this.state.selectedClientName;
//   axios
//     .post(`${getDashboardURL()}/api/monthly-insights/` + client + ``, params.new_item.editor_data, axiosHeaders())
//     .then(function(response) {
//       let id = response.data.data.id;
//       var emailUpdate = response.data.data.id;
//       let dateTime;
//       if (sendEmail) {
//         let emailData = {
//           id: emailUpdate,
//         };
//         axios
//           .post(`${getDashboardURL()}/api/monthly-insights/` + client + `/send`, emailData, axiosHeaders())
//           .then(function(response) {
//             dateTime = response.data.data.sent_at.date;
//           })
//           .catch(function(res) {
//             console.log(res);
//           });
//       }

//       commit("addInsightItem", {
//         editor_data: {
//           new_item: params.new_item.editor_data,
//           new_item_id: id,
//           new_item_date: dateTime,
//         },
//       });
//     })
//     .catch(function() {});
// }

export function getDashboardURL() {
    return `//${process.env.VUE_APP_dashboardApiHostName}`;
}

export function prevMonth() {
  return moment()
    .subtract(1, "month")
    .format("YYYY-MM");
}
export function firstMonth() {
  return moment()
    .subtract(13, "month")
    .format("YYYY-MM");
}

// date2 = (x) =>  date = new Date(new Date().setMonth(new Date().getMonth() - x - 1))
// var months = _.range(0, 13).map((i, y) => this.months[date2(y).getMonth()]+ ' ' + date2(y).getFullYear())

export function prevMonths() {
  var months = [
    i18n.t("months.jan"),
    i18n.t("months.feb"),
    i18n.t("months.mar"),
    i18n.t("months.apr"),
    i18n.t("months.may"),
    i18n.t("months.jun"),
    i18n.t("months.jul"),
    i18n.t("months.aug"),
    i18n.t("months.sep"),
    i18n.t("months.oct"),
    i18n.t("months.nov"),
    i18n.t("months.dec"),
  ];
  let date = moment();
  date = moment(date.subtract(1, "months"));
  let orderedMonths = [];
  for (var i = 0; i <= 12; i++) {
    orderedMonths.push(months[date.month()] + " " + date.year());
    +date.subtract(1, "months");
  }
  return orderedMonths;
}

export function insightPrevMonths(currentDatesLength) {
  let currentDatesUsed = currentDatesLength;
  // var months = [i18n.t('months.jan'), i18n.t('months.feb'), i18n.t('months.mar'), i18n.t('months.apr'), i18n.t('months.may'), i18n.t('months.jun'), i18n.t('months.jul'), i18n.t('months.aug'), i18n.t('months.sep'), i18n.t('months.oct'), i18n.t('months.nov'), i18n.t('months.dec')]
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let date = moment();
  date = moment(date.subtract(1, "months"));
  let orderedMonths = [];
  for (var i = 0; i <= 12; i++) {
    orderedMonths.push(months[date.month()] + " " + date.year());
    +date.subtract(1, "months");
  }
  if (currentDatesUsed.length != 0) {
    let compareCurrent = currentDatesUsed;
    compareCurrent.map(function (part, index, theArray) {
      theArray[index] = moment(theArray[index]).format("MMM YYYY");
    });

    let compareMonths = orderedMonths;
    compareMonths = compareMonths.map(function (value) {
      if (!compareCurrent.includes(value)) {
        return value;
      }
    });
    compareMonths = compareMonths.filter(function (value) {
      if (value != undefined || value != "undefined") {
        return value;
      }
    });
    return compareMonths;
  } else {
    return orderedMonths;
  }
}

export function errorHandling(err) {
  console.log(err);
  //   if (err.response && err.response.status === 401 && err.response.statusText != 'Unauthorized') {
  //     localStorage.clear()
  //     commit('logout')
  //     store.dispatch('updateError', err.response)
  //     router.push('/login')
  //   }
  //   else
  if (
    err.response &&
    err.response.status === 401 &&
    err.response.data.message == "User does not have access to the client"
  ) {
    store.dispatch("updateError", err.response);
    router.push("/Unauthorised");
  } else if (err.response && err.response.status === 401 && err.response.data.message == "Token has expired") {
    store.dispatch("expiryError");
    console.log("expired");
    store.dispatch("logout");
    router.push("/login");
  } else if (err.response && err.response.status === 401) {
    store.dispatch("logout");
    router.push("/login");
  } else {
    // console.log(err.response)
    // store.dispatch('updateError', err.response)
    // router.push('/Unauthorised')
  }
}
export function errorHandlingSmall(err) {
  if (err.response && err.response.status === 401 && err.response.data.message == "Token has expired") {
    store.dispatch("expiryError");
    console.log("expired");
    store.dispatch("logout");
    router.push("/login");
  }
}

export function axiosHeaders() {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    timeout: 2000 * 10,
  };
}

export function convertDateInsight(updateDateData) {
  let newDateArry = [];
  Object.keys(updateDateData).forEach((key) => {
    var months = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];
    var current_datetime = new Date(updateDateData[key].insight_date);
    var formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getFullYear();
    updateDateData[key].insight_new_date = formatted_date;
    newDateArry.push(formatted_date);
    // return [newDateArry]
  });
}
export function addEditable(addEditableData) {
  let newDateArry = [];
  Object.keys(addEditableData).forEach((key) => {
    var formatted_dat = 0;
    addEditableData[key].insight_editable = formatted_dat;
    newDateArry.push(formatted_dat);
    // return [newDateArry]
  });
}

export function clearFilters() {
  return {
    keyword: [],
    category_name: [],
    google_monthly_search_volume: [],
    fkms: [],
    sessions: [],
    sessions_90d: [],
    transactions: [],
    revenue: [],
    keywordRank: [],
    seo_rank_change_monthly: [],
    potential_traffic: [],
    seo_rank: [],
    status: [],
    updated_at: [],
    comment: [],
    url: [],
    activated_days: [],
    bounce_rate30d: [],
  };
}

export function initialSortState() {
  if (store.state.keywordApproval.kw_awaiting_approval_page) {
    return {
      keyword: false,
      url: false,
      category_name: false,
      potential_traffic: true,
      fkms: true,
      seo_rank: true,
      status: true,
      updated_by: true,
      comment: true,
      google_monthly_search_volume: true,
    };
  } else {
    return {
      keyword: false,
      category_name: false,
      fkms: true,
      sessions: true,
      sessions_90d: true,
      transactions: true,
      revenue: true,
      keywordRank: true,
      rankChange: true,
      google_monthly_search_volume: true,
      activated_days: true,
      bounce_rate30d: true,
    };
  }
}

export function getMaintenanceStatus() {
  return process.env.VUE_APP_maintenance == true ? true : false;
}
