import { getDashboardURL, axiosHeaders, prevMonth } from "../../utils/Utilities";
import axios from "axios";
import moment from "moment";

export default {
  state: {
    editor_data: {
      new_item: {},
    },
    test: false,
  },
  getters: {
    editableFiltered(state) {
      if (state.test == true) {
        return state.editor_data.new_item.filter((x) => x);
      }
    },
    availableMonths: function(state) {
        let months = [
          "JANUARY",
          "FEBRUARY",
          "MARCH",
          "APRIL",
          "MAY",
          "JUNE",
          "JULY",
          "AUGUST",
          "SEPTEMBER",
          "OCTOBER",
          "NOVEMBER",
          "DECEMBER",
        ];
        let date = moment();
        date = moment(date.subtract(1, "months"));
        let currentDates = [];
        // AUGUST 2020
        let monthlyItems = state.editor_data.new_item;
        Object.keys(monthlyItems).forEach((key) => {
          currentDates.push(monthlyItems[key].insight_new_date);
        });
        let orderedMonths = [];
        for (var i = 0; i <= 11; i++) {
          orderedMonths.push(months[date.month()] + " " + date.year());
          +date.subtract(1, "months");
        }
        let dropDownDates = [];
        dropDownDates = orderedMonths.filter(function(val) {
          return !currentDates.includes(val);
        });

        dropDownDates.forEach(function(element, i) {
          element = element.split(" ");
          element[0] = element[0].slice(0, 3).toLocaleLowerCase();
          element[0] = element[0].slice(0, 3).toLocaleLowerCase();
          element[0] = element[0].charAt(0).toUpperCase() + element[0].slice(1);
          dropDownDates[i] = element.join(" ");
        });
        return dropDownDates;
    },
  },
  mutations: {
    updateMonthlyInsights(state, data) {
      state.editor_data.new_item = data.editor_data.new_item;
      state.test = true;
    },
    deleteinsightItem(state, id) {
      var params = {
        id: id,
      };
      state.editor_data.new_item.splice(params.id, 1);
    },
    addInsightItem(state, data) {
      state.editor_data.new_item.push({
        insights: data.editor_data.new_item.insights,
        insight_date: data.editor_data.new_item.insight_date,
        id: data.editor_data.new_item_id,
        insight_new_date: data.editor_data.new_item.insight_new_date,
        first_name: state.first_name,
        last_name: state.last_name,
        insight_time: data.editor_data.new_item.new_item_date,
      });
      setTimeout(function() {
        let dataCollection = state.editor_data.new_item;
        sortByMonth(dataCollection);
        function sortByMonth(arr) {
          let months = [
            "DECEMBER",
            "NOVEMBER",
            "OCTOBER",
            "SEPTEMBER",
            "AUGUST",
            "JULY",
            "JUNE",
            "MAY",
            "APRIL",
            "MARCH",
            "FEBRUARY",
            "JANUARY",
          ];

          Object.keys(months).forEach((key) => {
            var d = new Date();
            var n = d.getFullYear();
            months[key] = months[key] + " " + n;
          });
          Object.keys(months).forEach((key) => {
            let month = months[key]
              .split(" ")
              .slice(0, 1)
              .join(" ");
            var d = new Date();
            var n = d.getFullYear() - 1;
            months.push(month + " " + n);
          });

          arr.sort(function(a, b) {
            months.indexOf(a.insight_new_date) - months.indexOf(b.insight_new_date);

            return months.indexOf(a.insight_new_date) - months.indexOf(b.insight_new_date);
          });
        }
      }, 40);
    },
  },
  actions: {
    fetchMonthlytInsight({ commit }) {
      var todaysDate = new Date();
      function convertDate(date) {
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth() + 1).toString();
        var mmChars = mm.split("");
        return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]);
      }
      let today = convertDate(todaysDate);
      var d = new Date();
      d.setMonth(d.getMonth() - 12);

      var date1 = d.toLocaleDateString("en-US");
      var reverse = date1.split("/");
      reverse[0].length < 2 ? (reverse[0] = "0" + reverse[0]) : false;
      reverse = reverse.reverse().join("-");
      var mySubString = reverse.substring(4, 7);
      var past = reverse.replace(mySubString, "");
      let client = this.state.clientList.selectedClient.name;
      past = moment()
        .subtract(12, "months")
        .format("YYYY-MM");
      today = moment(prevMonth()).format("YYYY-MM");

      axios
        .get(`${getDashboardURL()}/api/get-monthly-insights/` + client + `/` + past + `/` + today + ``, axiosHeaders())
        .then(function(response) {
          commit("updateMonthlyInsights", {
            editor_data: {
              new_item: response.data.data,
            },
          });
        })
        .catch(function() {});
    },
    postMonthlyInsight({ commit }, data) {
      var params = {
        new_item: data,
      };
      var sendEmail = data.sendEmail;
      let client = this.state.clientList.selectedClient.name;
      axios
        .post(`${getDashboardURL()}/api/monthly-insights/` + client + ``, params.new_item.editor_data, axiosHeaders())
        .then(function(response) {
          let id = response.data.data.id;
          var emailUpdate = response.data.data.id;

          let dateTime;
          if (sendEmail) {
            let emailData = {
              id: emailUpdate,
            };

            axios
              .post(`${getDashboardURL()}/api/monthly-insights/` + client + `/send`, emailData, axiosHeaders())
              .then(function(response) {
                dateTime = response.data.data.sent_at.date;
              })
              .catch(function(res) {
                console.log(res);
              });
          }
          commit("addInsightItem", {
            editor_data: {
              new_item: params.new_item.editor_data,
              new_item_id: id,
              new_item_date: dateTime,
            },
          });
        })
        .catch(function() {});
    },
    deleteMonthlyInsight({ commit }, data) {
      var paramsIndex = {
        id: data.index,
      };
      var params = {
        id: data.id,
      };
      let client = this.state.clientList.selectedClient.name;

      axios
        .post(`${getDashboardURL()}/api/monthly-insights/` + client + `/delete`, params, axiosHeaders())
        .then(function() {
          let id = paramsIndex.id;
          commit("deleteinsightItem", id);
        })
        .catch(function() {});
    },
  },
};
