<template>
  <b-form-input size="sm" v-model="filters[data.field.key]" @keypress="number_check ? isNumber($event) : false" />
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";
import filterMix from "../../mixins/filterMix.js";

export default {
  props: ["data", "number_check"],
  mixins: [filterMix],
  data() {
    return {
      filters: {
        keyword: "",
        category_name: "",
        google_monthly_search_volume: "",
        fkms: "",
        sessions: "",
        sessions_90d: "",
        transactions: "",
        revenue: "",
        keywordRank: "",
        seo_rank_change_monthly: "",
        potential_traffic: "",
        seo_rank: "",
        status: "",
        updated_at: "",
        comment: "",
        url: "",
        activated_days: "",
        bounce_rate30d: "",
      },
      client: "",
    };
  },
  beforeUpdate() {
    if (this.client && this.client != this.selectedClientName) {
      this.filters = {
        keyword: "",
        category_name: "",
        google_monthly_search_volume: "",
        fkms: "",
        sessions: "",
        sessions_90d: "",
        transactions: "",
        revenue: "",
        keywordRank: "",
        seo_rank_change_monthly: "",
        potential_traffic: "",
        seo_rank: "",
        status: "",
        updated_at: "",
        comment: "",
        url: "",
        activated_days: "",
        bounce_rate30d: "",
      };
    }
    this.client = this.selectedClientName;
  },
  mounted() {
    var self = this;
    this.$watchAll(
      [
        "filters.fkms",
        "filters.sessions",
        "filters.sessions_90d",
        "filters.transactions",
        "filters.google_monthly_search_volume",
        "filters.revenue",
        "filters.keywordRank",
        "filters.seo_rank_change_monthly",
        "filters.potential_traffic",
        "filters.seo_rank",
        "filters.activated_days",
        "filters.bounce_rate30d",
      ],
      debounce(function(field, value) {
        field = field.split(".")[1];
        if (value && field) {
          value = self.split_numeric_into_array(value, field);
          if (
            self.convert_nonEmpty_filter_value_toString[`filter[${field}][value]`] ||
            self.convert_nonEmpty_filter_value_toString[`filter[${field}][value]`] === 0
          ) {
            self.$emit("filter_fetch", self.convert_nonEmpty_filter_value_toString);
          }
        }
        if (!value) {
          self.filter_store[field] = [];
          self.$emit("filter_fetch", self.convert_nonEmpty_filter_value_toString);
        }
      }, 300)
    );
    this.$watchAll(
      [
        "filters.keyword",
        "filters.category_name",
        "filters.status",
        "filters.updated_at",
        "filters.comment",
        "filters.url",
      ],
      debounce(function(field, value) {
        field = field.split(".")[1];
        if (value == "null") {
          self.filter_store[field] = ["=", value];
        } else {
          self.filter_store[field] = ["<>", value];
        }
        self.$emit("filter_fetch", self.convert_nonEmpty_filter_value_toString);
      }, 300)
    );
  },
  computed: {
    ...mapState({
      filter_store: (state) => state.filters.filter_store,
      selectedClientName: (state) => state.clientList.selectedClient.name,
    }),
  },
  methods: {
    split_numeric_into_array(value, field) {
      if (value === "") {
        this.filter_store[field] = [];
        return value;
      }
      if (value == "null") {
        this.filter_store[field] = ["=", "null"];
        return value;
      }
      if (value.indexOf("_") > 0) {
        // handle dash
        let begin = parseFloat(value.split("_")[0]);
        let end = parseFloat(value.split("_")[1]);
        this.filter_store[field] = ["range", [begin, end]];
        return begin && end ? ["range", begin, end] : null;
      } else if (value.indexOf(">=") > -1) {
        // handle greater then or equal
        let begin = parseFloat(value.split(">=")[1]);
        this.filter_store[field] = [">=", begin];
        return [">=", begin];
      } else if (value.indexOf("<=") > -1) {
        // handle less then or equal
        let end = parseFloat(value.split("<=")[1]);
        this.filter_store[field] = ["<=", end];
        return ["<=", end];
      } else if (value.indexOf(">") > -1) {
        // handle greater then
        let begin = parseFloat(value.split(">")[1]);
        this.filter_store[field] = [">", begin];
        return [">", begin];
      } else if (value.indexOf("<") > -1) {
        // handle less then
        let end = parseFloat(value.split("<")[1]);
        this.filter_store[field] = ["<", end];
        return ["<", end];
      } else if (value === "null") {
        // handle blank filter
        this.filter_store[field] = [];
        return ["blank", value];
      } else {
        // handle normal values
        let numericValue = parseFloat(value);
        this.filter_store[field] = ["=", numericValue];
        return ["=", numericValue];
      }
    },
    isNumber(event) {
      let regex = ">|<|-|([0-9nul])|_|=|\\.";
      let result = RegExp(regex).test(event.key);
      if (result === false) {
        event.preventDefault();
      }
    },
  },
};
</script>
