import { getDashboardURL, errorHandling, axiosHeaders, currencyConversion } from "../../utils/Utilities";
import axios from "axios";
import router from "@/router/router";
import moment from "moment";
import i18n from "@/i18n";

export default {
  state: {
    clientList: {},
    client_list_load: false,
    client_list_ready: false,
    selectedClient: {
      name: null,
      client: null,
      logo: null,
      url: null,
      rev_lead: null,
      types: [],
      currency: "$",
    },
  },
  getters: {
    launchDate: (state) => {
      return state.clientList[state.selectedClient.name].seo_launch_date;
    },
    // return revenue or leads with revenue being the default state 
    revenue_leads_getter: (state) => {
      if(state.selectedClient.rev_lead == 'Leads') return i18n.t("chart_data.leads");
      return i18n.t("chart_data.revenue")
    },
    revenue_leads_string_getter: (state) => {
      if(state.selectedClient.rev_lead == 'Leads') return 'Leads';
      return 'Revenue'
    },
    // return true fro revenue and false for leads
    revenue_leads_boolean_getter: (state) => {
      if(state.selectedClient.rev_lead == 'Leads') return false
      return true
    }
  },
  mutations: {
    updateClientList(state, response) {
      state.client_list_load = false;
      state.clientList = response.data.data;
      state.client_list_ready = true;
    },
    client_list_load(state) {
      state.client_list_load = true;
    },
    updateCurrentClientData(state, client) {
      state.selectedClient.name = client;
      state.selectedClient.client = state.clientList[client].name;
      state.selectedClient.logo = state.clientList[client].logo;
      state.selectedClient.url = state.clientList[client].url;
      state.selectedClient.rev_lead = state.clientList[client]["report_kpi"];
      state.selectedClient.types = state.clientList[client].keyword_types;
      state.selectedClient.currency = currencyConversion(state.clientList[client].currency);
      state.activeMonth = moment(new Date())
        .subtract(1, "months")
        .format("MMM YYYY");
    },
  },
  actions: {
    getClientList({ commit }) {
      commit("client_list_load");
      axios
        .get(`${getDashboardURL()}/api/get-clients`, axiosHeaders())
        .then((response) => {
          const res = response.data.data;
          const resLength = Object.keys(res).length;
          if (resLength < 2) {
            commit("updateClientList", response);
            router.push("/client/" + Object.keys(res)[0]);
          } else {
            commit("updateClientList", response);
          }
        })
        .catch((err) => {
          errorHandling(err);
        });
    },
  },
};
