<template>
  <div class="anime">
    <div>
      <div class="date">
        <span :data-date="value.insight_date" class="table-data">{{ value.insight_new_date }} </span>
      </div>
    </div>
    <div class="col-me-2 col-12 main-yellow-container p-0">
      <div class="row" style="margin-bottom:0;">
        <div class="py-2 col-6" style="">
          <span class="poster-name">{{ value.first_name + " " + value.last_name }} </span>
        </div>
        <div class="py-2 col-6 text-right" style="font-size: 14px;" v-if="value.sent_at && user_can_edit">
          <span>{{ insight_date_method(insight_time) }} </span>
          <font-awesome-icon icon="info-circle" :id="`email_data_popover-${value.id}`" />
          <Popover :value="value"></Popover>
        </div>
      </div>
      <div class="bg-y col-md-12 py-2 p-lg-3 item-content-container">
        <div class="px-0 py-0 px-lg-3">
          <div :id="value.id" v-html="value.insights.description.text"></div>
        </div>
        <div class="button-container container" v-if="user_can_edit == 1">
          <div class="row">
            <div v-if="value.sent_at == null" class="col-4">
              <div class="button" @click="deleteData(value.id, index)">
                <span>Delete </span>
                <svg>
                  <polyline class="o1" points="0 0, 80 0, 80 40, 0 40, 0 0"></polyline>
                  <polyline class="o2" points="0 0, 80 0, 80 40, 0 40, 0 0"></polyline>
                </svg>
              </div>
            </div>
            <div class="col-4">
              <div class="button-container">
                <div
                  class="button"
                  @click="
                    editItem();
                    checkDate();
                  "
                >
                  <span>Edit </span>
                  <svg>
                    <polyline class="o1" points="0 0, 80 0, 80 40, 0 40, 0 0"></polyline>
                    <polyline class="o2" points="0 0, 80 0, 80 40, 0 40, 0 0"></polyline>
                  </svg>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="button-container">
                <div class="button" @click="emailData(value.id, index)">
                  <span>{{ insight_has_been_sent }} </span>
                  <svg>
                    <polyline class="o1" points="0 0, 80 0, 80 40, 0 40, 0 0"></polyline>
                    <polyline class="o2" points="0 0, 80 0, 80 40, 0 40, 0 0"></polyline>
                  </svg>
                </div>
              </div>
            </div>
            <div id="showMe" class="hidden-show-me" :class="[saved ? 'show-me' : '']">Changes saved...</div>
          </div>
        </div>

        <div v-if="!editing">
          <div v-if="checkedCalendar">
            <VueCtkDateTimePicker
              formatted="llll"
              format="YYYY-MM-DD hh:mm a"
              v-model="editor_data.insights.meeting_info.date"
            ></VueCtkDateTimePicker>
          </div>
          <div style="position:relative;">
            <ckeditor
              :editor="editor"
              v-model="editorData"
              :config="editorConfig"
              @input="createLocalStorage(value.id)"
            >
            </ckeditor>
            <br />
            <button type="button" class="btn btn-secondary" @click="saveData(value.id, value.insight_date)">
              Save Changes
            </button>

            <span style="position:absolute;right:240px;top:5px;"
              ><input @change="checkDate()" type="checkbox" v-model="checkedCalendar" value="calendar" /> Calendar</span
            >
            <div style="position:absolute;right:20px;top:-9px;">
              <b-dropdown
                variant="outline-secondary"
                id="dropdown-form"
                text="Selected Images"
                ref="dropdown"
                class="m-2"
              >
                <b-dropdown-form class="images_dropdown">
                  <b-form-checkbox class="mb-3" v-model="images.session_revenue">Session / Revenue</b-form-checkbox>
                  <b-form-checkbox class="mb-3" v-model="images.ranking_summary">Ranking Summary</b-form-checkbox>
                  <b-form-checkbox class="mb-3" v-model="images.keyword_index"
                    >Active Keywords / Indexed Pages</b-form-checkbox
                  >
                </b-dropdown-form>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import axios from "axios";
import Popover from "./insight-popover";
import { getDashboardURL, insight_date_formatter, apiError, axiosHeaders } from "../../../utils/Utilities.js";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import * as moment from "moment";
export default {
  name: "Monthly-item",
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: Number,
  },
  components: {
    Popover,
    VueCtkDateTimePicker,
  },
  data() {
    return {
      checkedCalendar: false,
      visible: false,
      images: {
        session_revenue: true,
        ranking_summary: true,
        keyword_index: true,
      },
      editor: ClassicEditor,
      editorData: this.value.insights.description.text,
      date: "",
      insight_time: "",
      insight_sent_to: "",
      insight_sent_bcc: "",
      insight_from: "",
      editor_data: {
        insights: {
          description: {
            text: "",
          },
          session_revenue: {
            chart: "",
            title: "SESSIONS / REVENUE",
          },
          ranking_summary: {
            chart: "",
            title: "RANKING SUMMARY",
          },
          keyword_index: {
            chart: "",
            title: "ACTIVE KEYWORDS / INDEXED PAGES",
          },
          meeting_info: {
            date: "",
          },
        },
        insight_date: this.value.insight_date,
        first_name: this.$store.state.auth.first_name,
        last_name: this.$store.state.auth.last_name,
        email: this.$store.state.auth.email,
        id: this.value.id,
      },
      editorConfig: {},
      editing: true,
      saved: false,
      changeClients: false,
    };
  },
  computed: {
    user_can_edit() {
      return this.$store.state.auth.edit;
    },
    insight_has_been_sent() {
      return !!this.value.sent_at == true ? "Resend" : "Send";
    },
    selectedClientName() {
      return this.$store.state.clientList.selectedClient.name;
    },
  },
  methods: {
    apiError(response) {
      return apiError(response);
    },
    checkDate() {
      if (!this.value.insights.meeting_info || this.value.insights.meeting_info.length == 0) {
        this.editor_data.insights.meeting_info = {
          date: "",
        };
      }
      if (
        !this.value.insights.session_revenue ||
        this.value.insights.session_revenue.chart == "" ||
        this.value.insights.session_revenue.length == 0
      ) {
        this.images.session_revenue = false;
        this.editor_data.insights.session_revenue = {
          chart: "",
          title: "SESSIONS / REVENUE",
        };
      }
      if (
        !this.value.insights.ranking_summary ||
        this.value.insights.ranking_summary.chart == "" ||
        this.value.insights.ranking_summary.length == 0
      ) {
        this.images.ranking_summary = false;
        this.editor_data.insights.ranking_summary = {
          chart: "",
          title: "RANKING SUMMARY",
        };
      }
      if (
        !this.value.insights.keyword_index ||
        this.value.insights.keyword_index.chart == "" ||
        this.value.insights.keyword_index.length == 0
      ) {
        this.images.keyword_index = false;
        this.editor_data.insights.keyword_index = {
          chart: "",
          title: "ACTIVE KEYWORDS / INDEXED PAGES",
        };
      }
    },
    insight_date_method(x) {
      if (x != null) {
        return "Sent: " + insight_date_formatter(x);
      } else {
        return "";
      }
    },
    emailData() {
      Swal.fire({
        title: `Do you want to ${this.insight_has_been_sent.toLowerCase()} this report?`,
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ffc001",
        cancelButtonColor: "#a5a5a5",
        confirmButtonText: "Yes, send it!",
      }).then((result) => {
        if (result.value) {
          var sendEmail = true;
          this.sendData(this.value.id, this.value.insight_date, sendEmail);
          Swal.fire({
            title: "Sent!",
            text: "Your file has been sent.",
            type: "success",
            confirmButtonColor: "#ffc001",
          });
        }
      });
    },
    createLocalStorage(id) {
      sessionStorage.setItem(`${this.selectedClientName}/monthly_insights_${id}`, this.editorData);
    },
    saveData() {
      Swal.fire({
        title: `Do you want to save this report?`,
        text: "This will not send to client!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ffc001",
        cancelButtonColor: "#a5a5a5",
        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.value) {
          var sendEmail = true;
          this.sendDataLocal(this.value.id, this.value.insight_date, sendEmail);
          Swal.fire({
            title: "Saved!",
            text: "Your file has been saved.",
            type: "success",
            confirmButtonColor: "#ffc001",
          });
        }
      });
    },

    sendDataLocal(id, insight_date, sendEmail) {
      this.editor_data.insights.description.text = this.value.insights.description.text;
      for (const property in this.images) {
        var selection = document.body.querySelector("." + property + ".chart-wrapper canvas") !== null;

        if (this.images[property] == true && selection) {
          this.editor_data.insights[property].chart = document.body
            .querySelector("." + property + ".chart-wrapper canvas")
            .toDataURL("image/jpg");
        } else {
          delete this.editor_data.insights[property];
        }
      }

      this.value.insights = this.editor_data.insights;
      this.value.insights.description.text = this.editorData;

      if (!this.checkedCalendar) {
        delete this.editor_data.insights.meeting_info;
      } else {
        this.editor_data.insights.meeting_info.date = moment(this.editor_data.insights.meeting_info.date).format(
          "YYYY-MM-DD HH:mm"
        );
      }
      // var params = {
      //   new_item: this.editor_data,
      // };
      let self = this;
      let client = this.selectedClientName;
      axios
        .post(`${getDashboardURL()}/api/monthly-insights/` + client + `/`, this.editor_data, axiosHeaders())
        .then(() => {
          if (!sendEmail) {
            this.editItem();
          }
          this.saved = true;
          sessionStorage.removeItem(`${this.selectedClientName}/monthly_insights_${id}`);
          this.checkDate();
        })
        .catch(function(res) {
          self.apiError(res);
        });
    },
    sendData(id) {
      var emailData = {
        id: id,
      };
      let client = this.selectedClientName;
      const self = this;
      axios
        .post(`${getDashboardURL()}/api/monthly-insights/` + client + `/send`, emailData, axiosHeaders())
        .then(function(response) {
          self.insight_time = response.data.data.sent_at.date;
          self.value.sent_at = true;
        })
        .catch(function(res) {
          self.apiError(res);
        });
    },
    deleteData(id, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ffc001",
        cancelButtonColor: "#a5a5a5",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteMonthlyInsight", {
            id,
            index,
          });
          this.$store.state.selectedDate = this.value.insight_new_date;
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            type: "success",
            confirmButtonColor: "#ffc001",
          });
        }
      });
    },
    editItem() {
      this.saved = false;
      this.editing = !this.editing;
    },
  },
  created() {
    this.checkDate();
    this.insight_time = this.value.sent_at;
    const self = this;
    this.$store.watch(
      (state) => state.monthlyInsights.editor_data.new_item,
      () => {
        self.editorData = self.value.insights.description.text;
      },
      (state) => state.clientList.selectedClient.name,
      () => {
        self.changeClients = true;
      }
    );
  },
  mounted() {
    this.checkDate();
    if (!this.value.insights.meeting_info || this.value.insights.meeting_info.length == 0) {
      this.editor_data.insights.meeting_info = {
        date: "",
      };
    } else {
      this.checkedCalendar = true;
    }
    if (this.value.insights.meeting_info) {
      this.editor_data.insights.meeting_info.date = this.value.insights.meeting_info.date;
    }
    const self = this;
    this.$store.watch(
      (state) => state.clientList.selectedClient.name,
      () => {
        self.changeClients = true;
        if (!self.changeClients) {
          if (sessionStorage.getItem(`${this.selectedClientName}/monthly_insights_${self.value.id}`) != null) {
            self.editorData = sessionStorage.getItem(`${this.selectedClientName}/monthly_insights_${self.value.id}`);
          }
        } else {
          setTimeout(function() {
            self.editorData = self.value.insights.description.text;
          }, 1500);
        }
      }
    );
    if (!this.changeClients) {
      if (sessionStorage.getItem(`${this.selectedClientName}/monthly_insights_${this.value.id}`) != null) {
        this.editorData = sessionStorage.getItem(`${this.selectedClientName}/monthly_insights_${this.value.id}`);
      }
    } else {
      this.editorData = this.value.editor_data.insights.description.text;
    }
  },
};
</script>
<style lang="scss">
td {
  vertical-align: top;
}

.images_dropdown .b-dropdown-form {
  padding: 0.25rem 0.5rem;
}
.date-editor {
  position: relative;
  .date-dropdown {
    position: relative;
    z-index: 2;
    #ddown2 {
      position: absolute;
      right: -3px;
      top: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
@media (max-width: 700px) {
  .tab-head.p-4 {
    margin-bottom: 20px;
  }
  body .date {
    right: 35%;
  }
  ul {
    padding-inline-start: 20px;
  }
}
.hidden-show-me {
  opacity: 0;
  max-width: 120px;
  background: #a5a5a5;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  position: absolute;
  bottom: 50px;
  left: 10px;
  &:hover {
    cursor: default;
  }
}
.show-me {
  animation: cssAnimation 0.5s 1s forwards;
  opacity: 1;
}

@keyframes cssAnimation {
  to {
    opacity: 0;
  }
}
.item-content-container {
  ul {
    position: relative;
    li {
      position: relative;
      list-style-type: none;
      //  list-style-image: url('https://i.ibb.co/LnjqCCx/bolt-item.png');
    }
    li:before {
      content: "";
      position: absolute;
      top: 3px;
      left: -16px;
      width: 13px;
      height: 19px;
      background-image: url("https://i.ibb.co/LnjqCCx/bolt-item.png");
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  svg {
    height: 40px;
    width: 80px;
    fill: none;
    stroke-width: 5;

    .o1 {
      stroke: rgba(#fff, 0.7);
      fill: #ffc001;
      transition: all 1s ease-in-out;
    }

    .o2 {
      stroke: #ffc001;
      stroke-dasharray: 20 420;
      stroke-dashoffset: 20;
      transition: all 1s ease-in-out;
    }
  }

  span {
    position: absolute;
    margin: auto 0;
  }

  &:hover {
    .o1 {
      // fill: rgba(#CC8110, 0.3);
    }

    .o2 {
      stroke-dashoffset: -420;
    }
  }
}
@media (min-width: 700px) {
  .main-yellow-container {
    min-width: 600px;
  }
  .button-container {
    .row {
      margin: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .button-container .col-sm-6:first-child {
    margin-bottom: 20px;
  }
}
</style>
<style lang="scss" scoped>
.brd-top {
  margin-left: 0;
}
.col-me-1 {
  -ms-flex: 0 0 70px;
  -webkit-box-flex: 0;
  flex: 0 0 70px;
  max-width: 70px;
}
.col-11 {
  -ms-flex: 0 0 calc(91.666667% - 70px);
  -webkit-box-flex: 0;
  flex: 0 0 calc(91.666667% - 70px);
  max-width: calc(91.666667% - 70px);
}

.profile-pic {
  height: 44px;
  width: 44px;
  border-radius: 10px;
}

@media (max-width: 991.98px) {
  .button-container .col-sm-6 {
    margin-bottom: 20px;
  }
  .col-11 {
    -ms-flex: 0 0 calc(100% - 70px);
    -webkit-box-flex: 0;
    flex: 0 0 calc(100% - 70px);
    max-width: calc(100% - 70px);
  }
}
</style>
