<template>
  <div class="home">
    <Layout>
      <div class="container-fluid main-body-container" id="container">
        <!-- check if organic or paid and show matching content  -->
        <div ref="SEOcontainer" v-if="seo_sem" id="organic-container">
          <MainSEO />
        </div>
        <div ref="SEMcontainer" v-if="!seo_sem" id="paid-container">
          <MainSEM />
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
// import MainBody from "./MainBody.vue";
import Layout from "../layouts/main";
import MainSEM from "../../components/Containers/MainSEM";
import MainSEO from "../../components/Containers/MainSEO";
import { mapState } from "vuex";

import { get_keyword_report_api } from "@/utils/api.js";

// @vuese
// @group Views
// main content display page for organic and paid data, controls whether to show Organic or Paid containers
export default {
  name: "OrganicPaidContainerView",
  components: {
    Layout,
    MainSEM,
    MainSEO,
  },
  created() {
    // tell store this isnt the keyword approval page
    this.$store.commit("approval_page", false);
    // pull the client name from the url
    const client = this.$route.params.id;
    // update selected client data (i/e name, rev/lead client currency etc)
    this.$store.commit("updateCurrentClientData", client);
    // select whether to show organic or paid by default, if both will default to organic
    if (!this.selectedClientTypes.includes("seo")) {
      this.$store.commit("SEM_default");
    }
    if (!this.selectedClientTypes.includes("sem")) {
      this.$store.commit("SEO_default");
    }
    // get client data that applies to both organic and paid containers
    get_keyword_report_api(client);


  },
  computed: {
    ...mapState({
      seo_sem: "seosem",
      selectedClientTypes: (state) => state.clientList.selectedClient.types,
    }),
  },
};
</script>
