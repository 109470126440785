<template>
  <div v-if="Object.keys(clientList).length > 1" class="m-auto p-0" style="min-width: 130px">
    <v-select
      :options="clients"
      class="style-chooser"
      v-model="selected"
      :value="selected"
      @input="selectNewClient(selected)"
      :clearable="false"
    ></v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selected: "",
    };
  },
  created() {
    // update the initial text to show in the dropdown 
    this.selected = this.selectedClient;
  },
  computed: {
    currentPage() {
      return this.$route.name;
    },
    ...mapState({
      clientList: (state) => state.clientList.clientList,
      selectedClient: (state) => state.clientList.selectedClient.client,
    }),
    clients() {
    // map the client list to meet the v-select dropwdown format 
      var clientList = this.clientList;
      return Object.keys(clientList).map((client) => ({
        code: client,
        label: clientList[client].name,
      }));
    },
  },
  methods: {
    selectNewClient(value) {
      const client = value.code;
      const clientName = value.label;
    //  only run if selecting a new client 
      if (clientName != this.selectedClient) {
        this.$store.commit('resetUpliftCall')
        this.$store
          .dispatch("getNewClient", client)
          .then(() =>
            this.currentPage === "Client"
              ? this.$router.push("/client/" + client)
              : this.$router.push(`/client/${client}/keyword-approval`)
          )
          .catch((err) => console.log(err));
      }
    //  ideally this would be moved elsewhere 
      this.$store.dispatch("fetchMonthlytInsight", {
        editor_data: {
          new_item: {},
        },
      });
    },
  },
};
</script>

<style></style>
