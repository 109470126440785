<template>
  <div id="toggle_outer_container">
    <div
      :class="[{ 'select-left': position }, { 'select-right': !position }]"
      id="toggle_container"
      @click="changeSEO_SEM"
    >
      <div id="item"></div>
      <div class="left">
        <span>LUX<br />{{ $t("nav.organic") }}</span>
      </div>
      <div class="right">
        <span>LUX<br />{{ $t("nav.paid") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// @vuese
// @group Components
// Component to switch between Organic and Paid content
export default {
  name: "organic_paid_slider",
  props: {
    organicPaid: Boolean,
  },
  data() {
    return {
      // true === seo
      position: true,
    };
  },
  methods: {
    changeSEO_SEM() {
      this.position = !this.position;
      this.$store.commit("changeSEO_SEM_State");
    },
  },
  mounted() {
    this.position = this.organicPaid;
  },
};
</script>

<style lang="scss" scoped>
#toggle_outer_container {
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#toggle_container {
  cursor: pointer;
  position: relative;
  width: 140px;
  height: 34px;
  background-color: white;
  border: 1px solid #e3e8ec;
  margin: 0;
  border-radius: 4px;
  touch-action: none;
}
#toggle_container.select-left .left span {
  opacity: 1;
  color: white;
}
#toggle_container.select-right .right span {
  opacity: 1;
  color: white;
}

#item {
  position: relative;
  width: 70px;
  height: 34px;
  border-radius: 4px;
  transition: all 0.05s cubic-bezier(0.04, 0.46, 0.36, 0.99);
  touch-action: none;
  user-select: none;
}
#item::after {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  height: calc(100%);
  background-color: #ffc001;
  content: "";
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
#toggle_container.select-right #item::after {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  height: calc(100%);
  background-color: #ffc001;
  content: "";
}
#toggle_container.select-right #item {
  transition: all 0.2s cubic-bezier(0.04, 0.46, 0.36, 0.99) 0s;
  transform: translate3d(70px, 0px, 0px);
}
#toggle_container.select-left #item {
  transition: all 0.2s cubic-bezier(0.04, 0.46, 0.36, 0.99) 0s;
  transform: translate3d(0px, 0px, 0px);
}

.left {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}
.left span {
  text-transform: uppercase;
  text-align: center;
  font-size: 11px;
  pointer-events: none;
  user-select: none;
  color: #a5a5a5;
  transition: all 0.2s ease;
  font-weight: 700;
  line-height: 1.2;
}

.right {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  pointer-events: none;
  user-select: none;
}
.right span {
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  font-size: 11px;
  pointer-events: none;
  user-select: none;
  color: #a5a5a5;
  transition: all 0.2s ease;
  font-weight: 700;
}
</style>
