<template>
  <div id="keyword-ranking-summary">
    <div v-if="!keywordRankingData && !data_fetched" class="row rounded">
        <div class="col-12 m-auto rounded-col-wrapper mx-1">
          <div class="row bg-w my-0 rounded-col m-0 cards h-100">
            <div class="loading-container">
              <b-spinner class="align-left"></b-spinner>
              <p>loading</p>
            </div>
          </div>
        </div>
    </div>
    <div class="row rounded" v-if="keywordRankingData">
      <div class="col-12 col-md-6 col-lg-5 col-xs-12 m-auto rounded-col-wrapper second-col mx-1">
        <div class="row bg-w my-0 rounded-col m-0 cards h-100">
          <div class="col-12 pr-0 my-2">
            <div class="mid-txt grey text-center">{{ $t("keyword_rankings.keyword_ranking_changes") }}</div>
          </div>
          <div class="col-12 my-0 mobile-center text-align-sm-right">
            <div class="row m-0 p-0">
              <div class="col-6 p-0 text-center">
                <span class="arrow up">
                  <font-awesome-icon icon="long-arrow-alt-up" />
                </span>
                <span class="lrg-colored px-1 up">{{
                  keywordRankingData.seo_total_keywords_ranked_higher_than_prev_month.toLocaleString()
                }}</span>
              </div>
              <div class="col-6 p-0 text-center">
                <span class="arrow down">
                  <font-awesome-icon icon="long-arrow-alt-down" />
                </span>
                <span class="lrg-colored px-1 down">{{
                  keywordRankingData.seo_total_keywords_ranked_lower_than_prev_month.toLocaleString()
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-7 m-auto rounded-col-wrapper third-col">
        <div class="row mx-0 bg-w my-0 rounded-col cards px-2">
          <div class="col-12 col-lg-3 my-auto serp-changes-wrapper py-2">
            <div class="mid-txt grey text-center">{{ $t("keyword_rankings.keyword_rankings") }}</div>
          </div>
          <div class="col-4 col-sm-4 col-lg-3 m-auto serp-col">
            <div class="border p-1 rounded">
              <div class="med-txt2 grey align-text-top">#1</div>
              <div class="med-txt blk align-text-right">
                <span>{{ Math.round(keywordRankingData.seo_ranking_position_1) }}%</span>
              </div>
            </div>
          </div>
          <div class="col-4 col-sm-4 col-lg-3 m-auto serp-col">
            <div class="border p-1 rounded">
              <div class="med-txt2 grey align-text-top">#1-10</div>
              <div class="med-txt blk">
                <span>{{ Math.round(keywordRankingData.seo_ranking_page_1) }}%</span>
              </div>
            </div>
          </div>
          <div class="col-4 col-sm-4 col-lg-3 m-auto position-relative serp-col">
            <div class="border p-1 rounded">
              <div class="med-txt2 grey align-text-top">#1-100</div>
              <div class="med-txt blk">
                <span>{{ 100 - Math.round(keywordRankingData.seo_no_ranking) }}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @vuese 
// @group Rows
// Container for the keyword ranking data 
export default {
  name: "KeywordRankings",
  computed: {
    keywordRankingData() {
      return this.$store.state.clientData.staticData.data;
    },
    data_fetched() {
      return this.$store.state.clientData.static_data_fetched;
    },
  },
};
</script>

<style lang="scss" scoped>
.med-txt {
  line-height: 1.2;
}
.mobile-center {
  padding: 0 7px;
}
.arrow {
  font-size: 33px;
  position: relative;
  bottom: auto;
}
.up {
  color: rgb(102, 218, 181);
}
.down {
  color: #6f6f6f;
}
.rounded {
  .rounded-col-wrapper {
    padding-left: 0;
    padding-right: 0;
    background-color: #faf9f5;
    .rounded-col {
      min-height: 100px;
    }
  }
}
.second-col {
  padding-right: 14px !important;
}
.position-absolute {
  bottom: 3px;
  right: 22px;
}
.positive {
  color: #66dab5;
}
.neg {
  color: #f75252;
}

@media (max-width: 1344px) {
  .lg-num,
  .lrg-colored {
    font-size: 33px;
  }
  .arrow {
    font-size: 32px;
  }
}

@media (max-width: 1199.98px) {
  .serp-col {
    padding: 5px 2px;
  }
  .second-col {
    margin: 0 !important;
  }
}

@media (min-width: 767px) and (max-width: 1045px) {
  .med-txt {
    display: block;
    text-align: center;
  }
  .med-txt {
    display: block;
  }
  .position-absolute {
    position: inherit !important;
    display: block;
    text-align: center;
  }
}
// @media (max-width: 420px) {
//   .first-col,
//   .second-col {
//     -ms-flex: 0 0 100%;
//     -webkit-box-flex: 0;
//     flex: 0 0 100%;
//     max-width: 100%;
//   }
// }
@media (max-width: 767.98px) {
  

  .mobile-center,
  .convert-txt,
  .lg-num-blk-wrapper,
  .serp-changes-wrapper {
    text-align: center;
  }
  .text-align-sm-right {
    text-align: right;
  }
  .text-align-sm-left {
    text-align: left;
  }

  .rounded-col-wrapper:nth-child(1),
  .rounded-col-wrapper:nth-child(2) {
    margin-bottom: 5px !important;
  }
}

@media (max-width: 639.9px) {
  .second-col {
    padding-right: 0 !important;
    margin: 0 !important;
  }
  .med-txt {
    display: block;
    text-align: center;
  }
  .med-txt {
    display: block;
  }
  .position-absolute {
    position: inherit !important;
    display: block;
    text-align: center;
  }
}
</style>
