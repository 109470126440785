export function yAxisOptions(id, pos, label, windowFunc, gridLineLabel, tick_display) {
  return {
    id: id,
    type: "linear",
    position: pos,
    gridLines: {
      display: gridLineLabel,
    },
    scaleLabel: {
      display: windowFunc,
      labelString: label,
      fontColor: "#c4c6c5",
      fontFamily: "dosis",
      fontSize: 14,
    },
    ticks: {
      display: tick_display,
      fontColor: "#c4c6c5",
      fontFamily: "dosis",
      beginAtZero: true,
      min: 0,
      fontSize: 14,
      callback: function(value) {
        if (value > 999) return (value / 1000).toFixed(1) + "k";
        return value;
      },
    },
  };
}

export function yAxisOptionsLine(id, pos, windowFunc, gridLineLabel, maxvalue, label) {
  return {
    id: id,
    type: "linear",
    position: pos,
    gridLines: {
      display: gridLineLabel,
    },
    scaleLabel: {
      display: windowFunc,
      labelString: label,
      fontColor: "#c4c6c5",
      fontFamily: "dosis",
      fontSize: 14,
    },
    ticks: {
      display: true,
      fontColor: "#c4c6c5",
      fontFamily: "dosis",
      beginAtZero: true,
      min: 0,
      fontSize: 14,
      suggestedMax: maxvalue * 1.2,
      callback: function(value) {
        if (value > 999) return (value / 1000).toFixed(1) + "k";
        return value;
      },
    },
  };
}
