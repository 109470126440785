<template>
  <ul class="navbar-nav">
    <li class="nav-item kw-approval" :class="link.class" v-for="(link, index) in links" :key="index">
      <router-link :to="link.to" class="keyword-approval-tab">{{ link.text }}</router-link>
    </li>
  </ul>
</template>

<script>
import i18n from "@/i18n";

// @vuese 
// @group Components
// Component to loop through all the nav links and display them as a router link
export default {
  props: {
    clientName: String,
    currentPage: String,
    organicPaid: String
  },
  computed: {
    links() {
      return [
        {
          name: "home",
          to: `/client/${this.clientName}`,
          text: i18n.t("nav.home"),
          class: this.currentPage === "keyword_approval" ? null : "active-location",
        },
        {
          name: "keywordApproval",
          to: `/client/${this.clientName}/keyword-approval`,
          text: i18n.t("nav.keyword_approval"),
          class: this.currentPage !== "keyword_approval" ? null : "active-location",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
li.nav-item.kw-approval {
  margin-right: 5px;
  &:after {
    content: " ";
    border-bottom: 2px solid #ffc001;
    border-bottom-width: 2px;
    display: table-cell;
    width: 0px;
    position: absolute;
    bottom: -28px;
    transition: all 0.5s;
  }
  position: relative;
  &:hover:after {
    content: " ";
    border-bottom: 2px solid #ffc001;
    border-bottom-width: 2px;
    display: table-cell;
    width: 100%;
    max-width: 134px;
    transition: all 0.5s;
    position: absolute;
    bottom: -28px;
  }
  .keyword-approval-tab {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a5a5a5;
    &:hover {
      text-decoration: none;
    }
  }
}
.active-location {
  &:after {
    content: " ";
    border-bottom: 2px solid #ffc001 !important;
    border-bottom-width: 2px !important;
    display: table-cell !important;
    width: 100% !important;
    max-width: 134px !important;
    transition: all 0.5s !important;
    position: absolute !important;
    bottom: -28px !important;
  }
  a {
    color: #ffc001 !important;
  }
}
@media (max-width: 1025px) {
  .navbar-nav {
    flex-wrap: wrap;
  }
  li.nav-item.kw-approval {
    margin: 3px 0;
    &:after {
      content: none;
    }
  }
  .active-location {
    &:after {
      content: none;
    }
  }
}
</style>
