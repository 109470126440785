<template>
  <div id="file-drag-drop" class="p-4 bg-w cards">
    <div class="uploaded">
      <h3>
        {{ seosem == "seo" ? $t("tab_container.kw_upload.SEO_uploaded") : $t("tab_container.kw_upload.SEM_uploaded") }}
      </h3>
      <b-table
        v-if="csvList && csvList.data && csvList.data.length"
        responsive
        no-sort-reset
        hover
        :items="csvList.data"
        :fields="fields()"
      >
        <template slot="cell(id)" slot-scope="data">
          <b-button
            @click=" download(data.item.id, data.item.file_name, data.item.uploaded_date)"
          >
            <font-awesome-icon icon="download" />
          </b-button>
        </template>
      </b-table>
      <div v-if="csvList && csvList.data && !csvList.data.length" class="my-4">
        <span>{{ $t("tab_container.kw_upload.no_files_uploaded") }}</span>
      </div>
    </div>
    <div class="mt-3">
      <b-button
        @click="openUpload()"
        :class="{ up_arrow: upload_toggle, down_arrow: !upload_toggle }"
        >{{ $t("tab_container.kw_upload.upload.upload_title") }}</b-button
      >
    </div>
    <div class="file-upload-container mt-3" :class="{ hidden2: !upload_toggle }">
      <div class="instructions">
        <ul>
          <li>{{ $t("tab_container.kw_upload.upload.upload1") }}</li>
          <li>{{ $t("tab_container.kw_upload.upload.upload2") }}</li>
          <li>{{ $t("tab_container.kw_upload.upload.upload3") }}</li>
        </ul>
      </div>
      <div style="text-align: center">
        <b-alert variant="danger" style="margin: 0 auto; max-width: 400px" dismissible :show="errors"
          >Failure to submit File. Please check file format.</b-alert
        >
      </div>

      <form ref="fileform" id="keywordUploadform">
        <span class="drop-files">
          {{ $t("tab_container.kw_upload.upload.upload4") }}
          <b-button class="ml-2" variant="outline-primary" @click="addFiles()">{{
            $t("tab_container.kw_upload.upload.upload5")
          }}</b-button>
        </span>
      </form>
      <!-- <progress max="100" :value.prop="uploadPercentage"></progress> -->
      <div class="text-center">
        <div v-for="(file, key, index) in files" :key="index" class="file-listing text-left">
          <div class="flex position-relative">
            <!-- <img class="preview" v-bind:ref="'preview' + parseInt(key)" style="max-width: 50px; margin-right: 20px;" /> -->
            <div style="flex-grow: 1">
              <div style="font-size: 18px">
                {{ file.name }} -
                <span style="font-size: 14px">{{ byteConversion(file.size) }}</span>
              </div>
              <div style="font-size: 14px">
                {{
                  file.lastModifiedDate.toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                }}
              </div>
            </div>
            <div class="remove-container">
              <b-button-close class="remove close" style="width: 20px;" v-on:click="removeFile(key)"></b-button-close>
            </div>
          </div>
        </div>
        <b-button v-on:click="submitFiles()" v-show="files.length > 0" class="m-3">{{
          $t("tab_container.kw_upload.upload.submit")
        }}</b-button>
      </div>
      <div class="large-12 medium-12 small-12 cell hidden">
        <label>
          Files
          <input type="file" id="files" ref="files" v-on:change="handleFilesUpload()" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getDashboardURL } from "../../utils/Utilities";
import { axiosHeaders } from "../../utils/Utilities";
const FileDownload = require("js-file-download");
import { mapState } from "vuex";

export default {
  data() {
    return {
      dragAndDropCapable: false,
      files: [],
      uploadPercentage: 0,
      show_upload: true,
      upload_toggle: false,
      errors: false,
    };
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach(
        function(evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );
      this.$refs.fileform.addEventListener(
        "drop",
        function(e) {
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.files.push(e.dataTransfer.files[i]);
            // this.getImagePreviews();
          }
        }.bind(this)
      );
    }
  },
  computed: {
    ...mapState({
      client: (state) => state.clientList.selectedClient.client,
      clientName: (state) => state.clientList.selectedClient.name,
    }),
    csvList() {
      return this.$store.state.seosem ? this.$store.state.keywordUpload.seo_csvList : this.$store.state.keywordUpload.sem_csvList;
    },
    seosem() {
      var state = this.$store.state.seosem ? "seo" : "sem";
      return state;
    },
  },
  methods: {
    download(id, name, date) {
      name = name.replace(/\.[^/.]+$/, "");
      axios
        .get(`${getDashboardURL()}/api/request-download/` + this.clientName + `/${id}`, axiosHeaders())
        .then((response) => {
          FileDownload(response.data, `${name} ${date}.csv`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openUpload() {
      this.show_upload = !this.show_upload;
      this.upload_toggle = !this.upload_toggle;
    },
    byteConversion(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    fields() {
      return [
        { key: "uploader_email", label: this.$t("tab_container.kw_upload.uploader_email") },
        { key: "uploaded_date", label: this.$t("tab_container.kw_upload.uploaded_date") },
        { key: "file_name", label: this.$t("tab_container.kw_upload.file_name") },
        { key: "id", thClass: "hidden" },
      ];
    },
    addFiles() {
      event.preventDefault();
      this.$refs.files.click();
    },
    handleFilesUpload() {
      this.files = [];
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        if (!this.files.includes(uploadedFiles[i])) {
          this.files.push(uploadedFiles[i]);
        }
      }
      // this.getImagePreviews();
    },
    determineDragAndDropCapable() {
      var div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    // getImagePreviews() {
    //   for (let i = 0; i < this.files.length; i++) {
    //     if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
    //       let reader = new FileReader();
    //       reader.addEventListener(
    //         "load",
    //         function() {
    //           this.$refs["preview" + parseInt(i)][0].src = reader.result;
    //         }.bind(this),
    //         false
    //       );
    //       reader.readAsDataURL(this.files[i]);
    //     } else {
    //       this.$nextTick(function() {
    //         this.$refs["preview" + parseInt(i)][0].src = "/images/file.png";
    //       });
    //     }
    //   }
    // },
    submitFiles() {
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("keyword_file", file);
      }
      axios
        .post(`${getDashboardURL()}/api/upload-keywords/${this.client}/${this.seosem}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            ...axiosHeaders().headers,
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          }.bind(this),
        })
        .then(() => {
          this.$store.dispatch("uploadedFiles_API_Call", { client: this.$route.params.id, seoState: this.seosem });
          this.errors = false;
          this.files = [];
        })
        .catch(() => {
          console.log("FAILURE!!");
          this.errors = true;
        });
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
  },
};
</script>

<style>
#keywordUploadform {
  display: block;
  height: 200px;
  width: 60%;
  background: #fff;
  margin: auto;
  margin-top: 40px;
  text-align: center;
  line-height: 200px;
  border-radius: 4px;
  border: 4px dashed #c0c0c0;
}

div.file-listing {
  width: 60%;
  margin: 10px auto;
  padding: 10px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
}
div.file-listing:hover {
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.45);
  border-radius: 6px;
}

div.remove-container {
  text-align: center;
}

div.remove-container a {
  color: red;
  cursor: pointer;
}

progress {
  width: 400px;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
.remove-container {
  position: absolute;
  top: 0;
  right: 0;
}
/* .close {
  border: none !important;
  position: absolute;
  right: 32px;
  width: 50px;
  height: 50px;
  opacity: 0.3;
  font-size: 50px;
  bottom: 2px;
} */
.close:hover {
  opacity: 1;
}

.down_arrow:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.up_arrow:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.hidden2 {
  display: none;
}
</style>
