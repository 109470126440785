<template>
<Layout>
  <div class="home">
    <div
      class="container-fluid main-body-container"
      style="display: flex; align-items: center; justify-content: center;"
    >
      <div style="margin-top:20px; width: 100%; height: 100%;">
        <div class="tableContainer">
          <h3>{{title}}</h3>
            <KeywordTable v-if="!show_placeholder && kw_awaiting_approval_check"/>
        </div>
        <Placeholder v-if="show_placeholder" />
      </div>
    </div>
  </div>
  </Layout>
</template>

<script>
import KeywordTable from "../../components/ui/keywordTable";
import { mapState } from "vuex";
import Placeholder from "../../components/ui/Placeholder.vue";
import { isEmpty } from "lodash";
import Layout from "../layouts/main";

// @vuese 
// @group Views
// Main page for the Keyword approval page
export default {
  name: "ApprovalView",
  created() {
    this.$store.commit("approval_page", true)
    this.$store.commit("updateCurrentClientData", this.$route.params.id);
    // fetch data on creation 
    this.dispatchCheck();
  },
  watch: {
    // re call the API whenever user toggles from organic to paid 
    seosem() {
      this.dispatchCheck();
    },
  },
  computed: {
    ...mapState({
      seosem: state => state.seosem,
      kw_awaiting_approval: (state) => state.keywordApproval.kw_awaiting_approval,
      selectedClientTypes: (state) => state.clientList.selectedClient.types,
    }),
    // check if keyword awaiting approval data exists
    kw_awaiting_approval_check() {
      if(this.kw_awaiting_approval.data && this.kw_awaiting_approval.data.keywords){
        return !isEmpty(this.kw_awaiting_approval.data.keywords);
      }
      return false
    },
    title(){
      return this.kw_awaiting_approval_check ? 'Keywords awaiting approval' : 'No keywords awaiting approval'
    },
    show_placeholder() {
      // determine whether placeholder should be shown depending on the selected client having paid / organic data 
      // if seo
      if (this.seosem && this.selectedClientTypes.includes("seo"))  return false;
      // if sem
      if (!this.seosem && this.selectedClientTypes.includes("sem")) return false;
      return true;
    },
  },
  methods: {
    // @vuese
    // Dispatches API call to retrieve keyword awaiting approval data. Runs on page creation
    dispatchCall(seoVal) {
      this.$store.dispatch("keyword_approval_API_call", {
        client: this.$route.params.id,
        per_page: 100,
        url: `api/get-keywords/${this.$route.params.id}/${seoVal}/awaiting-approval`,
        commitName: "keyword_awaiting_approval_update_data",
        fetchName: "TPK_data_fetched",
        name: "TPKData",
      });
    },
    dispatchCheck() {
      if (this.selectedClientTypes.includes("seo") && this.seosem) {
        this.dispatchCall("seo");
      }
      if (this.selectedClientTypes.includes("sem") && !this.seosem) {
        this.dispatchCall("sem");
      }
    },
  },
  components: {
    KeywordTable,
    Placeholder,
    Layout
  },
};
</script>

<style>
div.tableContainer {
  padding: 20px;
  background-color: white;
  border: 1px solid rgb(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
</style>
