<template>
  <div>
    <div v-if="!show_SEO_placeholder_check">
      <LogoRow />
      <KeywordRankings />
      <OrganicSummary v-if="month_data_fetched" />
      <ChartRow v-if="chart_data_fetched" />
      <TabContainer />
    </div>
    <div class="placeholder" v-if="show_SEO_placeholder_check">
      <Placeholder />
    </div>
  </div>
</template>

<script>
import Placeholder from "../../components/ui/Placeholder";
import LogoRow from "../../components/Rows/LogoRow.vue";
import OrganicSummary from "../../components/Rows/OrganicSummary.vue";
import ChartRow from "../../components/Rows/ChartRow.vue";
import KeywordRankings from "../../components/Rows/KeywordRankings.vue";
import TabContainer from "../../components/Rows/TabContainer.vue";
import { mapState } from "vuex";
import { SEO_API_calls, get_uploadedFiles_API_call } from "@/utils/api.js";

// @vuese
// @group Views
// displays all organic data
export default {
  name: "OrganicView",
  components: {
    LogoRow,
    OrganicSummary,
    ChartRow,
    KeywordRankings,
    TabContainer,
    Placeholder,
  },
  computed: {
    ...mapState({
      chart_data_fetched: (state) => state.clientData.chart_data_fetched,
      TPK_data_fetched: (state) => state.clientData.TPK_data_fetched,
      month_data_fetched: (state) => state.clientData.month_data_fetched,
      selectedClientTypes: (state) => state.clientList.selectedClient.types,
    }),
    // check if the client is organic and shows placeholder if not
    show_SEO_placeholder_check() {
      if (!this.selectedClientTypes.includes("seo")) return true;
      return false;
    },
  },
  created() {
    const client = this.$route.params.id;
    if (this.selectedClientTypes.includes("seo")) {
      this.$store.dispatch("fetchMonthlytInsight");
      // fetch client seo data
      SEO_API_calls(client);
      get_uploadedFiles_API_call(client, "seo");
    }
  },
};
</script>
