<template>
  <div class="active row">
    <div class="table-wrapper">
      <b-card no-body>
        <b-tabs nav-wrapper-class="navwrap" v-model="tabIndex" card>
          <!-- monthly insights  -->
          <b-tab :title-link-class="'tab-title'" v-if="seo_sem_boolean_getter" active>
            <template v-slot:title>{{ $t("tab_container.monthly_insights.title") }}</template>
            <Monthly />
          </b-tab>
          <!-- task list  -->
          <b-tab :title-link-class="'tab-title'">
            <template v-slot:title>{{ $t("task_list.task_list") }}</template>
            <taskList />
          </b-tab>
          <!-- keyword table  -->
          <b-tab :title-link-class="'tab-title'">
            <template v-slot:title>{{ $t("tab_container.keywords.title") }}</template>
            <a
              href="#"
              class="btn btn-secondary download"
              :title="$t('tab_container.keywords.download_keywords')"
              :alt="$t('tab_container.keywords.download_keywords')"
              @click="downloadKWs()"
              style="border-radius: 0.25rem; "
            >
              <font-awesome-icon icon="download" />
            </a>
            <keywordTable
              @open_modal="openModal"
              v-if="seo_sem_boolean_getter ? TPK_data_fetched : SEM_TPK_data_fetched"
            />
          </b-tab>
          <!-- keyword upload  -->
          <b-tab :title-link-class="'tab-title'">
            <template v-slot:title>{{ $t("tab_container.kw_upload.title") }}</template>
            <keywordUpload @open_modal="openModal" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import keywordTable from "../ui/keywordTable";
import axios from "axios";
import { getDashboardURL, axiosHeaders } from "../../utils/Utilities";
const FileDownload = require("js-file-download");
import Monthly from "../ui/MonthlyInsights/Monthly-insights";
import keywordUpload from "../ui/KeywordUpload";
import { mapState, mapGetters } from "vuex";
import taskList from "../ui/TaskList/taskList";

// @vuese
// @group Rows
// Container for monthly insights, task list, keyword table and keyword upload
export default {
  name: "Tab",
  data() {
    return {
      tabIndex: 0,
    };
  },
  components: {
    keywordTable,
    Monthly,
    keywordUpload,
    taskList,
  },
  computed: {
    ...mapState({
      TPK_data_fetched: (state) => state.clientData.TPK_data_fetched,
      SEM_TPK_data_fetched: (state) => state.clientData.SEM_TPK_data_fetched,
    }),
    ...mapGetters(["seo_sem_boolean_getter", "seo_sem_string_getter"]),
    // return organic or paid csv list depending on seo/sem state
    csvList_Ready() {
      return this.seo_sem_boolean_getter ? this.$store.state.seo_csvList_Ready : this.$store.state.sem_csvList_Ready;
    },
    csvMonth() {
      let dates = new Date();
      return dates
        .toLocaleDateString("en-us", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace("_", "");
    },
  },
  methods: {
    openModal(data) {
      this.$refs.modal.openModal(data);
    },
    downloadKWs() {
      axios
        .get(
          `${getDashboardURL()}/api/download-keywords/${this.$route.params.id}/${this.seo_sem_string_getter}`,
          axiosHeaders()
        )
        .then((response) => {
          FileDownload(
            response.data,
            `${this.$route.params.id.charAt(0).toUpperCase() + this.$route.params.id.slice(1)} ${
              this.seo_sem_string_getter
            } Keyword Report - ${this.csvMonth}.csv`,
            "text/csv;charset=utf-8",
            "\uFEFF"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
}
.download {
  position: absolute;
  right: 1%;
  top: -45px;
  z-index: 1000;
}
.tab-content {
  position: relative;
}
.hide {
  display: none;
}
.show {
  display: block;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

@media (min-width: 767px) and (max-width: 1045px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .download {
    right: 1%;
    top: -45px;
  }
}
// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}
</style>
