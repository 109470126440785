export default {
  state: {
    cancel_request: false,
    chart_data_fetched: false,
    ranking_chart_data_fetched: false,
    month_data_fetched: false,
    first_month_data_fetched: false,
    TPK_data_fetched: false,
    SEM_TPK_data_fetched: false,
    static_data_fetched: false,
    staticData: {},
    monthData: {},
    firstMonthData: {},
    TPKData: {},
    SEMTPKData: {},
    chartData: {},
    rankingChartData: {},
    SEM_Fail: false,
    firstUpliftCall: true,
    firstUpliftValue: false,
  },
  getters: {},
  mutations: {
    resetUpliftCall(state){
      state.firstUpliftCall = true
    },
    updateData(state, data) {
      state[data.fetchName] = true;
      state[data.name] = data.response.data;
      // uplift values are shown in the logorow and should not be updated when we select a new month
      // set an initial uplift value 
      if(data.name == 'monthData' && state.firstUpliftCall){
        state.firstUpliftValue = data.response.data.data.uplift
        state.firstUpliftCall = false
      }
    },
    SEM_Fail_Commit(state) {
      (state.SEM_Fail = true), (state.SEM_TPK_data_fetched = true);
    },
    SEM_Success_Commit(state) {
      (state.SEM_Fail = false), (state.SEM_TPK_data_fetched = true);
    },
  }
};