<template>
  <b-popover :target="`email_data_popover-${value.id}`" triggers="hover" placement="topleft">
    <template>
      <div>
        <table>
          <tr>
            <td class="text-right"><b>from:</b></td>
            <td>
              <span style="margin-left: 5px;">{{ value.email }}</span>
            </td>
          </tr>
          <tr v-if="value.sent_to && value.sent_to.length > 0">
            <td class="text-right"><b>to:</b></td>
            <td>
              <div style="margin-left: 5px;" v-for="(item, index) in value.sent_to" :key="index">{{ item }}</div>
            </td>
          </tr>
          <tr v-if="value.sent_to_bcc && value.sent_to_bcc.length > 0">
            <td class="text-right"><b>bcc:</b></td>
            <td>
              <div style="margin-left: 5px;" v-for="(item, index) in value.sent_to_bcc" :key="index">{{ item }}</div>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </b-popover>
</template>

<script>
export default {
  props: ["value"],
};
</script>
