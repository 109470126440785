<template>
  <div class="row" id="organic-summary">
    <div class="col-12 col-xl-9 bg-w cards" style="display: flex; flex-wrap: wrap; padding: 0;">
      <div class="row m-0 mt-2" style="flex: 100%">
        <div class="col-12" style="display: flex">
          <div class="rect-8 seo-box">
            <span class="SEO">{{ $t("leads_revenue_sessions.seo_summary") }}</span>
            <!-- <span class="SEO">LUX Organic Summary</span> -->
          </div>
          <b-dropdown variant="outline-secondary" id="monthDropdown" :text="shownMonth" class="mx-1" no-flip>
            <b-dropdown-item :key="index" v-for="(month, key, index) in prevMonths" @click="updateMonth(month)">{{
              month
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="row no-border seo-summary" style="flex: 100%">
        <!-- revenue leads  -->
        <div class="col-12 col-md-4 col-lg-3  col-left rev_container">
          <div class="focal-text">
            <div>
              <span class="currency">{{ Revenue_Leads == "Revenue" ? selectedClientCurrency : "" }} </span
              ><span>{{ conversions_revenue }}</span>
            </div>
          </div>
          <div class="convert-txt convert-lg d-inline-block" :style="getColor(conversions_revenue_mom)">
            <span class="mom-yoy">MOM</span>
            <font-awesome-icon :icon="arrow_direction(conversions_revenue_mom)" />
            {{ conversions_revenue_mom }}%
          </div>
          <div
            class="convert-txt convert-lg d-inline-block ml-3"
            v-if="showYOY && conversions_revenue_yoy"
            :style="getColor(conversions_revenue_yoy)"
          >
            <span class="mom-yoy">YOY</span>
            <font-awesome-icon :icon="arrow_direction(conversions_revenue_yoy)" />
            {{ conversions_revenue_yoy }}%
          </div>
          <div class="Leads-gene m-auto w-75 pt-3">
            <span class="mid-txt grey" style="font-size: 16px;">{{
              Revenue_Leads == "Revenue"
                ? $t("leads_revenue_sessions.revenue_generated_this_month")
                : $t("leads_revenue_sessions.leads_generated_this_month")
            }}</span>
          </div>
        </div>
        <!-- sessions  -->
        <div class="col-12 col-md-4 col-lg inside-border rev_container">
          <div class="focal-text">{{ sessions_val }}</div>
          <div class="convert-txt convert-lg d-inline-block" :style="getColor(sessions_mom)">
            <span class="mom-yoy">MOM</span>
            <font-awesome-icon :icon="arrow_direction(sessions_mom)" />
            {{ sessions_mom }}%
          </div>
          <div
            class="convert-txt convert-lg d-inline-block ml-3"
            v-if="showYOY && sessions_yoy"
            :style="getColor(sessions_yoy)"
          >
            <span class="mom-yoy">YOY</span>
            <font-awesome-icon :icon="arrow_direction(sessions_yoy)" />
            {{ sessions_yoy }}%
          </div>
          <div class="Leads-gene m-auto w-75 pt-3">
            <span class="mid-txt grey" style="font-size: 16px;">{{ $t("leads_revenue_sessions.sessions") }}</span>
          </div>
        </div>
        <!-- Transactions -->
        <div class="col-12 col-md-4 col-lg inside-border rev_container" v-if="Revenue_Leads === 'Revenue'">
          <div class="focal-text">{{ conversion_val }}</div>
          <div class="convert-txt convert-lg d-inline-block" :style="getColor(conversion_mom)">
            <span class="mom-yoy">MOM</span>
            <font-awesome-icon :icon="arrow_direction(conversion_mom)" />
            {{ conversion_mom }}%
          </div>
          <div class="Leads-gene m-auto w-75 pt-3">
            <span class="mid-txt grey" style="font-size: 16px;">
              {{ $t("leads_revenue_sessions.transactions") }}
            </span>
          </div>
        </div>
        <!-- conversion rate  -->
        <div class="col-12 col-md-4 col-lg inside-border rev_container">
          <div class="focal-text">{{ conversion_rate_val }}%</div>
          <div class="convert-txt convert-lg d-inline-block" :style="getColor(conversion_rate_mom)">
            <span class="mom-yoy">MOM</span>
            <font-awesome-icon :icon="arrow_direction(conversion_rate_mom)" />
            {{ conversion_rate_mom }}%
          </div>
          <div
            class="convert-txt convert-lg d-inline-block ml-3"
            v-if="showYOY && conversion_rate_yoy"
            :style="getColor(conversion_rate_yoy)"
          >
            <span class="mom-yoy">YOY</span>
            <font-awesome-icon :icon="arrow_direction(conversion_rate_yoy)" />
            {{ conversion_rate_yoy }}%
          </div>
          <div class="Leads-gene m-auto w-75 pt-3">
            <span class="mid-txt grey" style="font-size: 16px;">{{
              $t("leads_revenue_sessions.conversion_rate")
            }}</span>
          </div>
        </div>
        <!-- Bounce rate -->
        <div class="col-12 col-md-4 col-lg inside-border rev_container">
          <div class="focal-text">{{ bounce_rate_val }}%</div>
          <div class="convert-txt convert-lg d-inline-block" :style="getColor(bounce_rate_mom, false)">
            <span class="mom-yoy">MOM</span>
            <font-awesome-icon :icon="arrow_direction(bounce_rate_mom)" />
            {{ bounce_rate_mom }}%
          </div>
          <div class="Leads-gene m-auto w-75 pt-3">
            <span class="mid-txt grey" style="font-size: 16px;">
              {{ $t("leads_revenue_sessions.bounce_rate") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- second row  -->
    <div class="col-12 col-xl-3 p-0 pl-xl-1 mt-3 mt-xl-0 ">
      <div
        class="row p-0 m-0 h-100 cards client-comparison-container"
        style="border-left: 40px solid ##FAF9F5; display: flex"
      >
        <div class="col-12 col-lg col-xl-12 p-0">
          <div class="mx-0 p-2 h-100">
            <div class="row m-0 h-100">
              <div class="px-1 m-auto">
                <span class="toprow-smx">{{ $t("leads_revenue_sessions.client_comparison_data") }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- client conversion rate  -->
        <div class="col-12 col-lg col-xl-12 p-0  ">
          <div class="ml-md-1 p-2 pb-md-0 pb-lg-2 pb-xl-0 h-100 ml-xl-0">
            <div class="row m-0 h-100 cards">
              <div class="col-8  px-2 m-auto">
                <div class="toprow-smx grey">{{ $t("leads_revenue_sessions.non_brand_conversion_rate") }}</div>
              </div>
              <div class="col-4 text-right pr-2  pl-0 m-auto">
                <span class="lg-num blk">{{ non_brand_conversion_rate_val }}%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- client non brand br  -->
        <div class="col-12 col-lg col-xl-12 p-0  ml-xl-0">
          <div class="mx-0 p-2 h-100">
            <div class="row m-0 h-100 cards">
              <div class="col-8 px-2 m-auto">
                <div class="toprow-smx grey">{{ $t("leads_revenue_sessions.non_brand_bounce_rate") }}</div>
              </div>
              <div class="col-4 text-right pr-2  pl-0 m-auto">
                <span class="lg-num blk">{{ non_brand_bounce_rate_val }}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { prevMonths } from "../../utils/Utilities.js";
import { clientDataAPICall } from "../../utils/api.js";
import * as moment from "moment";
import { mapState, mapGetters } from "vuex";

// @vuese 
// @group Rows
// Container for the main organic data
export default {
  computed: {
    ...mapState({
      monthlyData: (state) => state.clientData.monthData.data,
      firstMonthData: (state) => state.clientData.firstMonthData.data,
      activeMonth: "activeMonth",
      selectedClientCurrency: (state) => state.clientList.selectedClient.currency,
      selectedClient_rev_leads: (state) => state.clientList.selectedClient.rev_lead,
      yearOverYearData: (state) => state.clientData.chartData.data,
      seosem: (state) => state.seosem,
    }),
    ...mapGetters(['seo_sem_string_getter']),
    prevMonths() {
      return prevMonths();
    },
    currentMonth() {
      return moment(new Date())
        .subtract(1, "months")
        .format("MMM YYYY");
    },
    // check if revenue or leads client, default to revenue 
    Revenue_Leads() {
      return this.selectedClient_rev_leads == null ? "Revenue" : this.selectedClient_rev_leads;
    },
    // month to display in dropdown 
    shownMonth() {
      return this.activeMonth == 0 ? this.prevMonths[0] : this.prevMonths[this.prevMonths.indexOf(this.activeMonth)];
    },
    // only show YOY data if the current month is selected. Old months dont have data
    showYOY() {
      return this.shownMonth == this.currentMonth ? true : false;
    },
    // return revenue or conversions data depending on client type 
    conversions_revenue() {
      return this.Revenue_Leads == "Revenue"
        ? Math.round(this.monthlyData.monthlyRev).toLocaleString()
        : Math.trunc(this.monthlyData.conversions).toLocaleString();
    },
    conversions_revenue_mom() {
      return this.Revenue_Leads == "Revenue"
        ? `${Math.round(this.monthlyData.revenueChangePercent * 100).toLocaleString()}`
        : `${Math.round(this.monthlyData.conversionsDifference * 100).toLocaleString()}`;
    },
    conversions_revenue_yoy() {
      if (this.yearOverYearData) {
        if (this.Revenue_Leads == "Revenue" && this.yearOverYearData.revenue[0]) {
          return `${Math.round(
            ((this.yearOverYearData.revenue[12] - this.yearOverYearData.revenue[0]) /
              this.yearOverYearData.revenue[0]) *
              100
          ).toLocaleString()}`;
        }
        if (this.Revenue_Leads != "Revenue" && this.yearOverYearData.conversions[0]) {
          return `${Math.round(
            ((this.yearOverYearData.conversions[12] - this.yearOverYearData.conversions[0]) /
              this.yearOverYearData.conversions[0]) *
              100
          ).toLocaleString()}`;
        }
      }
      return "";
    },
    sessions_val() {
      return Math.trunc(this.monthlyData.sessions).toLocaleString();
    },
    sessions_mom() {
      return Math.round(this.monthlyData.sessionsChangePercent * 100).toLocaleString();
    },
    sessions_yoy() {
      if (this.yearOverYearData && this.yearOverYearData.sessions[0]) {
        return `${Math.round(
          ((this.yearOverYearData.sessions[12] - this.yearOverYearData.sessions[0]) /
            this.yearOverYearData.sessions[0]) *
            100
        ).toLocaleString()}`;
      }
      return "";
    },
    conversion_rate_val() {
      return parseFloat(this.monthlyData.conversionRate * 100)
        .toFixed(2)
        .toLocaleString();
    },
    conversion_rate_mom() {
      return Math.round(this.monthlyData.conversionRateDifference * 100).toLocaleString();
    },
    conversion_rate_yoy() {
      if (this.firstMonthData && this.firstMonthData.conversionRate) {
        return Math.round(
          ((this.monthlyData.conversionRate - this.firstMonthData.conversionRate) /
            this.firstMonthData.conversionRate) *
            100
        ).toLocaleString();
      }
      return 0;
    },
    conversion_val() {
      return Math.trunc(this.monthlyData.conversions).toLocaleString();
    },
    conversion_mom() {
      return parseFloat(this.monthlyData.conversionsDifference * 100)
        .toFixed(2)
        .toLocaleString();
    },
    bounce_rate_val() {
      return Math.round(this.monthlyData.bounceRate * 100);
    },
    bounce_rate_mom() {
      return parseFloat(this.monthlyData.bounceRateDifference * 100).toLocaleString();
    },
    uplift_val() {
      return Math.round(this.monthlyData.uplift * 100).toLocaleString();
    },
    non_brand_bounce_rate_val() {
      return Math.round(this.monthlyData.nonBrandBounceRate * 100);
    },
    non_brand_conversion_rate_val() {
      return (this.monthlyData.nonBrandConversionRate * 100).toFixed(2);
    },
  },
  methods: {
    // get colour for the arrows and values depending on whether data is positive or not 
    getColor(value, standard = true) {
      if (standard && value >= 0) return { color: "#66dab5" };
      if (standard && value < 0) return { color: "#6f6f6f" };
      if (!standard && value >= 0) return { color: "#6f6f6f" };
      if (!standard && value < 0) return { color: "#66dab5" };
    },
    // update monthly data when new date is selected from dropdown 
    // fetch new data from API 
    updateMonth(month) {
      this.$store.commit("updateMonth", month);
      const jp = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
      const en = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
      if (jp.indexOf(month.split(" ")[0]) > -1) month = en[jp.indexOf(month.split(" ")[0])] + " " + month.split(" ")[1];
      clientDataAPICall({
        client: this.$route.params.id,
        type: this.seo_sem_string_getter,
        month: this.formatDate(month),
        url: "api/get-keyword-performance-summary",
        commitName: "updateMonthData",
        fetchName: "month_data_fetched",
        name: "monthData",
      });
    },
    formatDate(date) {
      return moment(date, "MMMYYYY").format("YYYY-MM");
    },
    arrow_direction(value, standard = true) {
      value = parseFloat(value.replace(/,/g, ""));
      if (standard) {
        return value >= 0 ? "long-arrow-alt-up" : "long-arrow-alt-down";
      }
      if (!standard) return value >= 0 ? "long-arrow-alt-down" : "long-arrow-alt-up";
    },
  },
};
</script>

<style lang="scss" scoped>
#monthDropdown {
  display: block;
  margin: auto;
}
.client-comparison-container {
  background-color: #f5f5f5;
}
.mom-yoy {
  font-size: 12px;
  margin-right: 4px;
}
.line-height-1 {
  line-height: 1;
}
.currency {
  font-size: 0.7em;
}
.focal-text {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  color: #ffc001;
  position: relative;
}
.date-dropdown {
  margin: auto;
  text-align: right;
}
.cl4 {
  padding-left: 15px;
  padding-right: 0;
}
.seo-summary {
  margin: 5px 0 20px 0;
}
.seo-box {
  clip-path: polygon(100% 0, 95% 50%, 100% 100%, 0 100%, 0 0);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.Leads-gene {
  padding-top: 5px !important;
}
.inside-border:after {
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(240, 240, 240, 0) 25%,
    #f0f0f0 25%,
    #f0f0f0 75%,
    rgba(240, 240, 240, 0) 75%
  );
  position: absolute;
  bottom: 25%;
  left: 0;
  height: 60%;
  width: 2px;
  color: #e9ecef;
}
.md-num {
  text-align: center;
  font-size: 30px;
  margin-top: 10px;
}
.convert-lg {
  text-align: center;
}
.rev_container {
  padding: 3px !important;
  text-align: center;
}

@media (max-width: 991px) {
  .focal-text {
    font-size: 36px;
  }
}
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .seo-summary {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  .lgtxt {
    font-size: 18px;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1099.98px) {
  .inside-border {
    border-left: none;
  }
  .inside-border:after {
    content: "";
    background: #ccc;
    position: absolute;
    bottom: 25%;
    left: 0;
    height: 50%;
    width: 0;
    color: #e9ecef;
  }

  .lg-num {
    font-size: 30px;
  }
  .seo-summary {
    margin-left: 0;
    margin-right: 0;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .rev_container {
    padding: 0;
  }
  .row-small {
    width: 100%;
    padding: 5px 0 !important;
    margin-left: 0;
    margin-right: 0;
  }
  .sm-mt-1 {
    margin-top: 0.25rem !important;
  }
  .cl4 {
    padding-left: 0px;
  }
  .box-mid,
  .box-left,
  .box-right {
    border-radius: 10px;
    padding: 0.5rem !important;
  }
  .my-1 {
    margin-bottom: 0 !important;
  }
  .box-right {
    margin-top: 0.25rem;
  }
  .col-left {
    padding-left: 0 !important;
  }
  .col-right {
    padding-right: 0 !important;
  }
  .lgtxt {
    font-size: 16px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .focal-text {
    font-size: 38px;
  }
}
</style>
