import { Line } from "vue-chartjs";
import { yAxisOptionsLine } from "../../../utils/chartOptions";
import { prevMonths } from "../../../utils/Utilities";

export default {
  props: [
    "chartData",
    "labels",
    "left_axis",
    "dataLabel",
    "locale",
    "revenue",
    "forecastData",
    "forecastLabels",
    "mixed",
  ],
  extends: Line,
  name: "LineChart",
  computed: {
    maxValues() {
      return Math.max(...this.chartData[1]);
    },
    lineChart() {
      return {
        labels: this.forecastData ? this.forecastLabels : prevMonths().reverse(),
        datasets: [
          {
            label: this.labels[1],
            fill: false,
            borderColor: "#2f2f2f",
            // steppedLine: this.stepped,
            backgroundColor: this.getGradient("#4c4c4c", "#2f2f2f"),
            hoverBackgroundColor: "#6c757d",
            lineTension: 0,
            yAxisID: "A",
            data: this.chartData[1],
            // datalabels: {
            //   align: "top",
            // },
          },
          {
            label: this.labels[0],
            type: this.mixed ? "bar" : "line",
            fill: this.mixed ? true : false,
            backgroundColor: this.getGradient("#ffc001d4", "#ffc001b8"),
            // steppedLine: this.stepped,
            hoverBackgroundColor: "#ffefbf",
            lineTension: 0,
            borderColor: "#ffd75f",
            yAxisID: "A",
            data: this.chartData[0],
            // datalabels: {
            //   align: "bottom",
            // },
          },
        ],
      };
    },
    chartOptions() {
      return {
        spanGaps: false,
        plugins: {
          filler: {
            propagate: false,
          },
          datalabels: {
            // display: "auto",
            display: false,
            // display: function(context) {
            //   console.log(context);
            //   return context.dataset.data[context.dataIndex] >= 1;
            // },
            // color: "black",
            // textAlign: "center",
            // font: {
            //   weight: "bold",
            //   size: 12,
            //   margin: "4px",
            // },
            // anchor: "end",
            // align: "top",
          },
        },
        cornerRadius: 20,
        responsive: true,
        legend: {
          display: true,
          position: "bottom",
        },
        maintainAspectRatio: false,
        aspectRatio: 5,
        scales: {
          xAxes: [
            {
              //line chart x axis to start at 0
              offset: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                fontColor: "#c4c6c5",
                fontFamily: "dosis",
                fontSize: 14,
                min: 0,
              },
            },
          ],
          yAxes: [
            yAxisOptionsLine("A", "left", this.getWindowWidth(), true, this.maxValues, this.left_axis),
            yAxisOptionsLine("B", "right", this.getWindowWidth(), false, this.maxValues, this.left_axis),
          ],
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: true,
          callbacks: {
            label: (tooltipItem, data) => {
              return [tooltipItem.yLabel.toLocaleString() + " " + data.datasets[tooltipItem.datasetIndex].label];
            },
          },
        },
      };
    },
  },
  methods: {
    getGradient(color1, color2) {
      var grad = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 350);
      grad.addColorStop(0, color1);
      grad.addColorStop(1, color2);
      return grad;
    },
    getWindowWidth() {
      var windowWidth = document.documentElement.clientWidth;
      return windowWidth > 900 ? true : false;
    },
  },
  mounted() {
    this.renderChart(this.lineChart, this.chartOptions);
  },
  watch: {
    chartData: function() {
      this.renderChart(this.lineChart, this.chartOptions);
    },
    // revenue: function() {
    //   this.renderChart(this.lineChart, this.chartOptions);
    // },
    locale: function() {
      this.renderChart(this.lineChart, this.chartOptions);
    },
  },
};
